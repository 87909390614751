import React from 'react';
import { Svg } from '@hmhco/svg';
import HmhGraphics from '@ed/baseline/VAL/hmh/login-sm.svg';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import HeinemannImage from '../../../Layout/integrationImages/hnm-background.png';
import useStyles from './Marketing.styles';

const Marketing = () => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();

  switch (platform) {
    case PLATFORMS.FLIGHT: {
      return (
        <div className={cx(classes.root, classes.heinemann)}>
          <div
            data-testid="marketing-image"
            role="presentation"
            className={cx(classes.image, classes.heinemannImage)}
            style={{
              backgroundImage: `url(${HeinemannImage})`,
            }}
          />
        </div>
      );
    }
    case PLATFORMS.ED:
    default: {
      return (
        <div className={cx(classes.root, classes.hmh)}>
          <div
            data-testid="marketing-image"
            role="presentation"
            className={cx(classes.image, classes.hmhImage)}
          >
            <Svg svg={HmhGraphics} />
          </div>
        </div>
      );
    }
  }
};

export default Marketing;
