import React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { func, string } from 'prop-types';
import useStyles from './Input.styles';
import { AUTO_COMPLETE } from '../../constants/constants';

const Username = ({
  username,
  handleChange,
  handleBlur,
  errorMessageId,
  elementId,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const usernameLabel = formatMessage({ id: 'registerPage.label.username' });
  const errorMessage = errorMessageId
    ? formatMessage({ id: errorMessageId })
    : '';

  return (
    <TextField
      FormHelperTextProps={{
        'aria-live': 'polite',
      }}
      value={username}
      error={Boolean(errorMessageId)}
      helperText={errorMessage}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{
        className: classes.input,
      }}
      label={usernameLabel}
      id={elementId}
      name="username"
      required
      autoComplete={AUTO_COMPLETE.USERNAME}
    />
  );
};

Username.propTypes = {
  username: string.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  errorMessageId: string,
  elementId: string.isRequired,
};

export default Username;
