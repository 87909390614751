export const graphRequestErrorsMapper = (error: any): string[] => {
  const graphResponse = JSON.parse(JSON.stringify(error));
  return (graphResponse?.response?.errors ?? []).map(
    responseError => responseError.message,
  );
};

export const graphApiErrorMapper = (error: any): string[] => {
  if (error?.url && error?.message) {
    return (
      (JSON.parse(error.message) as Array<{
        messageId: string;
        messageText: string;
      }>) ?? []
    ).map(errorMessage => errorMessage.messageText);
  }
  return [];
};
