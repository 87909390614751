export type ErrorHandler = {
  handle: (error: Error) => void;
};

export enum HttpAuthErrorStatusCodes {
  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
   * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
   * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
   * "unauthenticated", i.e. the user does not have the necessary credentials.
   */
  UNAUTHORIZED = 401,

  /**
   * The request was valid, but the server is refusing action.
   * The user might not have the necessary permissions for a resource.
   */
  FORBIDDEN = 403,
}
