import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { arrayOf, string, shape } from 'prop-types';
import StateInput from './StateInput';
import OrgInput from './OrgInput';
import {
  getCurrentState,
  getRenderUnitedStatesForm,
} from '../../store/selectors/CountryStateOrg';
import { setState } from '../../store/actions/CountryStateOrgActions';

const UnitedStatesForm = ({ states }) => {
  const dispatch = useDispatch();
  const renderUnitedStatesForm = useSelector(getRenderUnitedStatesForm);
  const selectedState = useSelector(getCurrentState);
  const setStateCallback = useCallback(state => {
    dispatch(setState(state));
  });

  if (!renderUnitedStatesForm) {
    return null;
  }
  return (
    <>
      <StateInput
        states={states}
        selectedState={selectedState}
        onChange={setStateCallback}
      />
      <OrgInput />
    </>
  );
};

const stateAndOrgShape = shape({ code: string, name: string });

UnitedStatesForm.propTypes = {
  states: arrayOf(stateAndOrgShape),
};

export default UnitedStatesForm;
