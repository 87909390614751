import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { IMMUTABLE } from '@hmhco/cache-expiration/src/cacheExpirationValues';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';

export const DEMO_ENDPOINT = `/api/oac/v3/access/organization/demoEntitled`;

export default (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    min: 900,
    max: 1300,
    redirectIfFails: false,
  }); // (min: 900, max: 1300) => Retries only once (after 1 sec).

  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    pactConfig,
  });

  return {
    isDemoCached: () =>
      request.get(DEMO_ENDPOINT, { maxAge: IMMUTABLE }).catch(error => {
        logErrorWithContext('Demo Entitlements Api', [
          { key: 'errorMessage', value: error },
        ]);

        return { demoEntitled: false, mdsPocEnabled: false };
      }),
    cancel,
  };
};
