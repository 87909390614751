import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ActionButtons' })(() => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
  button: {
    margin: 'auto 0px',
  },
  actionLinkContainer: {
    paddingBottom: 'var(--ebl-s2)',
  },
  signInDetailsLink: {
    textDecoration: 'none!important',
  },
}));
