import { CONFIG_TYPES, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import {
  FIELD_RELATED_USER_ERROR_TEXTS,
  FIELD_RELATED_USER_ERRORS,
} from '../../../constants/constants';

export const isOktaAvailable = (isVisible, oktaInfo, widgetRef) =>
  isVisible && !!oktaInfo.issuer && !!widgetRef.current;

export const updateLabels = widgetRef => {
  const labels = widgetRef.current.querySelectorAll('label');

  labels.forEach(label => {
    let asterisk = label.querySelector('span');
    if (asterisk) {
      return;
    }
    asterisk = document.createElement('span');
    asterisk.setAttribute('aria-hidden', 'true');
    asterisk.textContent = '*';
    label.append(asterisk);
  });
};

export const updateOktaForgotPassword = widgetRef => {
  const formElement = widgetRef.current.querySelector('[data-se="o-form"]');

  if (!formElement) {
    return;
  }

  const doesForgetPasswordContainerExist = !!formElement.querySelector(
    '#forget-password-container',
  );

  if (!doesForgetPasswordContainerExist) {
    const forgetPasswordElement = widgetRef.current.querySelector(
      '[data-se="forgot-password"]',
    );
    if (forgetPasswordElement) {
      const forgetPasswordContainer = document.createElement('div');
      forgetPasswordContainer.id = 'forget-password-container';
      const paragraphElement = document.createElement('p');
      paragraphElement.id = 'forget-password-paragraph';
      paragraphElement.appendChild(forgetPasswordElement);
      forgetPasswordContainer.appendChild(paragraphElement);
      const buttonsContainer = formElement.querySelector('.o-form-button-bar');
      formElement.insertBefore(forgetPasswordContainer, buttonsContainer);
    }
  }
};

export const updateForgotPassword = (widgetRef, formatMessage) => {
  const formElement = widgetRef.current.querySelector('[data-se="o-form"]');

  if (!formElement) {
    return;
  }

  const doesForgetPasswordContainerExist = !!formElement.querySelector(
    '#forget-password-container',
  );

  if (doesForgetPasswordContainerExist) {
    return;
  }

  const forgetPasswordOktaElement = widgetRef.current.querySelector(
    '[data-se="forgot-password"]',
  );

  if (!forgetPasswordOktaElement) {
    return;
  }

  forgetPasswordOktaElement.style = 'display: none';

  const forgetPasswordContainer = document.createElement('div');
  forgetPasswordContainer.id = 'forget-password-container';

  const paragraphElement = document.createElement('p');
  paragraphElement.id = 'forget-password-paragraph';
  const configForCurrentEnv = getConfigForCurrentEnv(CONFIG_TYPES.ed);

  const forgetPasswordLinkElement = document.createElement('a');
  forgetPasswordLinkElement.href = configForCurrentEnv.forgotPassword.replace(
    '/forgot-password',
    '/tenant-picker/forgot-password',
  );
  forgetPasswordLinkElement.target = '_self';
  forgetPasswordLinkElement.classList.add('forgotPasswordLink');
  forgetPasswordLinkElement.textContent =
    formatMessage?.({ id: 'tenantPicker.forgotPassword' }) ||
    'Forgot Password?';
  paragraphElement.appendChild(forgetPasswordLinkElement);
  forgetPasswordContainer.appendChild(paragraphElement);

  const buttonsContainer = formElement.querySelector('.o-form-button-bar');

  if (!buttonsContainer) {
    return;
  }

  formElement.insertBefore(forgetPasswordContainer, buttonsContainer);

  const requestSigninDetailsLinkElement = document.createElement('a');
  requestSigninDetailsLinkElement.href = configForCurrentEnv.requestSigninDetails.replace(
    '/request-signin-details',
    '/tenant-picker/request-signin-details',
  );
  requestSigninDetailsLinkElement.target = '_self';
  requestSigninDetailsLinkElement.classList.add('requestSigninDetailsLink');
  requestSigninDetailsLinkElement.textContent =
    formatMessage?.({
      id: 'tenantPicker.requestSiginDetails',
    }) || 'Send Sign In Details';
  buttonsContainer.prepend(requestSigninDetailsLinkElement);
  buttonsContainer.style = 'justify-content: space-between';
};

export const destroyFieldObservers = fieldObservers => {
  fieldObservers.forEach(fieldObserver => fieldObserver.disconnect());
};

export const updateFields = widgetRef => {
  const fieldContainers = widgetRef.current.querySelectorAll(
    '.o-form-fieldset',
  );

  fieldContainers.forEach(fieldContainer => {
    let fieldsetElement = fieldContainer.querySelector('fieldset');

    if (!fieldsetElement) {
      fieldsetElement = document.createElement('fieldset');
      fieldsetElement.setAttribute('aria-hidden', 'true');
      const controlContainerElement = fieldContainer.querySelector(
        '.o-form-control',
      );
      controlContainerElement?.append(fieldsetElement);
    }

    const labelElement = fieldContainer.querySelector('label');

    const hasError = !!fieldContainer.querySelector('.o-form-input-error');

    if (hasError) {
      fieldsetElement?.classList?.add('error');
      labelElement?.classList?.add('error');
    } else {
      fieldsetElement?.classList?.remove('error');
      labelElement?.classList?.remove('error');
    }
  });
};

export const updateBackButton = (widgetRef, formatMessage) => {
  const cancelBackButton = widgetRef.current.querySelector(
    '[data-se="cancel"]',
  );
  const goBackButton = widgetRef.current.querySelector('[data-se="go-back"]');
  const backButton = cancelBackButton || goBackButton;

  if (backButton) {
    backButton.textContent =
      formatMessage?.({
        id: 'login.forgotPassword.backButton',
      }) || 'Back to Sign In';
  }

  const oktaErrorAlertButton = widgetRef.current.querySelector(
    '[data-se="o-form-error-container"] button',
  );

  const buttonsContainer = widgetRef.current.querySelector(
    '.o-form-button-bar',
  );

  if (buttonsContainer) {
    const nextButton = buttonsContainer.querySelector('[value="Next"]');
    if (nextButton) {
      nextButton.value =
        formatMessage?.({
          id: 'login.forgotPassword.submitButton',
        }) || 'Submit';
    }

    const signInButton = buttonsContainer.querySelector('[value="Sign in"]');

    if (signInButton) {
      signInButton.value =
        formatMessage?.({
          id: 'tenantPicker.signIn',
        }) || 'Sign In';
    }

    if (!backButton) {
      if (buttonsContainer.children.length > 1) {
        buttonsContainer.style = 'justify-content: space-between';
      } else {
        buttonsContainer.style = 'justify-content: end';
      }
      return;
    }

    buttonsContainer.style = 'justify-content: space-between';
    buttonsContainer.prepend(backButton);
  } else if (oktaErrorAlertButton) {
    const oktaErrorAlertButtonContainer = oktaErrorAlertButton.parentElement;
    oktaErrorAlertButtonContainer.classList.add('buttonContainer');

    if (!backButton) {
      oktaErrorAlertButtonContainer.style = 'justify-content: end';
      return;
    }
    oktaErrorAlertButtonContainer.style = 'justify-content: space-between';
    oktaErrorAlertButtonContainer.prepend(backButton);
  }
};

export const updateAlerts = widgetRef => {
  /**
   * ERROR OKTA ALERT
   */
  const oktaErrorAlertContainer = widgetRef.current.querySelector(
    '[data-se="o-form-error-container"]',
  );
  const oktaErrorAlert = oktaErrorAlertContainer?.querySelector(
    '.okta-form-infobox-error',
  );
  const oktaCalloutAlert = oktaErrorAlertContainer?.querySelector(
    '[data-se="callout"]',
  );
  const userErrorAlertContainer = document.querySelector('#okta-error-alert');
  const userErrorAlert = userErrorAlertContainer?.querySelector(
    '[role="alert"] > div > p',
  );

  if (userErrorAlert) {
    const oktaErrorAlertText = oktaErrorAlert?.textContent;
    const oktaCalloutAlertText = oktaCalloutAlert?.textContent;

    const textContent = oktaErrorAlertText || oktaCalloutAlertText;

    if (
      textContent !==
      FIELD_RELATED_USER_ERROR_TEXTS[
        FIELD_RELATED_USER_ERRORS.FOUND_FORM_ERRORS
      ]
    ) {
      userErrorAlert.textContent = textContent || '';
      userErrorAlertContainer.hidden = !textContent;
    }
  }

  /**
   * INFO OKTA ALERT
   */
  const oktaInfoAlert = widgetRef.current.querySelector(
    '.o-form-info-container',
  );
  const oktaExplainAlert = oktaErrorAlertContainer?.querySelector?.(
    '[data-se="o-form-explain"]',
  );

  const userInfoAlertContainer = document.querySelector('#okta-info-alert');
  const userInfoAlert = userInfoAlertContainer?.querySelector?.(
    '[role="alert"] > div > p',
  );

  if (userInfoAlert) {
    const oktaInfoAlertText = oktaInfoAlert?.textContent;
    const oktaExplainAlertText = oktaExplainAlert?.textContent;

    userInfoAlert.textContent = oktaInfoAlertText || oktaExplainAlertText || '';
    userInfoAlertContainer.hidden = !oktaInfoAlertText && !oktaExplainAlertText;
  }
};
