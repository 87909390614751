import { ALERT_SIZE, HcpAlert } from '@hmhco/hcp-alert/src/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getUserAlert } from '../../../../store/selectors/TenantPickerState';
import { setUserAlert } from '../../../../store/actions/TenantPickerActions';

/**
 * Should be used to display generic error messages from user actions.
 *
 * NOTE: Accessibility. If possible to segragate error contexts then this Alert SHOULD BE used for generic errors ONLY.
 * Any specific contenxt related to field input (username, password, country selected) should be
 * address by corresponding field's error state.
 * @param {Object} message generic message object to show an alert for
 */
const UserAlert = () => {
  const userAlert = useSelector(getUserAlert);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  if (!userAlert) {
    return null;
  }

  return (
    <HcpAlert
      size={ALERT_SIZE.SMALL}
      severity={userAlert.severity}
      title={formatMessage({ id: userAlert.titleId })}
      subtitle={formatMessage({ id: userAlert.subtitleId })}
      onClose={() => dispatch(setUserAlert(null))}
    />
  );
};

export default UserAlert;
