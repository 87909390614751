import axios from 'axios';
import axiosRetry from 'axios-retry';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import getMainCorrelationId from '@hmhco/correlation-id-helper/utils/getMainCorrelationId';
import { getCommonUserContext } from '@hmhco/common-user-context';
import { pushGlobalError } from '@hmhco/amp-core/src/globalError';
import { NO_CACHE } from '@hmhco/cache-expiration/src/cacheExpirationValues';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { isRunningInAmp } from '@hmhco/amp-core/src/environmentHelpers';
import redirectToLogin from '@hmhco/redirect-to-login/src/redirectToLogin';
import backoff from './utils/backoff';

const FORAGE_STORE_PREFIX = 'HMH';

/**
 * CACHE_KEY_SEPARATOR separates the prefix from the rest of the URL made for the request.
 * This is to allow us to easily strip the prefix from the key if we need to later.
 */
const CACHE_KEY_SEPARATOR = '::';

/**
 * Change KILL_CACHE to TRUE if you want to turn off caching, and deploy this change through to PROD (for investigating MIs, CSIs, etc)
 * Exported only for unit test coverage.
 */
export const KILL_CACHE = false;

/**
 * create the local forage key using keyPrefix and url in request
 *
 * The key for each request item in the cache should start with the current user ID.
 * We also allow requests to add an optional prefix _after_ the userId to the key used in the localForage store,
 * otherwise use the request URL (including query parameters) is appended to the userId after a `::` separator.
 * Exported only for unit test coverage.
 *
 * @param {object} request contains { url: 'string', cache: { keyPrefix: 'string' }}
 *
 * @returns {string} local forage key for this user
 */
export const setLocalForageKey = request => {
  const { userId } = getCommonUserContext();
  return request.cache && request.cache.keyPrefix
    ? `${userId}_${request.cache.keyPrefix}${CACHE_KEY_SEPARATOR}${request.url}`
    : `${userId}${CACHE_KEY_SEPARATOR}${request.url}`;
};

/**
 * Create forageStore and cache values for using in axios client:
 *  - In the forageStore, we name the store with a prefix, and each request key also includes the current userId to prevent conflicts if users log in/out of the same browser
 *  - localforage.createInstance with the same name will reuse an existing store if it already exists in the browser
 *  - All requests made through createAxiosCancelable will be logged in the store with a default maxAge of 0, & this can be overridden by the request itself
 *  - LocalStorage is the default driver for the store, with a JS in-memory driver for Safari in private mode.
 *    - localforage.WEBSQL didn't appear to provide any caching with Chrome & axios-cache-adapter
 *    - localforage.INDEXEDDB didn't support multiple tabs open with different user sessions and also left empty databases behind after clearing.
 */
export const forageStore = localforage.createInstance({
  name: FORAGE_STORE_PREFIX,
  driver: [
    localforage.LOCALSTORAGE,
    /* eslint-disable-next-line no-underscore-dangle */
    memoryDriver._driver,
  ],
});
const cache = setupCache({
  maxAge: NO_CACHE,
  // Allow requests with query parameters (query params will appear in cache keys)
  exclude: { query: false },
  store: forageStore,
  key: setLocalForageKey,
});

/**
 * Clears the store named FORAGE_STORE_PREFIX, to avoid potential conflicts between users signing into the same browser session.
 *
 * This occurs:
 * - On login of Ed in @ed/arvo userContext.js
 * - On logout of Ed in @ed/arvo appContext.js
 * - On window.beforeunload of Ed header component @ed/arvo header.js
 * - On unmount of AMP in @hmhco/amp-container container.js
 *
 * A browser refresh, tab close without logout, logout, and new login should clear all FORAGE_STORE_PREFIX named items in the cache.
 */
export const clearLocalForageCache = async function() {
  await forageStore.clear();
};

/**
 * Used to clear related cache items when other requests are made successfully.
 * Should _only_ be used by @hmhco/cache-api-helper package.
 *
 * @param {string} keyPrefix string value that should be used to identify cache items that need to be expired
 */
export const clearCacheContainingKey = async function(keyPrefix) {
  const allCacheKeys = await forageStore.keys();
  const keysToClear = allCacheKeys.filter(cacheKey =>
    cacheKey.includes(keyPrefix),
  );
  if (keysToClear && keysToClear.length > 0) {
    await keysToClear.forEach(async keyToClear => {
      const result = await forageStore.getItem(keyToClear);
      if (result && 'expires' in result) {
        result.expires = Date.now(); // immediately expire
        await forageStore.setItem(keyToClear, result);
      }
    });
  }
};

/**
 * forward the error depending on current state of the request
 *
 * @param {object} error
 * @param {function} isCancel
 * @returns {object}
 */
export const handleError = (error, isCancel) => {
  if (isCancel && isCancel(error)) {
    // if we have cancelled we don't want to throw the 'error', but we want to return cancelled status so it can be handled
    return { isCancelled: true, message: error.toString() };
  }
  throw error;
};

/**
 * Axios error interceptor,
 * that sends the error to the main Error Store.
 * It also handles the redirect if a user navigates to AMP when not logged in.
 *
 * @param {Error} error
 */
export const errorInterceptor = error => {
  if (
    isRunningInAmp() &&
    (error?.response?.status === 403 || error?.response?.status === 401)
  ) {
    redirectToLogin({ encodeStateInUrl: true });
  }
  /** @type {{ config: AxiosRequestConfig }} */
  const { config } = error;
  if (config) {
    pushGlobalError({
      title: 'API error',
      origin: 'axiosHelpers.createAxiosWithBackoff',
      method: config.method,
      link: config.url,
      error,
    });
  }
  return Promise.reject(error);
};

/**
 * Returns header config with the SIF token
 *
 * @param {string} sif
 * @param {object} optionalHeaders
 * @returns {object} headers with authorization and correlation ID
 */
export const getAuthHeader = (sif, optionalHeaders = {}) => {
  const correlationId = getMainCorrelationId();
  if (sif) {
    return {
      headers: {
        Authorization: sif,
        CorrelationId: correlationId,
        ...optionalHeaders,
      },
    };
  }

  const userContext = getCommonUserContext();
  if (!userContext) {
    return {};
  }
  const { rawToken: { sif: { accessToken } = {} } = {} } = userContext;
  if (!accessToken) {
    logErrorWithContext('MissingAccessToken', []);
  }
  return {
    headers: {
      Authorization: accessToken,
      CorrelationId: correlationId,
      ...optionalHeaders,
    },
  };
};

/**
 * create basic headers without the authentification
 *
 * @param {object} optionalHeaders
 * @returns {object} headers with correlation ID
 */
export const getHeaderWithoutAuth = (optionalHeaders = {}) => {
  const correlationId = getMainCorrelationId();
  return {
    headers: {
      CorrelationId: correlationId,
      ...optionalHeaders,
    },
  };
};

/**
 * DEPRECATED: Please use createAxiosCancelable below.
 * Will be removed in subsequent PRs
 * @deprecated
 *
 * @param {number} min
 * @param {number} max
 * @param {boolean} includeAuth
 * @returns {object}
 */
export const createAxios = (min, max, includeAuth = true) => {
  const auth = includeAuth ? getAuthHeader() : getHeaderWithoutAuth();
  const client = axios.create(auth);
  axiosRetry(client, backoff(min, max));
  if (includeAuth) {
    client.interceptors.response.use(null, errorInterceptor);
  }
  return client;
};

/**
 * Helper for creating an Axios client and setting it up properly
 *
 * @param {number} min retry delay in ms
 * @param {number} max retry delay in ms
 * @param {function} retryCondition function returning boolean
 * @param {boolean} includeAuth whether to include Authorization in headers (default to true and will fetch current sif)
 * @param {boolean} redirectIfFails true by default, redirect to login on 403 and 401
 *
 * @returns {object} { client: AxiosInstance, cancel, cancelToken, isCancel }
 * @returns {function} cancelableAxios.cancel
 * @returns {Promise} cancelableAxios.cancelToken
 * @returns {function} cancelableAxios.isCancel
 */
export const createAxiosCancelable = ({
  min = 1000,
  max = 15000,
  retryCondition,
  includeAuth = true,
  redirectIfFails = true,
} = {}) => {
  const canceler = axios.CancelToken.source();
  const auth = includeAuth ? getAuthHeader() : getHeaderWithoutAuth();
  // Only use the cache if the user is logged in
  const userContext = getCommonUserContext();
  if (userContext.userId && !KILL_CACHE) {
    auth.adapter = cache.adapter;
  }
  const client = axios.create(auth);
  axiosRetry(client, backoff(min, max, retryCondition));
  if (includeAuth && redirectIfFails) {
    client.interceptors.response.use(null, errorInterceptor);
  }
  return {
    client,
    cancel: canceler.cancel,
    cancelToken: canceler.token,
    isCancel: axios.isCancel,
  };
};
