function s4() {
  if (window.crypto) {
    const randomNumber = window.crypto.getRandomValues(new Uint32Array(1))[0];
    return randomNumber.toString(16).substring(0, 4);
  }
  const randomNumber = (1 + Math.random()) * 0x10000;
  return Math.floor(randomNumber)
    .toString(16)
    .substring(1);
}

export const getRandomId = (blocks = 1) => {
  // generate an id with length 4 x blocks
  let id = '';
  for (let i = 0; i < blocks; i += 1) {
    id += s4();
  }
  return id;
};

export const getRandomGuid = () => {
  return `${getRandomId(
    2,
  )}-${getRandomId()}-${getRandomId()}-${getRandomId()}-${getRandomId(3)}`;
};
