/*  When creating vanilla routes, 
    you need to ensure they are unique and
    not substrings of other routes */
export const VANILLA_ROUTES = [
  '/ui/#/vanillaApp',
  '/ui/#/assessment-view',
  '/ui/#/contentProvider',
  '/ui/#/ltierror',
  '/ui/#/picker',
  '/ui/#/classcraft',
];
export const EXCLUDE_PENDO_ROUTE = [
  '/ui/#/vanillaApp',
  '/ui/#/assessment-view',
  '/ui/#/contentProvider',
  '/ui/#/ltierror',
  '/ui/#/picker',
  '/ui/#/classcraft/participate',
];

export const VANILLA_ROUTE_EXCLUSIONS = [
  'mainNavigation',
  'userProfile',
  'trainingPath',
  'coachingCore',
  'coachingChat',
];
