import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import buildAuthenticationUrl, { randomNonce } from './buildAuthenticationUrl';
import {
  createForm,
  navigateToSsoService,
  setAttributes,
  setPassword,
  setUsername,
  submitForm,
} from './formHelpers';
import { setNonce } from '../storageHelpers/localStorageAccessors';
import {
  getResourceUrl,
  getStateParam,
} from '../storageHelpers/sessionStorageAccessors';

export function getResourceUrlFromUrlOrStorage(search) {
  const urlResourceUrl = search.get('resource_url');
  if (urlResourceUrl) {
    return urlResourceUrl;
  }
  return getResourceUrl();
}

export function getStateParamFromUrlOrStorage(search) {
  const urlStateParam = search.get('state');
  if (urlStateParam) {
    return urlStateParam;
  }
  return getStateParam();
}

export function getStateAndResourceUrl() {
  const searchString = document?.location?.search?.substring(1) || '';
  const search = new URLSearchParams(searchString);
  const resourceUrl = getResourceUrlFromUrlOrStorage(search);
  const stateParam = getStateParamFromUrlOrStorage(search);

  return {
    resourceUrl,
    stateParam,
  };
}

export default () => {
  return {
    /*
     * Login is using a form submission because "Architecture" want login to do a browser redirect rather
     * respond with a JSON payload to reduce the likelyhood of man in the middle. This means that the UI
     * code ends up being convoluted and difficult to PACT test as the JSDOM environment does not support
     * native form submissions. This also greatly increases the risk of MI's (3 of the last UI caused MI's
     * were in login related code) as the PACT tests are not using the same API call that is made by real
     * users. Our PACT workaround is to use the formHelpers below to monitor what is being set on the form
     * and then replicating this same data in an axios call that's made in the PACT test.
     */
    login: async ({ pid, username, password }) => {
      try {
        const nonce = randomNonce();
        setNonce(nonce);
        const { resourceUrl, stateParam } = getStateAndResourceUrl();
        const loginUrl = buildAuthenticationUrl({
          pid,
          nonce,
          resourceUrl,
          stateParam,
        });
        const form = createForm();
        setAttributes(form, {
          method: 'post',
          url: loginUrl,
          encoding: 'application/x-www-form-urlencoded',
        });
        sessionStorage.setItem(
          'accountConfiguration',
          JSON.stringify({ defaultAccountType: 'institutional' }),
        );
        setUsername(form, username);
        setPassword(form, password);
        submitForm(form);
      } catch (error) {
        logErrorWithContext('Login app - failed to log in', [
          { key: 'errorMessage', value: error },
        ]);
      }
    },
    /*
     * Check SSO is similar to login above. We rely on a page redirect from the service to see if the user has SSO.
     * If the user does not have SSO, they get redirected back to the login form they were just on 🙄,
     * if they have SSO, they are redirected to the SSO login page.
     */
    checkSsoOrg: ({ pid }) => {
      try {
        const nonce = randomNonce();
        setNonce(nonce);
        const ssoUrl = buildAuthenticationUrl({ pid, nonce });
        navigateToSsoService(ssoUrl);
      } catch (error) {
        logErrorWithContext('Login app - Error in checking Sso Organization', [
          { key: 'errorMessage', value: error },
        ]);
      }
    },
  };
};
