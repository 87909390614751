/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/label-has-associated-control  */
import React, { forwardRef, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Icon from '@hmhco/icon/src/Icon';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import clearIcon from '@ed/baseline/icons/cts/clear-md.svg';
import FormLabel from '@mui/material/FormLabel';
import ReadOnlyField from '@hmhco/form-components/src/ReadOnlyField';
import FormControl from '@mui/material/FormControl';
import { Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import AddTranslations from '@hmhco/i18n-react/src/AddTranslations/AddTranslations';
import getLocaleFile from './lang';

const useStyles = makeStyles({ name: 'ComboBox' })({
  disabled: {
    color: 'var(--ebl-disabled-color)',
    marginTop: 0,
    marginBottom: 0,
  },
  disabledLabel: {
    marginLeft: 'var(--ebl-s3)',
    marginTop: 'calc(var(--ebl-s2) + 2px)',
  },
  input: { marginBottom: 0 },
  popper: {},
  noOptions: {
    display: 'none',
  },
});

const ComboBox = forwardRef((props, ref) => {
  const {
    renderOption,
    getOptionLabel,
    label,
    options,
    value,
    disabled,
    handleChange,
    innerClasses,
    dataTestId,
    placeholder,
    hideNoOptions,
    labelledby,
    required,
    autoHighlight,
    autoSelect,
    ariaDescribedBy,
    altError,
    altErrorText,
    memoizeCustomComponents,
    isLoading,
    ...otherProps
  } = props;

  const [textInputValue, setTextInputValue] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const { noOptionsText } = otherProps;

  const { formatMessage } = useIntl();
  const { classes: labelClasses } = useStyles(props, {
    props,
  });
  const seed = useUIDSeed();
  const labelId = labelledby || seed('textfield');
  const title = value ? getOptionLabel(value) : '';

  const hasError = noResultsFound || altError;

  const PopperAutoWidth = popperProps => {
    const { style, ...otherPopperProps } = popperProps;
    const maxWidth = Math.max(style.width, 320);
    return (
      <Popper
        {...otherPopperProps}
        style={{ minWidth: style.width, width: 'auto', maxWidth }}
        placement="bottom-start"
      />
    );
  };

  if (disabled && title) {
    return (
      <FormControl margin="none" required={required}>
        <ReadOnlyField
          label={label}
          value={title}
          data-testid={`${dataTestId}-readOnly`}
        />
      </FormControl>
    );
  }

  const checkErrorToDisplay = () => {
    switch (true) {
      case noResultsFound:
        return noOptionsText;
      case altError:
        return altErrorText;
      default:
        return null;
    }
  };

  const NumResultsHeader = paperProps => {
    const { children, ...otherPaperProps } = paperProps;
    const [numOptions, setNumOptions] = useState(null);
    const paperRef = useRef();

    useEffect(() => {
      const results = paperRef.current.querySelectorAll('li[data-option-index]')
        .length;
      setNoResultsFound(results === 0 && textInputValue?.length > 0);
      setNumOptions(results);
    });
    return (
      <>
        {numOptions > 0 && textInputValue && (
          <span
            aria-live="assertive"
            role="alert"
            aria-label={formatMessage(
              {
                id: 'comboBox.ariaLabel.optionsReturned',
              },
              { count: numOptions },
            )}
          />
        )}
        {numOptions === 0 && textInputValue && (
          <span
            aria-live="assertive"
            role="alert"
            aria-label={formatMessage({
              id: 'comboBox.ariaLabel.no.optionsReturned',
            })}
          />
        )}
        <Paper ref={paperRef} {...otherPaperProps}>
          {children}
        </Paper>
      </>
    );
  };

  return (
    <>
      <FormLabel required={required} disabled={disabled} id={labelId}>
        {label}
      </FormLabel>
      {disabled && !title && (
        // We're using a separate TextField for the disabled state
        // for a11y reasons.  Passing InputProps into the TextField
        // in the Autocomplete's renderInput section causes a11y issues
        // with the TextField when it's not disabled.  So this method
        // is cleaner and simpler.
        <TextField
          data-testid="disabledAutocomplete"
          variant="outlined"
          fullWidth
          disabled
          value={title}
          inputProps={{
            'aria-labelledby': labelId,
            'data-testid': dataTestId && `${dataTestId}Disabled`,
          }}
          className={labelClasses.disabled}
          required={required}
        />
      )}
      {!disabled && (
        <Autocomplete
          {...otherProps}
          loading={isLoading}
          renderOption={renderOption}
          autoHighlight={autoHighlight}
          autoSelect={autoSelect}
          PopperComponent={
            memoizeCustomComponents
              ? useMemo(() => PopperAutoWidth, [])
              : PopperAutoWidth
          }
          clearOnEscape
          classes={{
            option: labelClasses.option,
            popper: labelClasses.popper,
            noOptions: `${hideNoOptions ? labelClasses.noOptions : ''}`,
            ...innerClasses,
          }}
          id={seed('autocomplete')}
          options={options}
          value={value}
          onChange={handleChange}
          getOptionLabel={getOptionLabel}
          PaperComponent={
            memoizeCustomComponents
              ? useMemo(() => NumResultsHeader, [])
              : NumResultsHeader
          }
          onClose={() => setNoResultsFound(false)}
          renderInput={params => (
            <label aria-labelledby={labelId}>
              <TextField
                {...params}
                inputRef={ref}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  ...(ariaDescribedBy && {
                    'aria-describedby': ariaDescribedBy,
                  }),
                  'data-testid': 'input-aria-field',
                }}
                data-testid={dataTestId}
                placeholder={placeholder}
                required={required}
                className={labelClasses.input}
                onChange={e => setTextInputValue(e.target.value)}
                error={hasError}
                helperText={checkErrorToDisplay()}
              />
            </label>
          )}
          clearIcon={
            <Icon svg={clearIcon} size="24" colour="var(--ebl-text-gray)" />
          }
        />
      )}
    </>
  );
});

ComboBox.defaultProps = {
  disabled: false,
  innerClasses: {},
  getOptionLabel: value => value,
  hideNoOptions: false,
  labelledby: null,
  autoHighlight: false,
  autoSelect: false,
  value: undefined,
  required: false,
  label: null,
  dataTestId: null,
  placeholder: null,
  renderOption: null,
  ariaDescribedBy: null,
  memoizeCustomComponents: false,
  isLoading: false,
  altError: false,
  altErrorText: '',
};

const OptionType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    title: PropTypes.node.isRequired,
  }),
  PropTypes.object,
]);

ComboBox.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  value: OptionType,
  getOptionLabel: PropTypes.func,
  innerClasses: PropTypes.object,
  options: PropTypes.arrayOf(OptionType).isRequired,
  dataTestId: PropTypes.string,
  placeholder: PropTypes.string,
  hideNoOptions: PropTypes.bool,
  labelledby: PropTypes.string,
  autoHighlight: PropTypes.bool,
  autoSelect: PropTypes.bool,
  renderOption: PropTypes.func,
  ariaDescribedBy: PropTypes.string,
  memoizeCustomComponents: PropTypes.bool,
  isLoading: PropTypes.bool,
  altError: PropTypes.bool,
  altErrorText: PropTypes.string,
};

export default forwardRef((props, ref) => (
  <AddTranslations getLocaleFile={getLocaleFile}>
    <ComboBox {...props} ref={ref} />
  </AddTranslations>
));
