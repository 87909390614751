import { uniqBy } from 'lodash';
import { getEntitlementsByAuthor, getEntitlements } from '@hnm/session-context';
import { FlightProduct } from '@hnm/types';
import {
  ProductNames,
  productNameToAuthorMap,
  ProductIdentifier,
  productAuthorToProductNameMap,
} from '@hnm/config';
import { isExpired } from './DateUtils';

const productCategoryMapping = (
  productCategory,
  products,
  productSubCategory,
) => {
  return products.find(
    product =>
      productCategory === product.category &&
      product.displayDescription.includes(productSubCategory), // may need to use new HCP schema template value, e.g. subtype
  );
};

const removeExpiredProduct = entitlementList => {
  return entitlementList.filter(product => {
    return !isExpired(product.expirationDate);
  });
};

const authorProductMapping = authorList => {
  const entitlementList: FlightProduct[] = [];
  authorList.forEach(author => {
    const authorName = author.toLowerCase();
    const productList = uniqBy(
      getEntitlementsByAuthor(authorName),
      product => `${product.bundleId}|${product.productId}`,
    );
    if (authorName === productNameToAuthorMap[ProductNames.DO_THE_MATH]) {
      const dtmProducts = [ProductIdentifier.dtm, ProductIdentifier.dtmMs];
      dtmProducts.forEach(productName => {
        const dtmProductObject = productCategoryMapping(
          productName,
          productList,
          productName === ProductIdentifier.dtm ? 'All Modules' : 'All Units',
        );
        if (dtmProductObject) {
          entitlementList.push(dtmProductObject);
        }
      });
    } else if (authorName === Object.keys(productAuthorToProductNameMap)[4]) {
      const mxProductObject = productCategoryMapping(
        ProductIdentifier.mx,
        productList,
        'Grade 3',
      );
      if (mxProductObject) {
        entitlementList.push(mxProductObject);
      }
    } else {
      entitlementList.push(...productList);
    }
  });
  return removeExpiredProduct(entitlementList);
};

const sortProductEntitlement = list => {
  return list
    .map(prod => {
      return {
        ...prod,
        productName: `${prod.displayTitle} ${prod?.gradeList}`,
      };
    })
    .sort((a, b) => a.productName.localeCompare(b.productName));
};

const getAllProductEntitlementList = () => {
  const productEntitlementList: FlightProduct[] = getEntitlements() ?? [];
  const authorNames = Array.from(
    new Set(productEntitlementList.map(({ author }) => author)),
  );

  const uniqueEntitlementList: FlightProduct[] = authorProductMapping(
    authorNames,
  );
  return sortProductEntitlement(uniqueEntitlementList);
};

export default getAllProductEntitlementList;
