import { getConfig } from './buildAuthenticationUrl';

function buildAuthCallback(code, state, codeVerifier) {
  const { edsAuthorizationCode, ampSignedInURL } = getConfig();

  const params = new URLSearchParams();
  params.append('state', state);
  params.append('interaction_code', code);
  params.append('redirect_uri', ampSignedInURL);
  params.append('code_verifier', codeVerifier);

  return `${edsAuthorizationCode}?${params.toString()}`;
}

export default buildAuthCallback;
