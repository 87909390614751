import { AltId, Qualifier } from '@hnm/types';

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const assembleQualifierKeyValue = (qualifiers: Qualifier[]): any => {
  return Object.fromEntries(
    qualifiers.map(qualifier => {
      if (qualifier.key === 'hnm_institution_name') {
        return ['hnmInstitutionName', qualifier.value];
      }
      return [qualifier.key, qualifier.value];
    }),
  );
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const assembleAltIdKeyValue = (altIds: AltId[]): any => {
  return Object.fromEntries(
    altIds.map(altId => [altId.altKeyType, altId.altKey]),
  );
};
