import { getUserCtx } from '@hmhco/amp-core/src/userContext/auth';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import {
  CONFIG_TYPES,
  PLATFORMS,
} from '../../../../environments/globalConstants';
import getUserContext from './getHcpUserContext';

/*
 * calling the function without params will return the ed user context
 * for backwards compatibility
 */
export default (platform = PLATFORMS.ED, configType = CONFIG_TYPES.ed) => {
  if (platform === PLATFORMS.FLIGHT && configType === CONFIG_TYPES.ed) {
    throw Error(
      `config type:"${configType}" is not supported when platform is:"${platform}"`,
    );
  }
  if (platform === PLATFORMS.FLIGHT) {
    // we should come back and use the support-tool config library instead
    const config = getConfigForCurrentEnv(configType);
    const isFlight = configType === CONFIG_TYPES.hnm;
    const url = config.edsGraphUrl;
    const { request } = getUserContext(url, isFlight);
    return request();
  }
  return getUserCtx();
};
