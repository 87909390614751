export const GA_CONTAINER_ID = 'GTM-NKX422D';

export const localArgs = {
  auth: 'qLnvv0Dxfp_KNn-q12V4vA',
  preview: 'env-3',
};

export const intArgs = {
  auth: 'PKTAQY3MU8L_Z_Wx5TM_Zw',
  preview: 'env-4',
};

export const certArgs = {
  auth: 'u-7WBifYMw3VVVUCcuTx2Q',
  preview: 'env-5',
};

export const devCertArgs = {
  auth: 'qLnvv0Dxfp_KNn-q12V4vA',
  preview: 'env-3',
};

export const prodArgs = {
  auth: 'R3zyKaJlhU2cOCajBc1tnw',
  preview: 'env-6',
};
