import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'SelectedOrganization' })(theme => ({
  root: {},
  organization: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 'var(--ebl-s2)',
  },
  button: {
    backgroundColor: 'transparent',
    marginTop: 0,
    marginBottom: 0,
    '& > span': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
  },
}));
