import { useLocation } from 'react-router-dom';

export function useInteractionCodeParamHook() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const interactionCode = search.get('interaction_code');
  const state = search.get('state');
  return { interactionCode, state };
}

export default useInteractionCodeParamHook;
