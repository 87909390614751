import { TenantPickerTypes } from './types';

export const oktaVisible = visible => ({
  type: TenantPickerTypes.OKTA_VISIBLE,
  oktaVisible: visible,
});

export const setCachedOktaWidgetConfig = oktaWidgetConfig => ({
  type: TenantPickerTypes.SET_CACHED_OKTA_WIDGET_CONFIG,
  oktaWidgetConfig,
});

export const setDidPidFail = didPidFail => ({
  type: TenantPickerTypes.SET_DID_PID_FAIL,
  didPidFail,
});

export const setUserAlert = userAlert => ({
  type: TenantPickerTypes.SET_USER_ALERT,
  userAlert,
});

export const setLoginStep = loginStep => ({
  type: TenantPickerTypes.LOGIN_STEP,
  loginStep,
});

export const setLoginFlow = loginFlow => ({
  type: TenantPickerTypes.LOGIN_FLOW,
  loginFlow,
});

export const setLoginHint = loginHint => ({
  type: TenantPickerTypes.LOGIN_HINT,
  loginHint,
});

export default {};
