import { destroyFieldObservers } from './utils';
import { updateWidget } from './updateWidget';
import { createFieldObservers } from './createFieldObservers';
import { createAlertObservers } from './createAlertObservers';

export const createObserversToUpdateWidget = (widgetRef, formatMessage) => {
  const observers = {
    fieldObservers: [],
    alertObservers: [],
    containerObserver: null,
  };

  if (!widgetRef.current || !widgetRef.current.firstChild) {
    return observers;
  }

  const widgetInnerElement = widgetRef.current.firstChild;

  if (!widgetInnerElement) {
    return observers;
  }

  const observer = new MutationObserver((_, widgetObserver) => {
    const authContentElement = widgetRef.current.querySelector(
      '.auth-content-inner',
    );

    if (!authContentElement) {
      return;
    }

    observers.containerObserver = new MutationObserver(() => {
      destroyFieldObservers(observers.fieldObservers);
      updateWidget(widgetRef, formatMessage);
      observers.fieldObservers = createFieldObservers(widgetRef);
      observers.alertObservers = createAlertObservers(widgetRef);
    });

    observers.containerObserver.observe(authContentElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    widgetObserver.disconnect();
  });

  observer.observe(widgetInnerElement, {
    attributes: true,
    childList: true,
    characterData: true,
  });

  updateWidget(widgetRef, formatMessage);

  return observers;
};

export default createObserversToUpdateWidget;
