/**
 * Determine if the environment is deployed
 *
 * @param {string} env
 * @returns {boolean}
 */
export function isDeployedEnvironment(env) {
  const envConfig = {
    int: true,
    cert: true,
    prod: true,
  };
  return Boolean(envConfig[env]);
}

/**
 * getter for HMH software version
 *
 * @returns {string} software version
 */
export function getVersion() {
  return window.HMH_VERSION || window.APP_VERSION || 'no_version_set';
}
