import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import CountryInput from './CountryInput';
import getClient from '../../api/loginGetters';
import UnitedStatesForm from './UnitedStatesForm';
import InternationalForm from './InternationalForm';
import {
  getCurrentCountry,
  getRenderUnitedStatesForm,
} from '../../store/selectors/CountryStateOrg';

const CountryStateAndOrgForm = () => {
  const { getActiveCountries, getActiveStates, cancel } = getClient();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const selectedCountry = useSelector(getCurrentCountry);

  const renderUnitedStatesForm = useSelector(getRenderUnitedStatesForm);

  useEffect(() => {
    getActiveCountries()
      .then(activeCountries => setCountries(activeCountries))
      .catch(error => {
        logErrorWithContext('Login app - Error getting active countries', [
          { key: 'errorMessage', value: error },
        ]);
      });

    return () => cancel('Canceled by CountryStateAndOrgForm');
  }, []);

  useEffect(() => {
    if (renderUnitedStatesForm) {
      getActiveStates(selectedCountry.code)
        .then(activeStates => {
          setStates(activeStates);
        })
        .catch(error => {
          logErrorWithContext('Login app - Error getting active states', [
            { key: 'errorMessage', value: error },
          ]);
        });
    }
  }, [renderUnitedStatesForm]);

  return (
    <>
      <CountryInput countries={countries} />
      <UnitedStatesForm states={states} />
      <InternationalForm />
    </>
  );
};

CountryStateAndOrgForm.propTypes = {};

export default CountryStateAndOrgForm;
