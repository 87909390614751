import { Base64 } from 'js-base64';

/**
 * Parse the JSON format of the SIF token
 *
 * @param {string} token json encoded object containing user informations
 *
 * @returns {object}
 */
export function rawSifParser(token = null) {
  return JSON.parse(token) || {};
}

/**
 * Decode the SIF token
 *
 * @param {string} raw SIF token encoded in base64
 * @returns {string} decoded token
 */
export function decodeFromBase64IdToken(token) {
  if (!token) return '';
  return Base64.decode(token.replace('SIF_HMACSHA256 ', '')).trim();
}

/**
 * Decode the SIF token, remove "first part" separated by a dot
 *
 * @param {string} raw SIF token encoded in base64
 * @returns {string} decoded token
 */
export function decodeFromBase64(token) {
  const sif = decodeFromBase64IdToken(token).split('.')[1];
  return Base64.decode(sif);
}

/**
 * Decode the SIF token
 *
 * @param {string} raw SIF token encoded in base64
 * @returns {string} decoded token
 */
export function decodeSifBase(token = null) {
  if (!token) return {};
  return decodeFromBase64(token);
}

/**
 * Decode the SIF token and return the parsed json
 *
 * @param {string} raw SIF token encoded in base64
 * @returns {object} decoded token
 */
export function decodeSifBaseAsObject(token) {
  if (!token) return {};
  const decodedSif = decodeFromBase64(token);
  if (typeof decodedSif === 'string') {
    return JSON.parse(decodedSif);
  }
  return decodedSif;
}

/**
 * @param {string} token
 * @returns {string}
 */
export function getTokenInfoFromSifToken(token) {
  const rawToken = rawSifParser(token);
  const { sif: { tokeninfo } = {} } = rawToken;
  if (!tokeninfo) return '';
  return tokeninfo;
}

/**
 * @param {string} token
 * @returns {string}
 */
export default function sifDecoder(token) {
  const rawToken = rawSifParser(token);
  const { sif: { accessToken } = {} } = rawToken;
  if (!accessToken) return '';

  return decodeFromBase64(accessToken);
}
