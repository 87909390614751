import { ForgotPasswordOrgTypes } from '../actions/types';

const initialState = {
  orgName: null,
  forgotPasswordDisabled: false,
  loading: false,
  error: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ForgotPasswordOrgTypes.FORGOT_PASSWORD_UPDATE_ORG_NAME: {
      const { orgName } = action;
      return {
        ...initialState,
        loading: false,
        orgName,
      };
    }
    case ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_DISABLED_FLAG: {
      return {
        ...state,
        forgotPasswordDisabled: true,
      };
    }
    case ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_ORG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_ORG_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};
