import { useEffect, useState } from 'react';
import { func, string, object } from 'prop-types';
import useIsMounted from '@hmhco/react-useismounted-hook/src/useIsMounted.hook';

/**
 * Helper for App level components to fetch translation messages (like hmhco/hmh-login)
 *
 * children: function getting translations as argument, eg: messages => <Component messages={messages} />
 * locale: the current locale to load (eg: "en-US" or "es-US")
 * messageFetchFunction: returns a promise resolving to the loaded translations for the current locale
 * defaultTranslations: optional object containing translations messages to use until loading is done. Prevent missing key errors.
 *
 */
const TranslationsMessageProvider = ({
  children,
  locale,
  messageFetchFunction,
  defaultTranslations,
}) => {
  const componentIsMounted = useIsMounted();
  const [messages, setMessages] = useState(defaultTranslations);

  useEffect(() => {
    messageFetchFunction(locale).then(loadedTranslations => {
      if (componentIsMounted.current) {
        setMessages(loadedTranslations);
      }
    });
  }, [locale, componentIsMounted]);

  // function form child expected to receive the locale
  return children(messages);
};

TranslationsMessageProvider.propTypes = {
  children: func.isRequired,
  locale: string.isRequired,
  messageFetchFunction: func.isRequired,
  defaultTranslations: object,
};

export default TranslationsMessageProvider;
