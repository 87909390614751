import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
  },
  icon: {
    marginTop: 'var(--ebl-s0)',
  },
  informationMessage: {
    marginLeft: 'var(--ebl-s2)',
  },
}));
