import {
  ADMINISTRATOR_CAPABILITIES,
  LicenseTypes,
  RoleType,
  TEACHER_CAPABILITIES,
  User,
  UserMembership,
} from '@hnm/types';
import {
  AccountDTO,
  AccountQualifierDTO,
  CapabilityDTO,
  GroupCapabilitiesKeyDTO,
  GroupMembershipDTO,
  UserDTO,
  UsersDTO,
} from '@hnm/types/src/definitions/UserCapabilities';

type MapCapabilitiesForMembershipParams = {
  accountQualifier: AccountQualifierDTO[];
  capabilities: CapabilityDTO[];
  dealerId: number;
  domainId: number;
  accountId: number;
  accountType: LicenseTypes;
  sourceId: number;
};

const mapCapabilitiesForMembership = ({
  accountQualifier,
  capabilities,
  dealerId,
  domainId,
  accountId,
  accountType,
  sourceId,
}: MapCapabilitiesForMembershipParams): UserMembership[] | [] => {
  // For determining the user's role, we only need the key attribute from each capability
  const capabilitiesKeys = capabilities.map(capability => capability.key);
  const teacherCapabilitiesKeys = TEACHER_CAPABILITIES.map(
    capability => capability.key,
  );
  const adminCapabilitiesKeys = ADMINISTRATOR_CAPABILITIES.map(
    capability => capability.key,
  );
  const isAdministrator = adminCapabilitiesKeys.every(capability =>
    capabilitiesKeys.includes(capability),
  );
  const isTeacher = teacherCapabilitiesKeys.every(capability =>
    capabilitiesKeys.includes(capability),
  );
  const membershipRoles: RoleType[] = [];

  if (isAdministrator) {
    membershipRoles.push(RoleType.Owner);
  }

  if (isTeacher) {
    membershipRoles.push(RoleType.Member);
  }

  const institutionName = accountQualifier.find(
    qualifier => qualifier.key === 'hnm_institution_name',
  );

  const userMembership: UserMembership[] = membershipRoles.map(
    membershipRole => ({
      dealerId,
      domainId,
      accountType,
      groupId: accountId, // The groupId needs to be mapped to the accountId when we want to construct a UI membership from a groupMembership.
      hnmInstitutionName: institutionName?.value,
      membershipRole,
      sourceId,
    }),
  );

  return userMembership;
};

type MapCapabilityUserParams = {
  accountQualifier: AccountQualifierDTO[];
  capabilities: CapabilityDTO[];
  dealerId: number;
  domainId: number;
  accountId: number;
  accountType: LicenseTypes;
  user: UserDTO;
  sourceId: number;
};

export const mapCapabilityUser = ({
  accountQualifier,
  capabilities,
  dealerId,
  domainId,
  accountType,
  accountId,
  user,
  sourceId,
}: MapCapabilityUserParams): User => {
  return {
    email: user.email,
    familyName: user.familyName,
    givenName: user.givenName,
    id: user.id,
    isAdmin: false,
    memberships: mapCapabilitiesForMembership({
      accountQualifier,
      capabilities,
      dealerId,
      domainId,
      accountId,
      accountType, // Without accountType we can't display org labels correctly (example: My group)
      sourceId,
    }),
    name: `${user.givenName} ${user.familyName}`,
  };
};

const userAccountTypes = [LicenseTypes.Institutional, LicenseTypes.Individual];

export const mapGroupMemberships = (
  groupMemberships: GroupMembershipDTO[],
): User[] => {
  // Filter by group membership capability with key 'manage_capabilities'
  const filteredGroupMembershipsByManageCapability = groupMemberships.filter(
    groupMembership =>
      groupMembership.capabilities.find(
        capability =>
          capability.key === GroupCapabilitiesKeyDTO.manageCapabilities,
      ),
  );

  const groupMembershipsReducer = (
    groupMembershipsAccumulator: User[],
    groupMembership: GroupMembershipDTO,
  ) => {
    const linkedAccounts =
      groupMembership?.capabilitiesGroup?.linkedAccounts ?? [];

    // Filter account group type for institutional and individual only
    const filteredLinkedAccounts = linkedAccounts.filter(linkedAccount =>
      userAccountTypes.includes(linkedAccount.account.accountType),
    );

    if (filteredLinkedAccounts.length === 0) {
      return groupMembershipsAccumulator;
    }

    const accountsReducer = (
      linkedAccountsAccumulator: User[],
      account: { account: AccountDTO },
    ) => {
      const {
        accountQualifier,
        dealerId,
        domainId,
        primaryMembers,
        source: sourceId,
        accountType,
        id,
      } = account.account;
      const primaryMembersReducer = (primaryMemberList, primaryMember) => {
        const { capabilities, user } = primaryMember;
        const mappedUser = mapCapabilityUser({
          accountQualifier,
          capabilities,
          dealerId,
          domainId,
          accountId: id,
          accountType,
          user,
          sourceId,
        });
        return [...primaryMemberList, mappedUser];
      };

      const primaryMembersReduced = primaryMembers.reduce(
        primaryMembersReducer,
        [],
      );
      return [...linkedAccountsAccumulator, ...primaryMembersReduced];
    };

    const mappedUsersByAccount = filteredLinkedAccounts.reduce<User[]>(
      accountsReducer,
      [],
    );
    return [...groupMembershipsAccumulator, ...mappedUsersByAccount];
  };

  const allUsersByManageCapability = filteredGroupMembershipsByManageCapability.reduce<
    User[]
  >(groupMembershipsReducer, []);

  // Merge users with same id
  const mappedUsers = allUsersByManageCapability.reduce<Map<number, User>>(
    (usersMap, user) => {
      if (usersMap.has(user.id)) {
        const existingUser = usersMap.get(user.id) as User;
        const updatedUser = {
          ...existingUser,
          memberships: [...existingUser.memberships, ...user.memberships],
        };
        usersMap.set(user.id, updatedUser);
      } else {
        usersMap.set(user.id, user);
      }
      return usersMap;
    },
    new Map<number, User>(),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Array.from(mappedUsers, ([name, value]) => ({ ...value }));
};

export const mapUsersByCapability = (users: UsersDTO): User[] =>
  mapGroupMemberships(users[0].groupMemberships);
