import { createSelector } from 'reselect';

export const getCountryStateOrg = state => state.countryStateOrg;

export const getCurrentCountry = createSelector(
  [getCountryStateOrg],
  countryStateOrg => countryStateOrg?.country,
);

export const getCurrentCountryCode = createSelector(
  [getCurrentCountry],
  currentCountry => currentCountry?.code,
);

export const getCurrentState = createSelector(
  [getCountryStateOrg],
  countryStateOrg => countryStateOrg?.state,
);

export const getCurrentStateCode = createSelector(
  [getCurrentState],
  currentState => currentState?.code,
);

export const getCurrentOrg = createSelector(
  [getCountryStateOrg],
  countryStateOrg => countryStateOrg?.org,
);

export const getCurrentPid = createSelector(
  [getCurrentOrg],
  currentOrg => currentOrg?.pid,
);

export const getRenderSignInButton = createSelector(
  [getCurrentOrg], // The logic here is the same as getRenderUsernameAndPassword for now but will change in upcoming PR
  currentOrg => Boolean(currentOrg?.pid && currentOrg?.isConnectionPid),
);

export const getRenderNextButton = createSelector([getCurrentOrg], currentOrg =>
  Boolean(currentOrg?.pid && !currentOrg?.isConnectionPid),
);

export const getRenderUsernameAndPassword = createSelector(
  [getCurrentOrg],
  currentOrg => Boolean(currentOrg?.pid && currentOrg?.isConnectionPid),
);

export const getRenderUnitedStatesForm = createSelector(
  [getCurrentCountryCode],
  currentCountryCode => currentCountryCode === 'US',
);

export const getRenderInternationalForm = createSelector(
  [getCurrentCountryCode],
  currentCountryCode =>
    Boolean(currentCountryCode && currentCountryCode !== 'US'),
);

export const getRenderUnitedStatesOrgInput = createSelector(
  [getCurrentCountryCode, getCurrentStateCode],
  (currentCountryCode, currentStateCode) => {
    if (!currentCountryCode || !currentStateCode) {
      return false;
    }
    return currentCountryCode === 'US';
  },
);
