import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import AlertNotification from '@hmhco/alert-notification/src/AlertNotification';
import { getIsRequestSigninDisabled } from '../../store/selectors/RequestSigninPageState';

const RequestSignInDetailsDisabledMessage = () => {
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);
  if (!requestSigninDisabled) {
    return null;
  }
  return (
    <AlertNotification severity="error" small textMode="inline">
      <FormattedMessage id="login.RequestSignInDetails.disabledMessage" />
    </AlertNotification>
  );
};

export default RequestSignInDetailsDisabledMessage;
