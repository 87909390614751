/* eslint-disable import/prefer-default-export */

import { isLegacyPlatformToken } from '@hmhco/amp-core/src/userContext/auth';
import isDemoApi from '@hmhco/demo-entitlements-api/src/isDemoApi';
import { getCommonUserContext } from '@hmhco/common-user-context';

/**
 * @deprecated
 *
 * Instead you should use feature-flags: packages/libs/feature-flags/README.md
 */
export const isDemoUser = async () => {
  const { sif } = getCommonUserContext();
  const { isDemoCached } = isDemoApi();
  // NOTE: HMO doesn't have a demo entitlements or a concept of feature flagging so we need to treat legacy users as NON demo
  const { demoEntitled } = await (isLegacyPlatformToken(sif)
    ? Promise.resolve({ demoEntitled: false })
    : isDemoCached());
  return demoEntitled;
};
