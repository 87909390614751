import { getConfigForCurrentEnv, CONFIG_TYPES } from '@hmhco/amp-core-env';
import { ErrorHandler, HttpAuthErrorStatusCodes } from './ErrorHandler';
import { ApiError } from './ApiError';

// eslint-disable-next-line import/prefer-default-export
export const unauthorizedErrorHandler: ErrorHandler = {
  handle: error => {
    const apiError = error as ApiError;
    const errorStatus = apiError?.statusCode ?? -1;
    const errorMessage = apiError?.message ?? '';

    Object.values(HttpAuthErrorStatusCodes).forEach(statusCode => {
      if (
        errorStatus === statusCode ||
        errorMessage.includes(String(statusCode))
      ) {
        window.location.href = `${
          getConfigForCurrentEnv(CONFIG_TYPES.hnm).edsPostLogoutRedirectUrl
        }ui/logout/prepare-logout`;
      }
    });
  },
};
