import { ForgotPasswordOrgTypes, RequestSigninStateTypes } from './types';

export const setOrgName = orgName => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_UPDATE_ORG_NAME,
  orgName,
});

export const setForgotPasswordOrgError = () => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_ORG_ERROR,
});

export const setRequestSigninIsValid = isValid => ({
  type: RequestSigninStateTypes.REQUEST_SIGNIN_SET_ISVALID,
  isValid,
});

export const setRequestSigninEmail = email => ({
  type: RequestSigninStateTypes.SET_EMAIL,
  email,
});

export const setRequestSigninDetailsLoading = () => ({
  type: RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_IS_LOADING,
});

export const setRequestSigninDetailsError = () => ({
  type: RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_HAS_ERROR,
});

export const setRequestSigninDetailsSuccess = () => ({
  type: RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_IS_SUCCESS,
});

export const setRequestSigninDetailsDisabledFlag = () => ({
  type: RequestSigninStateTypes.REQUEST_SIGNIN_SET_DISABLED_FLAG,
});
