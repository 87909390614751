import { mapUser, mapUserAndTransformGroupMembership } from '@hnm/utils';
import gql from 'graphql-tag';
import { createGraphClient } from '@hnm/graph-client';
import { getAuth } from '@hnm/session-context';

export const getUserContextQuery = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        id
        givenName
        familyName
        email
        memberships {
          id
          role
          group {
            id
            parentId
            name
            account {
              id
              accountType
              dealerId
              domainId
              source
              accountQualifier {
                key
                value
              }
              accountAltId {
                altKeyType
                altKey
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserContextQueryWithGroupMembershipDealer2 = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        id
        givenName
        familyName
        email
        groupMemberships {
          capabilitiesGroup(args: { filters: { types: "private" } }) {
            id
            type
            linkedAccounts {
              account {
                id
                accountType
                dealerId
                domainId
                source
                accountAltId {
                  altKey
                  altKeyType
                }
                accountQualifier {
                  key
                  value
                }
              }
            }
          }
          capabilities {
            key
            value
          }
        }
      }
    }
  }
`;

export const getUserContextQueryWithGroupMembershipDealer1 = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        id
        givenName
        familyName
        email
        groupMemberships {
          capabilitiesGroup {
            id
            type
            linkedAccounts {
              account {
                id
                accountType
                dealerId
                domainId
                source
                accountAltId {
                  altKey
                  altKeyType
                }
                accountQualifier {
                  key
                  value
                }
              }
            }
          }
          capabilities {
            key
            value
          }
        }
      }
    }
  }
`;

const getUserContext = (url, withGroupMembership = false) => {
  const auth = getAuth();
  const { request } = createGraphClient(url);
  const variables = {
    id: auth.userId.toString(),
  };
  const dealerBasedQuery =
    Number(auth?.authContext?.idToken?.claims?.tenant_id) !== 2
      ? getUserContextQueryWithGroupMembershipDealer2
      : getUserContextQueryWithGroupMembershipDealer1;
  // this entire pattern can be abstracted into a helper
  return {
    request: () =>
      request(
        withGroupMembership ? dealerBasedQuery : getUserContextQuery,
        variables,
      ).then(result => {
        if (result?.getUserById?.users) {
          return result.getUserById.users.map(
            withGroupMembership ? mapUserAndTransformGroupMembership : mapUser,
          )[0];
        }
        return undefined;
      }),
  };
};

export default getUserContext;
