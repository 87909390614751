import { ApiError } from './ApiError';

const restErrorConverter = (url: string, response: Response): any => {
  if (response && response.ok) {
    return response.json();
  }

  return response
    .text()
    .then(text =>
      Promise.reject(
        new ApiError(url, text, response.status, response.statusText),
      ),
    );
};

export default restErrorConverter;
