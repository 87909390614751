import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from '@hmhco/amp-core/src/utils/debounce';
import getClient from '../api/loginGetters';
import { setOrg } from '../store/actions/CountryStateOrgActions';
import {
  getCurrentCountryCode,
  getCurrentStateCode,
  getCurrentOrg,
} from '../store/selectors/CountryStateOrg';

const SEARCH_TERM_DEBOUNCE = 600;

function useSearchOrgs() {
  const dispatch = useDispatch();
  const { getOrgsForCountryAndState, getOrgsForCountry, cancel } = getClient();
  const currentCountryCode = useSelector(getCurrentCountryCode);
  const currentStateCode = useSelector(getCurrentStateCode);
  const currentOrg = useSelector(getCurrentOrg);

  const [orgSearchTerm, setOrgSearchTerm] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = debounce(
    (event, newInputValue) => setOrgSearchTerm(newInputValue || ''),
    SEARCH_TERM_DEBOUNCE,
  );

  const selectOrg = org => dispatch(setOrg(org));

  useEffect(() => {
    const searchTermEqualsSelectedOrg = orgSearchTerm === currentOrg?.name;
    if (!searchTermEqualsSelectedOrg) {
      setLoading(true);
      let promise;

      if (currentCountryCode === 'US') {
        promise = getOrgsForCountryAndState(
          currentCountryCode,
          currentStateCode,
          orgSearchTerm,
        );
      } else if (currentCountryCode) {
        promise = getOrgsForCountry(currentCountryCode, orgSearchTerm);
      } else {
        setLoading(false);
        return () => {};
      }
      promise
        .then(orgResponse => {
          if (!orgResponse.isCancelled) {
            setOrgs(orgResponse);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => cancel('Cancelled by useSearchOrgs hook');
  }, [orgSearchTerm, currentCountryCode, currentStateCode]);

  return {
    selectOrg,
    handleSearchChange,
    loading,
    orgs,
  };
}

export default useSearchOrgs;
