import { LicenseTypes } from './LicenseTypes';
import { CapabilitiesKeyType } from './CapabilitiesKeyType';

// DTO refers to Data Transfer Object, meaning the data received from the backend API
export type UsersDTO = {
  groupMemberships: GroupMembershipDTO[];
};

type CapabilitiesGroupDTO = {
  id: number;
  type: LicenseTypes;
  linkedAccounts: Array<{ account: AccountDTO }>;
};

export enum GroupCapabilitiesKeyDTO {
  licensing = 'manage_licensing',
  manageCapabilities = 'add_user_functions',
  member = 'member',
  useProductBundle = 'use_product_bundle',
}

export enum GroupCapabilityValueDTO {
  allow = 'allow', // Not used for now. Add possible values once backend has specs for this.
}

export type GroupCapabilitiesDTO = {
  key: GroupCapabilitiesKeyDTO;
  value: GroupCapabilityValueDTO;
};

export type GroupMembershipDTO = {
  capabilities: GroupCapabilitiesDTO[];
  capabilitiesGroup: CapabilitiesGroupDTO | null;
};

export type UserDTO = {
  email: string;
  familyName: string;
  givenName: string;
  id: number;
};

type CapabilityDTOValue = 'allow'; // We don't need this value

export type CapabilityDTO = {
  key: CapabilitiesKeyType;
  value: CapabilityDTOValue;
};

export type PrimaryMemberDTO = {
  capabilities: CapabilityDTO[];
  user: UserDTO;
};

export type AccountQualifierKeyDTO =
  | 'hnm_customer_class'
  | 'hnm_institution_name'
  | 'hnm_mdr_pid'
  | 'quantity'
  | 'quantity_allocated'
  | 'quantity_unallocated'
  | 'value';

export type AccountQualifierDTO = {
  key: AccountQualifierKeyDTO;
  value: string;
};

export type AccountDTO = {
  accountAltId: Array<{ altKeyType: string; altKey: string }>;
  accountQualifier: AccountQualifierDTO[];
  accountType: LicenseTypes;
  dealerId: number;
  domainId: number;
  id: number;
  primaryMembers: PrimaryMemberDTO[];
  source: number;
};
