import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RequestSigninDetailsInformationHeader' })({
  title: {
    paddingBottom: 'var(--ebl-s2)',
  },
  informationPanel: {
    paddingBottom: 'var(--ebl-s3)',
  },
});
