import React from 'react';
import { useSelector } from 'react-redux';
import ComboBox from '@hmhco/combobox/src/ComboBox/ComboBox';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';
import {
  getCurrentOrg,
  getRenderUnitedStatesOrgInput,
  getRenderInternationalForm,
} from '../../store/selectors/CountryStateOrg';
import useStyles from './OrgInput.styles';
import useSearchOrgs from '../../hooks/getSearchedOrgs.hook';

export const nameAndAddressRenderer = (
  props,
  { name, addressCity, addressStreet, addressZip },
) => (
  <li {...props}>
    <Box>{name || ''}</Box>
    <Box fontWeight="fontWeightLight">
      <FormattedMessage
        id="login.form.org.international.address"
        values={{ addressCity, addressStreet, addressZip }}
      />
    </Box>
  </li>
);

const returnAllOptions = options => options;

const OrgInput = () => {
  const { classes } = useStyles();
  const currentOrg = useSelector(getCurrentOrg);
  const renderUnitedStatesOrg = useSelector(getRenderUnitedStatesOrgInput);
  const renderInternationalForm = useSelector(getRenderInternationalForm);
  const { selectOrg, handleSearchChange, loading, orgs } = useSearchOrgs();

  if (!renderUnitedStatesOrg && !renderInternationalForm) {
    return null;
  }

  const inputLabel = renderUnitedStatesOrg
    ? 'login.form.org.US.label'
    : 'login.form.org.international.label';
  const inputPlaceholder = renderUnitedStatesOrg
    ? 'login.form.org.US.placeholder'
    : 'login.form.org.international.placeholder';

  return (
    <div className={classes.orgContainer}>
      <FormattedMessage id={inputLabel}>
        {([label]) => (
          <FormattedMessage id={inputPlaceholder}>
            {([placeholder]) => (
              <ComboBox
                filterOptions={returnAllOptions} // The filtering occurs on the service so we use a MUI prop to disable the client side filtering
                autoHighlight
                classes={{ input: classes.input, option: classes.option }}
                required
                placeholder={placeholder}
                dataTestId="loginOrgSelect"
                label={label}
                options={orgs}
                getOptionLabel={option => option.name || ''}
                value={currentOrg}
                handleChange={(event, value) => {
                  selectOrg(value);
                }}
                onInputChange={(event, value) => {
                  handleSearchChange(event, value);
                }}
                loading={loading}
                renderOption={nameAndAddressRenderer}
              />
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
    </div>
  );
};

OrgInput.propTypes = {};

export default OrgInput;
