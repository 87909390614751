import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import AlertNotification from '@hmhco/alert-notification/src/AlertNotification';
import useStyles from './RequestSignInDetailsErrorState.styles';
import RequestSignInDetailsButtons from './RequestSignInDetailsButtons';
import OrgInfo from '../../../RequestSignInDetailsPage/OrgInfo';

const RequestSignInDetailsErrorState = () => {
  const { classes } = useStyles();
  return (
    <>
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.requestSiginDetails.pageTitle" />
      </Typography>
      <OrgInfo />
      <AlertNotification severity="error">
        <Typography
          component="p"
          variant="body2"
          className={classes.informationPanel}
        >
          <FormattedMessage id="login.requestSiginDetails.errorMessage" />
        </Typography>
      </AlertNotification>

      <RequestSignInDetailsButtons />
    </>
  );
};

export default RequestSignInDetailsErrorState;
