/* eslint-disable */
const { PLATFORMS } = require('./globalConstants.js');
const currentConfiguration = require('./currentConfiguration.js');

const Themes = {
  heinemann: 'heinemann',
  hmh: 'hmh',
};

const environment = {
  currentConfigurationName: currentConfiguration,
  allConfigurations: {
    HMH: {
      name: 'HMH',
      predicate: 'hmh',
      showDesktopNavbar: true,
      showMobileNavbar: true,
      showLMS: true,
      showUpdatesBanner: true,
      theme: Themes.hmh,
    },
    HNM: {
      name: 'HNM',
      predicate: 'heinemann',
      showDesktopNavbar: false,
      showMobileNavbar: false,
      showLMS: false,
      showUpdatesBanner: false,
      theme: Themes.heinemann,
    },
  },
};

const getCurrentConfigurationTheme = () => {
  const hasPredicate = window.location.host.includes(
    environment.allConfigurations.HNM.predicate,
  );
  if (hasPredicate) {
    return PLATFORMS.FLIGHT;
  }
  return PLATFORMS.ED;
};

const currentRouteIntegration = () => {
  const isLocal = window.location.host.includes('local');
  if (isLocal) {
    return (
      environment?.currentConfigurationName || getCurrentConfigurationTheme()
    );
  }
  return getCurrentConfigurationTheme();
};

module.exports = {
  environment,
  Themes,
  currentRouteIntegration,
  getCurrentConfigurationTheme,
};
