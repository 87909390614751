import { createSelector } from 'reselect';
import { getIsForgotPasswordDisabled } from './ForgotPasswordOrg';

export const getForgotPasswordState = state => state.forgotPasswordState;

export const getForgotPasswordUsername = createSelector(
  [getForgotPasswordState],
  forgotPasswordState => forgotPasswordState?.username,
);

export const getIsForgotPasswordUsernameValid = createSelector(
  [getForgotPasswordUsername],
  username => {
    return username?.length > 0;
  },
);

export const getForgotPasswordIsLoading = createSelector(
  [getForgotPasswordState],
  forgotPasswordState => forgotPasswordState.loading,
);

export const getForgotPasswordIsErrored = createSelector(
  [getForgotPasswordState],
  forgotPasswordState => forgotPasswordState.error,
);

export const getForgotPasswordHasSucceeded = createSelector(
  [getForgotPasswordState],
  forgotPasswordState => forgotPasswordState.success,
);

export const getShouldRenderErrorScreen = createSelector(
  [getForgotPasswordIsLoading, getForgotPasswordIsErrored],
  (isLoading, isErrored) => {
    if (isLoading) {
      return false;
    }
    return isErrored;
  },
);

export const getShouldRenderSuccessScreen = createSelector(
  [getForgotPasswordIsLoading, getForgotPasswordHasSucceeded],
  (isLoading, hasSucceeded) => {
    if (isLoading) {
      return false;
    }
    return hasSucceeded;
  },
);

export const getShouldRenderForgotPasswordForm = createSelector(
  [getShouldRenderErrorScreen, getShouldRenderSuccessScreen],
  (shouldRenderErrorScreen, shouldRenderSuccessScreen) => {
    if (shouldRenderErrorScreen || shouldRenderSuccessScreen) {
      return false;
    }
    return true;
  },
);

export const getCanSubmitUsername = createSelector(
  [
    getShouldRenderErrorScreen,
    getShouldRenderSuccessScreen,
    getIsForgotPasswordDisabled,
  ],
  (
    shouldRenderErrorScreen,
    shouldRenderSuccessScreen,
    isForgotPasswordDisabled,
  ) => {
    if (
      shouldRenderErrorScreen ||
      shouldRenderSuccessScreen ||
      isForgotPasswordDisabled
    ) {
      return false;
    }
    return true;
  },
);
