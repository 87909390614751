import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import AlertNotification from '@hmhco/alert-notification/src/AlertNotification';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import PropTypes from 'prop-types';
import LoginAppLayout from '../Layout/LoginAppLayout';
import LoginAppFormLayout from '../Layout/LoginAppFormLayout';
import changePasswordApi from '../../api/changePasswordApi';
import Username from './Username';
import usernameValidator from '../../util/usernameValidator';
import passwordValidator from '../../util/passwordValidator';
import formDataValidator from '../../util/formDataValidator';
import Password from './Password';
import { QUERY_PARAMS } from '../../constants/constants';
import InformationMessage from '../InformationMessage/InformationMessage';

const NewPasswordPage = ({ currentTheme }) => {
  const [createNewPasswordError, setCreateNewPasswordError] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [newPassword, setNewPassword] = useState(undefined);
  const [verifyPassword, setVerifyPassword] = useState(undefined);
  const { isUsernameValid, usernameErrorMessageId } = usernameValidator(
    username,
  );
  const { isPasswordValid, passwordErrorMessageId } = passwordValidator(
    newPassword,
  );
  const platformId = currentTheme === 'HNM' ? 'flight' : 'ed';
  const {
    isFormValid,
    passwordMatchesUsernameErrorMessageId,
    passwordsDoNotMatchErrorMessageId,
  } = formDataValidator(
    newPassword,
    verifyPassword,
    username,
    isUsernameValid,
    isPasswordValid,
  );
  const { changePassword } = changePasswordApi();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get(QUERY_PARAMS.TOKEN);
  const connection = queryParams.get(QUERY_PARAMS.CONNECTION);
  const { formatMessage } = useIntl();
  const usernamePasswordError = passwordMatchesUsernameErrorMessageId
    ? formatMessage({
        id: passwordMatchesUsernameErrorMessageId,
      })
    : undefined;
  const verifyPasswordErrorMessage = passwordsDoNotMatchErrorMessageId
    ? formatMessage({
        id: passwordsDoNotMatchErrorMessageId,
      })
    : undefined;
  const passwordLabel = formatMessage({
    id: 'login.newPassword.passwordLabel',
  });
  const reEnterPasswordLabel = formatMessage({
    id: 'login.newPassword.reenterPassword',
  });
  const passwordErrorMessage = passwordErrorMessageId
    ? formatMessage({
        id: passwordErrorMessageId,
      })
    : undefined;

  const requestPasswordUpdate = event => {
    event.preventDefault();

    const payload = {
      username,
      token,
      password: newPassword,
      platformId,
    };
    changePassword(payload)
      .then(() => {
        setCreateNewPasswordError(false);
        history.push({
          pathname: '/',
          search: `?${QUERY_PARAMS.CONNECTION}=${connection}&${QUERY_PARAMS.FROM}=${QUERY_PARAMS.PASSWORD_RESET}`,
        });
      })
      .catch(error => {
        setCreateNewPasswordError(true);
        logErrorWithContext('Login app - Error in creating new password', [
          { key: 'errorMessage', value: error },
        ]);
      });
  };

  const handleUsernameChange = event => setUsername(event.target.value);
  const handlePasswordChange = event => setNewPassword(event.target.value);
  const handleVerifyPasswordChange = event =>
    setVerifyPassword(event.target.value);

  return (
    <LoginAppLayout currentTheme={currentTheme}>
      <LoginAppFormLayout currentTheme={currentTheme}>
        {createNewPasswordError && (
          <AlertNotification severity="error">
            <FormattedMessage id="newPassword.createNewPassword.error" />
          </AlertNotification>
        )}
        <Typography component="h1" variant="h3">
          <FormattedMessage id="login.newPassword.pageTitle" />
        </Typography>
        <Username
          handleUsernameChange={handleUsernameChange}
          errorMessageId={usernameErrorMessageId}
        />
        <Password
          dataTestId="new-password-password"
          handlePasswordChange={handlePasswordChange}
          errorMessage={passwordErrorMessage || usernamePasswordError}
          passwordLabel={passwordLabel}
          elementId="new-password-enter-password"
        />
        <div style={{ marginBottom: 'var(--ebl-s3)' }}>
          <InformationMessage messageId="newPassword.newPassword.information" />
        </div>
        <Password
          dataTestId="new-password-validate-password"
          handlePasswordChange={handleVerifyPasswordChange}
          errorMessage={verifyPasswordErrorMessage}
          passwordLabel={reEnterPasswordLabel}
          elementId="new-password-reenter-password"
        />
        <div style={{ marginBottom: 'var(--ebl-s6)' }}>
          <InformationMessage messageId="newPassword.info.requiredFields" />
        </div>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              disabled={!isFormValid}
              onClick={requestPasswordUpdate}
              variant="contained"
              color="primary"
              type="submit"
            >
              <FormattedMessage id="login.newPassword.submitButton" />
            </Button>
          </Grid>
        </Grid>
      </LoginAppFormLayout>
    </LoginAppLayout>
  );
};

NewPasswordPage.propTypes = {
  currentTheme: PropTypes.string,
};

export default NewPasswordPage;
