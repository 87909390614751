import { RequestSigninStateTypes } from '../actions/types';

const initialState = {
  email: null,
  requestSigninIsValid: false,
  loading: false,
  error: false,
  success: false,
  requestSigninDisabled: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RequestSigninStateTypes.SET_EMAIL: {
      const { email } = action;
      return {
        ...state,
        email,
      };
    }
    case RequestSigninStateTypes.REQUEST_SIGNIN_SET_ISVALID: {
      const { isValid } = action;
      return {
        ...state,
        requestSigninIsValid: isValid,
      };
    }
    case RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_IS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_HAS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case RequestSigninStateTypes.REQUEST_SIGNIN_REQUEST_IS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case RequestSigninStateTypes.REQUEST_SIGNIN_SET_DISABLED_FLAG: {
      return {
        ...state,
        requestSigninDisabled: true,
      };
    }
    default: {
      return state;
    }
  }
};
