import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';
import { bool, func } from 'prop-types';

const CreateAccountButton = ({ onClick: handleRegisterUser, isDisabled }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          onClick={handleRegisterUser}
          disabled={isDisabled}
          variant="contained"
          color="primary"
          type="submit"
        >
          <FormattedMessage id="registerPage.createAccount" />
        </Button>
      </Grid>
    </Grid>
  );
};

CreateAccountButton.propTypes = {
  onClick: func,
  isDisabled: bool,
};

export default CreateAccountButton;
