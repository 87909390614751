const certEdConfig = {
  name: 'cert',
  ampContent: 'https://cert.hmhco.com/content',
  ampLoggedInURL: 'https://cert.hmhco.com/ui/logged-in/',
  ampSignedInURL: 'https://cert.hmhco.com/ui/signed-in/',
  ampLogin: 'https://cert.hmhco.com/ui/login/',
  ampLoginHelp: 'http://downloads.hmlt.hmco.com/Help/Ed/LogIn',
  ampLoginPrivacyPolicy:
    'https://www.hmhco.com/prek-12-products-privacy-policy',
  ampLoginTermsAndConditions:
    'https://www.hmhco.com/terms-of-use-k12-learning-platforms',
  hnmLoginHelp: 'http://downloads.hmlt.hmco.com/Help/Flight/LogIn/',
  hnmLoginPrivacyPolicy:
    'https://www.heinemann.com/privacy/?utm_medium=shorturl&utm_source=privacy',
  hnmLoginTermsAndConditions:
    'https://www.heinemann.com/platforms-terms-of-use/?utm_medium=shorturl&utm_source=platform-terms',
  apiEngHostname: 'https://api.cert.hmhco.com',
  apiEngHostnamePublic: 'https://api.cert.hmhco.com',
  assessments: 'https://cert.hmhco.com/api/assessment',
  assignments: 'https://cert.hmhco.com/api/assignment',
  authorizationApi: 'https://cert.hmhco.com/api/authorization',
  auth0: 'https://hmhco-eng.auth0.com',
  auth0CreateConnectionsHost:
    'https://api.cert.hmhco.com/auth-self-service-setup/api/v1/sso',
  auth0FetchConnectionsHost:
    'https://api.cert.hmhco.com/auth-self-service-setup/api/v1/sso/datastore/connection',
  amiraReports: 'cloudfront.net',
  baseURL: 'https://cert.hmhco.com',
  classcraftSessionData:
    'https://hapi.cert.hmhco.com/classcraft-session-data-svc',
  classes: 'https://cert.hmhco.com/ui/classes',
  classesClasses: 'https://cert.hmhco.com/ui/classes/classes',
  clientRegistry: 'https://api.cert.hmhco.com/idm-client-registry',
  collaboration: 'collaboration.cert.hmhco.com',
  contentProvider: 'http://cert.hmhco.com/ui/#/contentProvider/',
  districtDownloadApi:
    'https://api.cert.hmhco.com/data-district-download-service',
  ebrCore: 'https://api.cert.hmhco.com/idm-ebr-core',
  ebrUi: 'https://cert.hmhco.com/import/',
  edHostname: 'https://cert.hmhco.com/',
  edRoot: 'https://cert.hmhco.com/one/#/',
  edBaseUrl: 'https://cert.hmhco.com',
  EDSLoginUrl: 'https://hapi.cert.hmhco.com/edcore/eds-login',
  EDSResourceUrl: 'https://api.cert.heinemann.com',
  grading: 'https://cert.hmhco.com/api/grading/',
  hmhHelpCenter: 'http://downloads.hmlt.hmco.com',
  hmoHostname: 'https://my-cert.hrw.com/sp/',
  hmoLoginPath: 'https://my-cert.hrw.com/index.jsp',
  idm: 'https://cert.idm.api.hmhco.com/openid-connect',
  jasperReporting: 'https://cert.hmhco.com/jasperserver-pro',
  licenses: 'http://cert.hmhco.com/ui/#/licenses',
  liftRoot: 'https://cert.hmhco.com/ui/',
  loginPath: '/ui/login/',
  ltiCache: 'https://api.cert.hmhco.com/lti-deep-linking/cache',
  myClasses: 'https://cert.hmhco.com/ui/!my-classes',
  myStuff: 'http://cert.hmhco.com/ui/#/my-stuff/lessons',
  notificationsApi: 'https://api.cert.hmhco.com',
  newNotificationsApi: 'https://hapi.cert.hmhco.com',
  nweaBaseUrl: 'https://auth.cert.nwea.org/adult/login',
  nweaStartPage: 'https://perf01-start.mapnwea.org',
  nweaCommonRosterRedirectUrl:
    'https://perf01-admin.mapnwea.org/admin/importprofile/StartNewImportPage.seam',
  oktaConnectionApi:
    'https://api.cert.hmhco.com/okta-services-gateway/api/v1/sso',
  oktaFetchConnectionsHost:
    'https://api.cert.hmhco.com/okta-services-gateway/api/v1/sso/idps',
  oktaNewOrg: 'https://api.cert.hmhco.com/okta-services-gateway/api/v1/org',
  onesearch: 'https://cert.hmhco.com/api/onesearch/v1',
  picker: 'https://cert.hmhco.com/ui/#/picker/',
  plannerHost: 'https://api.cert.hmhco.com/planner-api',
  plannerHostNonWaf: 'https://api.cert.eng.hmhco.com/planner-api',
  plsAuthors: 'https://api.cert.hmhco.com/pls-services-author/api/v5/authors',
  plsCollections:
    'https://api.cert.hmhco.com/pls-services-resource/api/v5/collections',
  plsLessonRelatedResource:
    'https://api.cert.hmhco.com/pls-services-lessonrelatedresource/api/v5/lessonrelatedresources',
  plsRelatedResources:
    'https://api.cert.hmhco.com/pls-services-relatedresource/api/v5/relatedresources',
  plsResource:
    'https://api.cert.hmhco.com/pls-services-resource/api/v5/resources',
  prefixCDS: 'https://cds-metadatapipeline.cert.br.hmheng.io',
  restApi: 'https://api.cert.hmhco.com',
  restGroupLessonApi: 'https://practice-cert.gogetwaggle.com/teacher',
  restHapi: 'https://hapi.cert.hmhco.com',
  restDMPS: 'https://api.cert.hmhco.com/dmps',
  restDMPSNonWAF: 'https://api.cert.eng.hmhco.com/dmps',
  restELM: 'https://api.cert.eng.hmhco.com/elm/v1',
  restIdsApi: 'https://api.cert.br.internal/ids', // for purging users in IDS we need a different API
  restIdsGridApi: 'https://api.cert.hmhco.com/ids-grid-api',
  restIdsGridApiNonWAF: 'https://api.cert.eng.hmhco.com/ids-grid-api',
  restNotifications: 'https://api.cert.hmhco.com/notifications',
  restNotificationsNonWAF: 'https://api.cert.eng.hmhco.com/notifications',
  restOAC: 'https://api.cert.hmhco.com/oac/v3',
  restOACNonWAF: 'https://api.cert.eng.hmhco.com/oac/v3',
  restOacV2: 'https://cert.hmhco.com/api/oac/v2',
  restPostPrefix: 'https://cert.hmhco.com',
  restPrefix: 'https://cert.hmhco.com',
  restRecommendation: 'https://api.cert.eng.hmhco.com/recommendation-api',
  restStagingProcessor: 'https://api.cert.hmhco.com/idm-staging-processor',
  restSusApi: 'https://api.cert.br.internal/idm-user-api', // for purging users in SUS
  restUdsApi: 'https://api.cert.hmhco.com/uds',
  restUpdatesApi: 'https://api.cert.hmhco.com/update-catalog',
  rosterDashboard: 'https://cert.hmhco.com/ui/#/roster',
  r180Api: 'https://cert.hmhco.com/api/r180/',
  r180Media: 'https://cert.hmhco.com/r180/media',
  sisHostname: 'https://cert.hmhco.com/',
  sso: 'https://api.cert.hmhco.com/auth-self-service-setup',
  ssoInitializeUrl: 'https://api.cert.hmhco.com/login/initialize',
  storyBook: 'https://cert.hmhco.com/ui/storybook/',
  susAuth: 'https://cert.hmhco.com/api/authorization',
  standardsReportWritableResources:
    'https://cert.hmhco.com/ui/#/allResources/discipline/standards?disciplineCode=ED18_RLA&abGuid=802F94DA-7440-11DF-93FA-01FD9CFF4B22&domainId=286EE5CC-74F7-11DF-80DD-6B359DFF4B22&domainName=Writing&studentId=all&schoolId=null&teacherId=1c0b60be-d613-4ad7-8689-6b8b20470894',
  standardsReportWaggleResources:
    'https://cert.hmhco.com/ui/#/allResources/discipline/standards?disciplineCode=ED18_MATH&abGuid=1BD4F4C6-7053-11DF-8EBF-BE719DFF4B22&domainId=1BCEA21A-7053-11DF-8EBF-BE719DFF4B22&domainName=Operations+and+Algebraic+Thinking&studentId=all&schoolId=null&teacherId=288a8843-736f-4171-8fe5-2783b7c4a794',
  tcHostname: 'https://www-cert.thinkcentral.com/sp/',
  tcLoginPath: 'https://www-cert.thinkcentral.com/ePC/start.do',
  linkedAssignments:
    'https://cert.hmhco.com/ui/#/assign-full?resourceId=l_ff9bdd36-f627-4241-8dbc-9f9b4f158f56_c7b06f27-597b-42fc-8169-67247568e97f&programId=IM_NL20_G4&title=Unit+Opener%3A+Place+Value+and+Whole+Number+Operations&backTo=discover%252FIM_NL20_G4%252F1&showSched=true&recBy=NONE&isSgm=false',
  virtualClassroomApi:
    'https://api.cert.hmhco.com/vcservice/v2/virtual-classroom/meeting',
  wormhole: 'https://api.cert.hmhco.com/wh-api/graphql',
  wormholek8s: 'https://app01.cert.tsg.hmhco.com/wormhole-api/graphql',
  importingService: 'https://api.cert.hmhco.com/idm-api-importing-service',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  apiBaseURL: '',
  featureFlagURL:
    'https://cert.hmhco.com/ui/flags/featureFlagsCohorts.config.json',
  edsApiGateway: 'https://hapi.cert.hmhco.com/edcore/hmheng-eds-api-gateway/',
  edsAuthorize:
    'https://api.cert.heinemann.com/edcore/eds-login/login/authorize',
  edsAuthorizationCode:
    'https://hapi.cert.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  edsInitUrl:
    'https://hapi.cert.hmhco.com/edcore/eds-login/login/initializeUri',
  flightLogin: `https://platform.cert.heinemann.com/ui/login/`,
  forgotPassword: '/ui/login/forgot-password/',
  requestSigninDetails: '/ui/login/request-signin-details/',
  plsCollaborationArtifacts:
    'https://hapi.cert.hmhco.com/collaboration-artifacts-svc',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  edpsUrl: 'https://hapi.cert.hmhco.com/edcore/hmheng-eds-api-gateway/',
  plsCoachingTopicURL:
    'https://api.cert.hmhco.com/pls-services-resource/api/v1',
};

module.exports = certEdConfig;
