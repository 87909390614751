import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import Marketing from './Marketing/Marketing';
import useStyles from './TenantPickerLayout.styles';
import Footer from './Footer/Footer';

const TenantPickerLayout = ({ children }) => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();
  const showOnDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <div
      className={cx(classes.root, {
        [classes.heinemann]: platform === PLATFORMS.FLIGHT,
        [classes.hmh]: platform === PLATFORMS.ED,
      })}
    >
      {showOnDesktop && <Marketing />}
      <div
        className={cx(classes.content, {
          [classes.contentHeinemann]: platform === PLATFORMS.FLIGHT,
          [classes.contentHmh]: platform === PLATFORMS.ED,
        })}
      >
        {children}
        <div className={classes.spacing} />
        <Footer />
      </div>
    </div>
  );
};

TenantPickerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TenantPickerLayout;
