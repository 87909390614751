import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteCookie,
  getCookie,
} from '@hmhco/cookie-accessors/src/cookieHelper';
import { setLoginFailed } from '../store/actions/UsernameAndPasswordActions';

const FAILED_LOGIN_COOKIE = 'loginFailed';

function useCheckLoginCookies() {
  const dispatch = useDispatch();

  useEffect(() => {
    const loginFailed = getCookie(FAILED_LOGIN_COOKIE);
    if (loginFailed) {
      dispatch(setLoginFailed());
      deleteCookie(FAILED_LOGIN_COOKIE);
    }
  }, []);
}

export default useCheckLoginCookies;
