import { UsernameAndPasswordTypes } from '../actions/types';

const initialState = {
  loginHasFailed: false,
  showUsernameAndPassword: false,
  username: '',
  password: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UsernameAndPasswordTypes.LOGIN_FAILED: {
      return {
        ...initialState,
        loginHasFailed: true,
      };
    }
    case UsernameAndPasswordTypes.SET_USERNAME: {
      return {
        ...state,
        username: action.data,
        loginHasFailed: false,
      };
    }
    case UsernameAndPasswordTypes.SET_PASSWORD: {
      return {
        ...state,
        password: action.data,
        loginHasFailed: false,
      };
    }
    case UsernameAndPasswordTypes.SET_SHOW_USERNAME_AND_PASSWORD: {
      return {
        ...state,
        showUsernameAndPassword: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
