import { updateFields } from './utils';

export const createFieldObservers = widgetRef => {
  const fieldContainers = widgetRef.current.querySelectorAll(
    '[data-se="o-form-input-container"]',
  );

  const fieldObservers = [];

  fieldContainers.forEach(fieldContainer => {
    const fieldObserver = new MutationObserver(() => updateFields(widgetRef));

    fieldObserver.observe(fieldContainer, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    fieldObservers.push(fieldObserver);
  });

  return fieldObservers;
};

export default createFieldObservers;
