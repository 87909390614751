import { TenantPickerTypes } from '../actions/types';

const initialState = {
  oktaVisible: false,
  oktaWidgetConfig: {},
  didPidFail: false,
  userAlertId: null,
  loginStep: 1,
  loginFlow: null,
  loginHint: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TenantPickerTypes.OKTA_VISIBLE: {
      const { oktaVisible } = action;
      return {
        ...state,
        oktaVisible,
      };
    }
    case TenantPickerTypes.SET_DID_PID_FAIL: {
      const { didPidFail } = action;
      return {
        ...state,
        didPidFail,
      };
    }
    case TenantPickerTypes.SET_USER_ALERT: {
      const { userAlert } = action;
      return {
        ...state,
        userAlert,
      };
    }
    case TenantPickerTypes.SET_CACHED_OKTA_WIDGET_CONFIG: {
      const { oktaWidgetConfig } = action;
      return {
        ...state,
        oktaWidgetConfig,
      };
    }
    case TenantPickerTypes.LOGIN_STEP: {
      const { loginStep } = action;
      return {
        ...state,
        loginStep,
      };
    }
    case TenantPickerTypes.LOGIN_FLOW: {
      const { loginFlow } = action;
      return {
        ...state,
        loginFlow,
      };
    }
    case TenantPickerTypes.LOGIN_HINT: {
      const { loginHint } = action;
      return {
        ...state,
        loginHint,
      };
    }
    default: {
      return state;
    }
  }
};
