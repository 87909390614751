import React from 'react';
import propTypes from 'prop-types';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';

const errorHandler = (error, errorInfo) => {
  logErrorWithContext(
    `Login app - Error in component ${errorInfo?.componentStack}`,
    [{ key: 'errorMessage', value: error }],
  );
};

export default class ErrorBoundaryComponent extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    errorHandler(error, errorInfo);
  }

  render() {
    const {
      state: { hasError },
      props: { children, fallback },
    } = this;

    return hasError ? fallback : children;
  }
}

ErrorBoundaryComponent.propTypes = {
  children: propTypes.node.isRequired,
  fallback: propTypes.element,
};

ErrorBoundaryComponent.defaultProps = {
  fallback: null,
};
