import { useEffect, useState } from 'react';
import { systemAlert } from '@hmhco/system-alert-api/src/systemAlertApi';

export const THIRTY_SECONDS = 30000;

function useGetLoginMessage(maximumNumberOfLoginMessageRequests) {
  const [loginMessage, setLoginMessage] = useState({
    activate: false,
    content: '',
    title: '',
  });
  const { fetchSystemAlert, cancel } = systemAlert();
  let setMessageTimeoutId;
  let messageRequestCount = 0;

  const setMessage = () =>
    fetchSystemAlert().then(message => {
      messageRequestCount += 1;
      if (!message?.isCancelled) {
        setLoginMessage({
          activate: Boolean(message?.activate),
          content: message?.content || '',
          title: message?.title || '',
        });
      }
      if (
        window.location.pathname.includes('/ui/login') &&
        messageRequestCount < maximumNumberOfLoginMessageRequests
      ) {
        setMessageTimeoutId = setTimeout(setMessage, THIRTY_SECONDS);
      }
    });

  useEffect(() => {
    setMessage();
    return () => {
      cancel('Cancelled by useGetLoginMessage');
      clearTimeout(setMessageTimeoutId);
    };
  }, []);

  return loginMessage;
}

export default useGetLoginMessage;
