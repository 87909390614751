/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useButtonStyles = makeStyles(
  { name: 'AsyncButton' },
  {
    root: {},
    disabled: {
      color: 'var(--ebl-text-black) !important',
    },
  },
);

const useProgressStyles = makeStyles(
  {
    root: {
      marginRight: 'var(--ebl-button-icon-text-padding)',
    },
  },
  { name: 'AsyncButtonCircularProgress' },
);

const AsyncButton = React.forwardRef((props, ref) => {
  const { buttonProps, children } = props;
  const { classes: buttonClasses } = useButtonStyles(buttonProps);
  const { classes: progressClasses } = useProgressStyles();
  const [loading, setLoading] = useState();

  return (
    <Button
      ref={ref}
      {...buttonProps}
      onClick={event => {
        const result = buttonProps.onClick(event);
        if (result && result.finally) {
          setLoading(true);
          result.finally(() => setLoading(false));
        }
      }}
      disabled={loading || buttonProps?.disabled}
      classes={buttonClasses}
    >
      {loading ? (
        <>
          <CircularProgress
            size={20}
            classes={progressClasses}
            role="none presentation"
          />
          {buttonProps?.loadingText || children}
        </>
      ) : (
        children
      )}
    </Button>
  );
});

AsyncButton.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

AsyncButton.defaultProps = {
  children: null,
};

export default AsyncButton;
