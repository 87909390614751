const prodSupportToolConfig = {
  app: 'HCP Support App',
  name: 'prod',
  edsLoginLandingPage: 'https://hcp-support-tool.tsg.hmhco.com/',
  edsLogoutUrl: 'https://app01.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: 'https://app01.tsg.hmhco.com/hcp-support-tool-web/',
  edsLoginClientId: '2FA1159E-6CA1-426F-9482-2240D048F094',
  edsLoginIssuer: 'hmh-prod-57457e14-6df9-458d-8787-722b0c20a807',
  edsLoginUrl: 'https://hapi.hmhco.com/edcore/eds-login/login/initializeUri',
  edsServiceUrl: 'https://app01.tsg.hmhco.com/edcore/eds-core-app',
  appEndPointUrl:
    'https://hcp-support-tool-api.tsg.hmhco.com/hcp-support-tool/',
  emailTokenURL: 'https://hmh-identity-provider.br.hmheng.io/user/signupToken',
  commonIdsUrl: 'https://api.br.internal/ids-grid-api/ids/',
  edsGraphUrl: 'https://api.heinemann.com/graphql',
  idmUserApiUrl: 'https://api.br.internal/idm-user-api/',
  idmConfigUrl: 'https://config-idm.br.hmheng.io',
  hmhIdentityProvider: 'https://hmh-identity-provider.br.hmheng.io',
  edcoreGatewayUrl: 'https://hapi.hmhco.com/edcore/hmheng-eds-api-gateway/',
};

module.exports = prodSupportToolConfig;
