const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const localHnmConfig = {
  name: 'local',
  edsServiceUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-core-app',
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/landing-page`,
  contentShareAppUrl: 'https://local.hmhone.hmhco.com:9003/',
  edsLogoutUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: `https://local.hmhone.hmhco.com:${currentPort}/`,
  flightRosteredLoginUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: 'https://hnm-dev.authorization.heinemann.com',
  edsGraphUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  edsLtiUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti',
  edLtiUrl: 'https://api.int.br.internal/lti-platform',
  rceProtocolHost: 'http://int.hmhone.app.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: true,
  edsContentShareClientId: 'b9f2100f-4f3a-449d-8ab7-26b3fd1856ec',
  edsContentShareInitializeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/authorize',
  edsContentShareTokenUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/token',
  licensesRemoteEntrypoint: 'https://local.hmhone.hmhco.com:9022/nlEntry.js',
  restUdsApi: 'https://api.int.eng.hmhco.com/uds/',
};

module.exports = localHnmConfig;
