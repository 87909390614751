import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import removeIcon from '@ed/baseline/icons/cts/remove-md.svg';
import Icon from '@hmhco/icon';
import { useStyles } from 'tss-react/mui';

const CloseButton = ({ onClose, ...buttonProps }) => {
  const { formatMessage } = useIntl();
  const closeLabel = formatMessage({ id: 'alertNotification.icon.close' });
  const { theme } = useStyles();
  return (
    <IconButton
      onClick={onClose}
      size="small"
      aria-label={closeLabel}
      title={closeLabel}
      {...buttonProps}
    >
      <Icon svg={removeIcon} size="16" colour={theme.palette.ebl.actionColor} />
    </IconButton>
  );
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseButton;
