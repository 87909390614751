/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import keyMirror from '@hmhco/react-utils/src/keyMirror';

function createIcon(svg) {
  return { __html: svg };
}

const DISPLAY_TYPE_MAP = {
  outlined: 'flex',
  default: 'block',
  noDisplayValue: null,
  inline: 'inline',
  inlineBlock: 'inline-block',
};

export const DISPLAY_TYPES = keyMirror(DISPLAY_TYPE_MAP);

const Icon = ({
  size,
  svg,
  colour,
  display,
  Component,
  mixBlendMode,
  ...domProps
}) => {
  const displayType = DISPLAY_TYPE_MAP[display];
  const displayValue = { display: displayType };

  return (
    <Component
      {...domProps}
      style={{
        height: `${size}px`,
        width: `${size}px`,
        flexShrink: 0,
        fill: colour,
        mixBlendMode,
        ...displayValue,
      }}
      dangerouslySetInnerHTML={createIcon(svg)}
    />
  );
};

Icon.defaultProps = {
  colour: '#000',
  display: 'default',
  Component: 'div',
  mixBlendMode: null,
};

Icon.propTypes = {
  /**  Import the svg you want to use from @ed/baseline and use this prop */
  svg: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  colour: PropTypes.string,
  display: PropTypes.oneOf(Object.keys(DISPLAY_TYPES)),
  Component: PropTypes.node,
  mixBlendMode: PropTypes.string,
};

export default Icon;
