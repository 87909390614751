export const TYPOGRAPHY_COMPONENTS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p',
  SPAN: 'span',
  DIV: 'DIV',
  STRONG: 'strong',
  A: 'a',
};
Object.freeze(TYPOGRAPHY_COMPONENTS);

// exporting default variants to use in development as enums

export const TYPOGRAPHY_VARIANTS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SUBTITLE1: 'subtitle1',
  SUBTITLE2: 'subtitle2',
  OVERLINE: 'overline',
  BUTTON: 'button',
  BODY1: 'body1',
  BODY2: 'body2',
  BODY1BOLD: 'body1Bold',
  BODY2BOLD: 'body2Bold',
};
Object.freeze(TYPOGRAPHY_VARIANTS);

export default {
  fontFamily: 'var(--ebl-wf-headings)',
  // headings
  [TYPOGRAPHY_VARIANTS.H1]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h1)',
    lineHeight: 'var(--ebl-h1-line-height)',
    letterSpacing: 'var(--ebl-h1-letter-spacing)',
    color: 'var(--ebl-h1-color)',
    fontWeight: 'var(--ebl-h1-weight)',
  },
  [TYPOGRAPHY_VARIANTS.H2]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h2)',
    lineHeight: 'var(--ebl-h2-line-height)',
    letterSpacing: 'var(--ebl-h2-letter-spacing)',
    color: 'var(--ebl-h2-color)',
    fontWeight: 'var(--ebl-h2-weight)',
  },
  [TYPOGRAPHY_VARIANTS.H3]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h3)',
    lineHeight: 'var(--ebl-h3-line-height)',
    letterSpacing: 'var(--ebl-h3-letter-spacing)',
    color: 'var(--ebl-h3-color)',
    fontWeight: 'var(--ebl-h3-weight)',
  },
  [TYPOGRAPHY_VARIANTS.H4]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h4)',
    lineHeight: 'var(--ebl-h4-line-height)',
    letterSpacing: 'var(--ebl-h4-letter-spacing)',
    color: 'var(--ebl-h4-color)',
    fontWeight: 'var(--ebl-h4-weight)',
  },
  [TYPOGRAPHY_VARIANTS.H5]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h5)',
    lineHeight: 'var(--ebl-h5-line-height)',
    letterSpacing: 'var(--ebl-h5-letter-spacing)',
    color: 'var(--ebl-h5-color)',
    fontWeight: 'var(--ebl-h5-weight)',
  },
  [TYPOGRAPHY_VARIANTS.H6]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-h6)',
    lineHeight: 'var(--ebl-h6-line-height)',
    letterSpacing: 'var(--ebl-h6-letter-spacing)',
    color: 'var(--ebl-h6-color)',
    fontWeight: 'var(--ebl-h6-weight)',
  },
  /* --Subheadings-- */
  [TYPOGRAPHY_VARIANTS.SUBTITLE1]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh1)',
    lineHeight: 'var(--ebl-sh1-line-height)',
    letterSpacing: 'var(--ebl-sh1-letter-spacing)',
    color: 'var(--ebl-sh1-color)',
    fontWeight: 'var(--ebl-sh1-weight)',
  },
  [TYPOGRAPHY_VARIANTS.SUBTITLE2]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh2)',
    lineHeight: 'var(--ebl-sh2-line-height)',
    letterSpacing: 'var(--ebl-sh2-letter-spacing)',
    color: 'var(--ebl-sh2-color)',
    fontWeight: 'var(--ebl-sh2-weight)',
  },
  [TYPOGRAPHY_VARIANTS.OVERLINE]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh3)',
    lineHeight: 'var(--ebl-sh3-line-height)',
    letterSpacing: 'var(--ebl-sh3-letter-spacing)',
    color: 'var(--ebl-sh3-color)',
    fontWeight: 'var(--ebl-sh3-weight)',
    textTransform: 'none',
  },
  [TYPOGRAPHY_VARIANTS.BUTTON]: {
    fontFamily: 'var(--ebl-wf-headings)',
    fontSize: 'var(--ebl-sh4)',
    lineHeight: 'var(--ebl-sh4-line-height)',
    letterSpacing: 'var(--ebl-sh4-letter-spacing)',
    color: 'var(--ebl-sh4-color)',
    fontWeight: 'var(--ebl-sh4-weight)',
    textTransform: 'none',
  },
  /* --Body-- */
  [TYPOGRAPHY_VARIANTS.BODY1]: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p1)',
    lineHeight: 'var(--ebl-p1-line-height)',
    letterSpacing: 'var(--ebl-p1-letter-spacing)',
    color: 'var(--ebl-p1-color)',
    fontWeight: 'var(--ebl-noto)',
  },
  [TYPOGRAPHY_VARIANTS.BODY2]: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p2)',
    lineHeight: 'var(--ebl-p2-line-height)',
    letterSpacing: 'var(--ebl-p2-letter-spacing)',
    color: 'var(--ebl-p2-color)',
    fontWeight: 'var(--ebl-noto)',
  },
  // p1 bold
  [TYPOGRAPHY_VARIANTS.BODY1BOLD]: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p1)',
    lineHeight: 'var(--ebl-p1-line-height)',
    letterSpacing: 'var(--ebl-p1-letter-spacing)',
    color: 'var(--ebl-p1-color)',
    // fontWeight: 'new var', once baseline variable is available need to replace it with variable
    fontWeight: '700',
  },

  // p2 bold
  [TYPOGRAPHY_VARIANTS.BODY2BOLD]: {
    fontFamily: 'var(--ebl-wf-body)',
    fontSize: 'var(--ebl-p2)',
    lineHeight: 'var(--ebl-p2-line-height)',
    letterSpacing: 'var(--ebl-p2-letter-spacing)',
    color: 'var(--ebl-p2-color)',
    // fontWeight: 'new var', once baseline variable is available need to replace it with variable
    fontWeight: '700',
  },
};
