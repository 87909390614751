import { useLocation } from 'react-router-dom';
import { getConnectionPid } from '../storageHelpers/localStorageAccessors';

export function useGetConnectionFromUrl() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  return search.get('connection');
}

export function useGetConnectionFromStorage() {
  return getConnectionPid();
}

function useGetConnectionParam() {
  const connectionParam = useGetConnectionFromUrl();
  // Storage connection has lower precedence than the connection string in the url
  const connectionFromStorage = useGetConnectionFromStorage();
  return connectionParam || connectionFromStorage;
}

export default useGetConnectionParam;
