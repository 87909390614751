import { createSelector } from 'reselect';

export const getRequestSigninState = state => state.requestSigninState;

export const getRequestSigninEmail = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState?.email,
);
export const getIsRequestSigninValid = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState?.requestSigninIsValid,
);

export const getIsRequestSigninDisabled = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState?.requestSigninDisabled,
);

export const getRequestSigninIsLoading = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState.loading,
);

export const getRequestSigninIsErrored = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState.error,
);

export const getRequestSigninHasSucceeded = createSelector(
  [getRequestSigninState],
  requestSigninState => requestSigninState.success,
);

export const getShouldRenderErrorScreen = createSelector(
  [getRequestSigninIsLoading, getRequestSigninIsErrored],
  (isLoading, isErrored) => {
    if (isLoading) {
      return false;
    }
    return isErrored;
  },
);

export const getShouldRenderSuccessScreen = createSelector(
  [getRequestSigninIsLoading, getRequestSigninHasSucceeded],
  (isLoading, hasSucceeded) => {
    if (isLoading) {
      return false;
    }
    return hasSucceeded;
  },
);

export const getShouldRenderRequestSigninForm = createSelector(
  [getShouldRenderErrorScreen, getShouldRenderSuccessScreen],
  (shouldRenderErrorScreen, shouldRenderSuccessScreen) => {
    return !(shouldRenderErrorScreen || shouldRenderSuccessScreen);
  },
);

export const getCanSubmitEmail = createSelector(
  [getShouldRenderErrorScreen, getShouldRenderSuccessScreen],
  (shouldRenderErrorScreen, shouldRenderSuccessScreen) => {
    return !(shouldRenderErrorScreen || shouldRenderSuccessScreen);
  },
);
