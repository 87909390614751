import React from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BackButton } from '@hmhco/back-button/src/BackButton';
import PropTypes from 'prop-types';
import useStyles from './ForgotPasswordButtons.styles';
import forgotPasswordApi from '../../api/forgotPassword';
import {
  getForgotPasswordUsername,
  getIsForgotPasswordUsernameValid,
  getCanSubmitUsername,
} from '../../store/selectors/ForgotPasswordPageState';
import useGetConnectionParam from '../../hooks/getConnectionParam.hook';
import {
  setForgotPasswordError,
  setForgotPasswordLoading,
  setForgotPasswordSuccess,
} from '../../store/actions/ForgotPasswordActions';

const ForgotPasswordButtons = ({ currentTheme }) => {
  const canSubmitUsername = useSelector(getCanSubmitUsername);
  const history = useHistory();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentUsername = useSelector(getForgotPasswordUsername);
  const connection = useGetConnectionParam();
  const canRequestPasswordChange = useSelector(
    getIsForgotPasswordUsernameValid,
  );

  const showOnMobileUp = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const showOnlyOnMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onSubmit = async event => {
    event.preventDefault();
    const { forgotPassword, forgotPasswordHnm } = forgotPasswordApi();
    try {
      dispatch(setForgotPasswordLoading());
      if (currentTheme === 'HNM') {
        await forgotPasswordHnm({
          username: currentUsername,
          districtId: connection,
        });
      } else {
        await forgotPassword({
          username: currentUsername,
          districtId: connection,
        });
      }
      dispatch(setForgotPasswordSuccess());
    } catch (error) {
      dispatch(setForgotPasswordError());
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const backToLoginButton = (
    <BackButton
      title={<FormattedMessage id="login.forgotPassword.backButton" />}
      backButtonClick={goBack}
      role="link"
    />
  );

  return (
    <div className={classes.buttons}>
      {showOnMobileUp && backToLoginButton}
      {canSubmitUsername && (
        <Button
          disabled={!canRequestPasswordChange}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={onSubmit}
        >
          <FormattedMessage id="login.forgotPassword.submitButton" />
        </Button>
      )}
      {showOnlyOnMobile && backToLoginButton}
    </div>
  );
};

ForgotPasswordButtons.propTypes = {
  currentTheme: PropTypes.string,
};

ForgotPasswordButtons.defaultProps = {
  currentTheme: null,
};

export default ForgotPasswordButtons;
