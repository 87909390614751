import { getOktaVisible } from '../storageHelpers/sessionStorageAccessors';

function useOktaVisible() {
  const oktaVisible = getOktaVisible();
  if (!oktaVisible) {
    return null;
  }
  return oktaVisible;
}

export default useOktaVisible;
