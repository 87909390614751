import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'LoginMarketingSection' })({
  heinemann: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
  },
  ed: {
    width: '100%',
    height: '100%',
  },
});
