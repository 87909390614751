import { getCachedOrgInfo } from '../storageHelpers/sessionStorageAccessors';

function useGetCachedOrgInfo(orgPid) {
  const cachedInfo = getCachedOrgInfo(orgPid);
  if (!cachedInfo) {
    return null;
  }
  return cachedInfo;
}

export default useGetCachedOrgInfo;
