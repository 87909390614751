import { UsernameAndPasswordTypes } from './types';

export const setLoginFailed = () => ({
  type: UsernameAndPasswordTypes.LOGIN_FAILED,
});

export const setUsername = username => ({
  type: UsernameAndPasswordTypes.SET_USERNAME,
  data: username,
});

export const setPassword = password => ({
  type: UsernameAndPasswordTypes.SET_PASSWORD,
  data: password,
});

export const setShowUsernameAndPassword = show => {
  return {
    type: UsernameAndPasswordTypes.SET_SHOW_USERNAME_AND_PASSWORD,
    data: show,
  };
};
