import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon, { DISPLAY_TYPES } from '@hmhco/icon/src/Icon';
import removeIcon from '@ed/baseline/icons/cts/remove-md.svg';
import { bool, func, node, oneOf, oneOfType } from 'prop-types';
import { useIntl } from 'react-intl';
import useStyles from './HcpAlert.styles';
import { ALERT_TEST_ID } from './constants';
/**
 * Represents an action component for the HcpAlert.
 *
 * @param {Object} props - Component props.
 * @param {boolean|ReactNode} props.action - The action to display (either a boolean or a ReactNode).
 * @param {function} props.onClose - Callback function to handle close action.
 * @returns {JSX.Element} - The ActionComp component.
 */
function ActionComp({ action, onClose }) {
  const { theme } = useStyles();
  const { formatMessage } = useIntl();
  const closeLabel = formatMessage({ id: 'hcpAlert.closeButton.aria-label' });

  if (!action) {
    // Render a close button with the remove icon
    return (
      <IconButton
        onClick={onClose}
        size="small"
        aria-label={closeLabel}
        data-testid={ALERT_TEST_ID.CLOSE_BUTTON}
      >
        <Icon
          svg={removeIcon}
          size="16"
          colour={theme.palette.ebl.actionColor}
          aria-hidden="true"
          display={DISPLAY_TYPES.noDisplayValue}
        />
      </IconButton>
    );
  }

  // Render the provided action
  return <>{action}</>;
}

// Prop types for ActionComp component
ActionComp.propTypes = {
  action: oneOfType([node, oneOf([bool, undefined])]), // Either a boolean or a ReactNode
  onClose: func, // Callback function for close action
};

ActionComp.defaultProps = {
  action: undefined,
  onClose: undefined,
};

export default ActionComp;
