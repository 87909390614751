const computedStyle = getComputedStyle(document.documentElement);

/**
 * Read the hexadecimal code for the color
 *
 * The color can be either in provided styles object (key / value associative object)
 * or defined as CSS variables (fallback as we allow partial override of variables)
 *
 * @param {string} variableName CSS variable name like `--ebl-action-color`
 * @param {object} styles key/value association of css variables and css colors
 * @returns
 */
const getHexFromVariable = (variableName, styles = null) =>
  styles?.[variableName]
    ? styles[variableName]
    : computedStyle.getPropertyValue(variableName).trim();

export default getHexFromVariable;
