import React from 'react';
import { useSelector } from 'react-redux';
import InfoRequiredFields from '@hmhco/form-components/src/InfoRequiredFields';
import PropTypes from 'prop-types';
import LoginAppLayout from '../Layout/LoginAppLayout';
import useBootstrapOrgInfo from '../../hooks/bootstrapForgotPasswordOrgInfo.hook';
import LoginAppFormLayout from '../Layout/LoginAppFormLayout';
import StudentMessage from './StudentMessage';
import ForgotPasswordInformationHeader from './ForgotPasswordInformationHeader';
import OrgInfo from './OrgInfo';
import ForgotPasswordUsername from './ForgotPasswordUsername';
import ForgotPasswordButtons from './ForgotPasswordButtons';
import {
  getShouldRenderErrorScreen,
  getShouldRenderForgotPasswordForm,
  getShouldRenderSuccessScreen,
} from '../../store/selectors/ForgotPasswordPageState';
import ForgotPasswordErrorState from './ForgotPasswordErrorState';
import ForgotPasswordSuccessState from './ForgotPasswordSuccessState';
import { getIsForgotPasswordDisabled } from '../../store/selectors/ForgotPasswordOrg';
import ForgotPasswordDisabledMessage from './ForgotPasswordDisabledMessage';

const ForgotPasswordPage = ({ currentTheme }) => {
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const renderForgotPasswordForm = useSelector(
    getShouldRenderForgotPasswordForm,
  );
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  useBootstrapOrgInfo();
  return (
    <LoginAppLayout currentTheme={currentTheme}>
      <LoginAppFormLayout currentTheme={currentTheme}>
        {renderErrorState && <ForgotPasswordErrorState />}
        {renderSuccessState && <ForgotPasswordSuccessState />}
        {renderForgotPasswordForm && (
          <>
            <ForgotPasswordInformationHeader />
            <StudentMessage />
            {!forgotPasswordDisabled && <InfoRequiredFields fontSize="12" />}
            <OrgInfo />
            <ForgotPasswordUsername />
            <ForgotPasswordDisabledMessage />
            <ForgotPasswordButtons currentTheme={currentTheme} />
          </>
        )}
      </LoginAppFormLayout>
    </LoginAppLayout>
  );
};

ForgotPasswordPage.propTypes = {
  currentTheme: PropTypes.string,
};

export default ForgotPasswordPage;
