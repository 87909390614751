/**
 * read value stored in cookie
 *
 * @param {string} cname
 * @returns {string} value of the cookie
 */
export const getCookie = cname => {
  const name = `${cname}=`;
  return document.cookie
    .split(';')
    .filter(cookie => cookie.trim().indexOf(name) === 0)
    .map(cookie => cookie.substring(name.length, cookie.length))[0];
};

/**
 * delete a cookie by setting the expiration date in the past
 *
 * @param {string} cname
 */
export const deleteCookie = cname => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * create a cookie with the given value and expiring in the provided number of days
 *
 * @param {string} cname
 * @param {string} cvalue
 * @param {number} exdays number of days until expiration
 */
export const setCookie = (cname, cvalue, exdays) => {
  let expires = '';
  if (exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = `expires=${d.toUTCString()};`;
  }
  document.cookie = `${cname}=${cvalue || ''};${expires}path=/`;
};
