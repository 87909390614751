import React, { useRef, useEffect, useState } from 'react';
import UserAlert from './UserAlert/UserAlert';
import SuccessAlert from './SuccessAlert/SuccessAlert';
import SystemAlert from './SystemAlert/SystemAlert';
import useStyles from './Alerts.styles';
import OktaInfoAlert from './OktaInfoAlert/OktaInfoAlert';
import OktaErrorAlert from './OktaErrorAlert/OktaErrorAlert';

const Alerts = () => {
  const { cx, classes } = useStyles();

  const ref = useRef(null);

  const [hasAlert, setHasAlert] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const observer = new MutationObserver(() => {
      setHasAlert(!!ref.current?.querySelector('#alerts > :not([hidden])'));
    });

    observer.observe(ref.current, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => observer.disconnect();
  }, [hasAlert]);

  return (
    <div
      ref={ref}
      id="alerts"
      className={cx(classes.root, { [classes.hasAlert]: hasAlert })}
    >
      <OktaErrorAlert />
      <OktaInfoAlert />
      <UserAlert />
      <SystemAlert />
      <SuccessAlert />
    </div>
  );
};

export default Alerts;
