import {
  CONFIG_TYPES,
  ENVIRONMENTS,
  DEFAULT_CONFIG_TYPE_MAP,
} from '../../../../environments/globalConstants';
import configMap from './configMap';

/**
 * return configuration for the environment passed as argument
 *
 * @param {string} env name of the environment, expected: local, int, cert devCert, or prod
 * @param {string} configType the platform the config is for, static consts can be found in CONFIG_TYPES
 *
 * @returns {object} configuration (endpoints urls)
 */

export const getConfig = (env, configType) => {
  const envValues = Object.values(ENVIRONMENTS);
  const configValues = Object.values(CONFIG_TYPES);
  if (!envValues.includes(env)) {
    throw new Error(
      `Environment: "${env}" is not supported, please use one of the following: [${envValues}]`,
    );
  }

  if (!configValues.includes(configType)) {
    throw new Error(
      `Config type: "${configType}" is not supported, please use one of the following: [${configValues}]`,
    );
  }

  return configMap[env][configType];
};

export const getCurrentEnv = () =>
  window.HMH_ENV ||
  process.env.RUNTIME_ENV ||
  process.env.STORYBOOK_ENV ||
  window.RUNTIME_ENV;

export const getConfigForCurrentEnv = configType => {
  return getConfig(getCurrentEnv(), configType);
};
export const getConfigTypeForPlatform = platform => {
  return DEFAULT_CONFIG_TYPE_MAP[platform];
};

export { CONFIG_TYPES };
