import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './ForgotPasswordUsername.styles';
import { setForgotPasswordUsername } from '../../store/actions/ForgotPasswordActions';
import { getIsForgotPasswordDisabled } from '../../store/selectors/ForgotPasswordOrg';
import { AUTO_COMPLETE } from '../../constants/constants';

const ForgotPasswordUsername = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const changeCallback = useCallback(event => {
    const newUsername = event?.target?.value;
    dispatch(setForgotPasswordUsername(newUsername));
  }, []);

  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  if (forgotPasswordDisabled) {
    return null;
  }

  return (
    <FormattedMessage id="login.forgotPassword.usernameLabel">
      {usernameLabel => (
        <TextField
          id="username"
          name="username"
          onChange={changeCallback}
          label={usernameLabel}
          inputProps={{
            'data-testid': 'username',
            className: classes.input,
          }}
          required
          autoComplete={AUTO_COMPLETE.USERNAME}
          helperText={
            <FormattedMessage id="login.forgotPassword.usernameHelpText" />
          }
        />
      )}
    </FormattedMessage>
  );
};

export default ForgotPasswordUsername;
