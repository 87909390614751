const validCharactersRegex = /^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}\\~¡¢£¤¥¦§¨©ª«¬\-®¯°±²³´¶¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþ]{1,}$/;

const usernameValidator = username => {
  let errorMessage;
  const usernameRequired = 'login.newPassword.username.empty';
  const userNameTooShort = 'login.newPassword.username.tooShort';
  const usernameContainsInvalidCharacter =
    'login.newPassword.username.invalidCharacter';
  const usernameTooLong = 'login.newPassword.username.tooLong';

  if (username !== undefined) {
    if (username.length === 0) {
      errorMessage = usernameRequired;
    } else if (username.length < 5) {
      errorMessage = userNameTooShort;
    } else if (!validCharactersRegex.test(username)) {
      errorMessage = usernameContainsInvalidCharacter;
    } else if (username.length > 75) {
      errorMessage = usernameTooLong;
    }
  }

  return {
    isUsernameValid: errorMessage === undefined && username !== undefined,
    usernameErrorMessageId: errorMessage,
  };
};

export default usernameValidator;
