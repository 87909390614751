import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { arrayOf, func, shape, string } from 'prop-types';
import ComboBox from '@hmhco/combobox/src/ComboBox/ComboBox';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './StateInput.styles';
import { getDidPidFail } from '../../store/selectors/TenantPickerState';
import { setDidPidFail } from '../../store/actions/TenantPickerActions';

const StateInput = ({ states, onChange, selectedState }) => {
  const { classes } = useStyles();
  const didPidFail = useSelector(getDidPidFail);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.stateContainer}>
      <FormattedMessage id="login.form.state.label">
        {([label]) => (
          <FormattedMessage id="login.form.state.placeholder">
            {([placeholder]) => (
              <ComboBox
                autoHighlight
                classes={{ input: classes.input }}
                required
                placeholder={placeholder}
                dataTestId="loginStateSelect"
                label={label}
                options={states}
                getOptionLabel={option => option.name || ''}
                value={selectedState}
                handleChange={(event, value) => {
                  dispatch(setDidPidFail(false));
                  onChange(value);
                }}
                altError={didPidFail}
                altErrorText={formatMessage({
                  id: 'login.form.didPidFailed.stateFieldError',
                })}
              />
            )}
          </FormattedMessage>
        )}
      </FormattedMessage>
    </div>
  );
};

const stateShape = shape({ code: string, name: string });
StateInput.propTypes = {
  states: arrayOf(stateShape).isRequired,
  onChange: func.isRequired,
  selectedState: stateShape,
};

export default StateInput;
