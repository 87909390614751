const CONFIG_TYPES = {
  ed: 'ed',
  hnm: 'hnm', // Flight apps running in flight
  hybrid: 'hybrid', // Flight apps running in amp-container
  supportTool: 'supportTool',
};

const PLATFORMS = {
  ED: 'HMH',
  FLIGHT: 'HNM',
};

const ENVIRONMENTS = {
  local: 'local',
  dev: 'dev',
  int: 'int',
  cert: 'cert',
  devCert: 'devCert',
  prod: 'prod',
};

// use this to assign default configs types for platforms
const DEFAULT_CONFIG_TYPE_MAP = {
  [PLATFORMS.ED]: CONFIG_TYPES.ed,
  [PLATFORMS.FLIGHT]: CONFIG_TYPES.hybrid, // hybrid is the former 'hnm' config for amp apps (not flight)
};

module.exports = {
  CONFIG_TYPES,
  PLATFORMS,
  ENVIRONMENTS,
  DEFAULT_CONFIG_TYPE_MAP,
};
