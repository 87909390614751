import React from 'react';
import { useSelector } from 'react-redux';
import OrgInput from './OrgInput';
import { getRenderInternationalForm } from '../../store/selectors/CountryStateOrg';

const InternationalForm = () => {
  const renderInternationalForm = useSelector(getRenderInternationalForm);

  if (!renderInternationalForm) {
    return null;
  }
  return <OrgInput />;
};

export default InternationalForm;
