const supportedCharacters = /^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}\\~¡¢£¤¥¦§¨©ª«¬\-®¯°±²³´¶¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþ]{1,}$/;
const uppercasePattern = /.*[A-Z]/;
const lowercasePattern = /.*[a-z]/;
const digitPattern = /.*[0-9]/;
const specialPattern = /.*[^A-Za-z0-9]/;

const passwordValidator = password => {
  let passwordErrorMessageId;
  const passwordRequiredId = 'login.newPassword.errorMessage.passwordRequired';
  const passwordTooShortId = 'login.newPassword.errorMessage.passwordTooShort';
  const unsupportedCharacterId =
    'login.newPassword.errorMessage.passwordContainsUnsupportedCharacter';

  const passwordNotComplexId =
    'login.newPassword.errorMessage.password.complexity';

  const isPasswordComplexEnough = passwordToTest => {
    const hasUpppercase = uppercasePattern.test(passwordToTest);
    const hasLowercase = lowercasePattern.test(passwordToTest);
    const hasDigit = digitPattern.test(passwordToTest);
    const hasSpecialCharacter = specialPattern.test(passwordToTest);
    return hasUpppercase && hasLowercase && hasDigit && hasSpecialCharacter;
  };

  if (password !== undefined) {
    if (password === '') {
      passwordErrorMessageId = passwordRequiredId;
    } else if (password.length < 8) {
      passwordErrorMessageId = passwordTooShortId;
    } else if (!supportedCharacters.test(password)) {
      passwordErrorMessageId = unsupportedCharacterId;
    } else if (!isPasswordComplexEnough(password)) {
      passwordErrorMessageId = passwordNotComplexId;
    }
  }

  return {
    isPasswordValid:
      passwordErrorMessageId === undefined && password !== undefined,
    passwordErrorMessageId,
  };
};

export default passwordValidator;
