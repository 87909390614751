import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'TenantPickerLayout' })(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
  },
  hmh: {
    backgroundColor: 'var(--ebl-pagelayout-login-brand-color)',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'white',
    },
  },
  heinemann: {
    backgroundColor: '#E3F7FA',
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'white',
    },
  },
  content: {
    padding: 'var(--ebl-s4)',
    display: 'flex',
    width: '535px',
    maxWidth: '535px',
    flexDirection: 'column',
    flex: 1,
    '.MuiFormControl-root': {
      width: '100%',
    },
  },
  contentHmh: {
    backgroundColor: 'white',
  },
  contentHeinemann: {
    backgroundColor: 'white',
  },
  spacing: {
    flex: 1,
    minHeight: 'var(--ebl-s8)',
  },
}));
