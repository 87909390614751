import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'LoginLogo' })(() => ({
  logoContainer: {
    paddingTop: 'var(--ebl-s3)',
    paddingBottom: 'var(--ebl-s3)',
  },
  svg: {
    width: `158px`, // Todo: Get baseline variable for this
  },
}));
