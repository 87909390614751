import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'UploadInstructions' })(
  (_theme, { isIconFirst }, classes) => ({
    link: {
      [`&:visited .${classes.linkIcon}`]: {
        fill: 'var(--ebl-link-visited-color) !important',
      },
    },
    linkIcon: {
      display: 'inline-block !important',
      fill: 'var(--ebl-action-color) !important',
      marginLeft: isIconFirst
        ? '0'
        : 'var(--ebl-contentblock-icon-secondary-marginright)',
      marginRight: isIconFirst
        ? 'var(--ebl-contentblock-icon-secondary-marginright)'
        : '0',
      verticalAlign: 'middle',
      height: '16px',
      width: '16px',
      lineHeight: '16px', // correctly center the icon
    },
  }),
);
