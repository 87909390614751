import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ForgotPasswordButtons' })(theme => ({
  buttons: {
    paddingTop: 'var(--ebl-s4)',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  backToButton: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
