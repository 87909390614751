import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '@hmhco/icon';
import changeSvgMd from '@ed/baseline/icons/cts/change-md.svg';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import useStyles from './SelectedOrganization.styles';

const SelectedOrganization = ({ organization, onClick }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const showOnDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="subtitle2">
        <FormattedMessage id="tenantPicker.selectedOrg" />
      </Typography>
      <div className={classes.organization}>
        <Typography component="p" variant="body2">
          {organization?.name}
        </Typography>
        {onClick && (
          <Button
            size="small"
            className={classes.button}
            startIcon={
              <Icon
                svg={changeSvgMd}
                size="16"
                colour="var(--ebl-button-frameless-text-color)"
              />
            }
            aria-label={formatMessage({
              id: 'tenantPicker.changeOrgAriaLabel',
            })}
            variant="text"
            fullWidth={false}
            onClick={onClick}
          >
            {showOnDesktop && <FormattedMessage id="tenantPicker.changeOrg" />}
          </Button>
        )}
      </div>
    </div>
  );
};

SelectedOrganization.propTypes = {
  organization: PropTypes.object,
  onClick: PropTypes.func,
};

SelectedOrganization.defaultProps = {
  organization: undefined,
  onClick: undefined,
};

export default SelectedOrganization;
