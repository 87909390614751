import { CountryStateOrgTypes } from '../actions/types';

const DEFAULT_COUNTRY = { code: 'US', name: 'United States' };

const initialState = {
  country: DEFAULT_COUNTRY,
  state: null,
  org: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CountryStateOrgTypes.SET_COUNTRY: {
      return {
        country: action?.country || null,
        state: null,
        org: null,
      };
    }
    case CountryStateOrgTypes.SET_STATE: {
      return {
        ...state,
        state: action?.state || null,
        org: null,
      };
    }
    case CountryStateOrgTypes.SET_ORG: {
      return {
        ...state,
        org: action?.org || null,
      };
    }
    case CountryStateOrgTypes.SET_CACHED_ORG: {
      const orgData = action.data;
      const newOrgState = {
        ...(orgData?.org || {}),
        isConnectionPid: true,
      };
      return {
        country: orgData?.country || null,
        state: orgData?.state || null,
        org: newOrgState,
      };
    }
    default: {
      return state;
    }
  }
};
