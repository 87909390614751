import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'SimpleNotification' })(
  (_theme, { justifyContent }) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent,
    },
  }),
);

const SimpleNotification = props => {
  const { icon, variant, component, children, id } = props;
  const { classes } = useStyles(props, {
    props,
  });

  return (
    <Box className={classes.root}>
      {icon && <Box marginRight={2}>{icon}</Box>}
      <Typography id={id} variant={variant} component={component}>
        {children}
      </Typography>
    </Box>
  );
};

SimpleNotification.propTypes = {
  children: PropTypes.node.isRequired,
  // justifyContent is used for styling but eslint doesn't see it
  // eslint-disable-next-line react/no-unused-prop-types
  justifyContent: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  icon: PropTypes.node,
  id: PropTypes.string,
  variant: PropTypes.string,
  component: PropTypes.string,
};

SimpleNotification.defaultProps = {
  justifyContent: 'center',
  icon: undefined,
  id: null,
  variant: 'subtitle1',
  component: 'p',
};

export default SimpleNotification;
