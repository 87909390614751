export const FETCH_ADMINISTRATOR = 'FETCH_ADMINISTRATOR';
export const FETCH_CLASS = 'FETCH_CLASS';
export const FETCH_CLASS_ROSTERS = 'FETCH_CLASS_ROSTERS';
export const FETCH_CLASSES = 'FETCH_CLASSES';
export const FETCH_FEATURE_FLAG = 'FETCH_FEATURE_FLAG';
export const FETCH_ONESEARCH_MANIFESTS = 'FETCH_ONESEARCH_MANIFESTS';
export const FETCH_ONESEARCH_PROGRAMS = 'FETCH_ONESEARCH_PROGRAMS';
export const FETCH_ONESEARCH_CLASS_PROGRAMS_BY_DISCIPLINE =
  'FETCH_ONESEARCH_CLASS_PROGRAMS_BY_DISCIPLINE';
export const FETCH_OAC_MANIFESTS = 'FETCH_OAC_MANIFESTS';
export const FETCH_STUDENTS = 'FETCH_STUDENTS';
export const FETCH_TEACHER_CLASSES = 'FETCH_TEACHER_CLASSES';
export const FETCH_TEACHERS = 'FETCH_TEACHERS';
export const UPDATE_ADMINISTRATOR = 'UPDATE_ADMINISTRATOR';
export const UPDATE_PROGRAM_SETTINGS = 'UPDATE_PROGRAM_SETTINGS';
export const UPDATE_ROSTERS = 'UPDATE_ROSTERS';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_TEACHER = 'UPDATE_TEACHER';
export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_FILTERS_FOR_RESOURCES = 'GET_FILTERS_FOR_RESOURCES';
export const GET_RESOURCES_FILTERED = 'GET_RESOURCES_FILTERED';
export const GET_FILTERS_FOR_RESOURCES_FILTERED =
  'GET_FILTERS_FOR_RESOURCES_FILTERED';
export const GET_RESOURCES_BY_KEYWORD = 'GET_RESOURCES_BY_KEYWORD';
export const GET_FILTERS_FOR_RESOURCES_BY_KEYWORD =
  'GET_FILTERS_FOR_RESOURCES_BY_KEYWORD';
export const GET_RESOURCES_BY_KEYWORD_FILTERED =
  'GET_RESOURCES_BY_KEYWORD_FILTERED';
export const GET_FILTERS_FOR_RESOURCES_BY_KEYWORD_FILTERED =
  'GET_FILTERS_FOR_RESOURCES_BY_KEYWORD_FILTERED';
export const GET_RESOURCES_BY_STANDARD_ID = 'GET_RESOURCES_BY_STANDARD_ID';
export const GET_FILTERS_FOR_RESOURCES_BY_STANDARD_ID =
  'GET_FILTERS_FOR_RESOURCES_BY_STANDARD_ID';
export const GET_RESOURCES_BY_STANDARD_ID_FILTERED =
  'GET_RESOURCES_BY_STANDARD_ID_FILTERED';
export const GET_FILTERS_FOR_RESOURCES_BY_STANDARD_ID_FILTERED =
  'GET_FILTERS_FOR_RESOURCES_BY_STANDARD_ID_FILTERED';
export const GET_RESOURCES_BY_STANDARD_SET_ID =
  'GET_RESOURCES_BY_STANDARD_SET_ID';
export const GET_FILTERS_FOR_RESOURCES_BY_STANDARD_SET_ID =
  'GET_FILTERS_FOR_RESOURCES_BY_STANDARD_SET_ID';
export const GET_RESOURCES_BY_STANDARD_SET_ID_FILTERED =
  'GET_RESOURCES_BY_STANDARD_SET_ID_FILTERED';
export const GET_FILTERS_FOR_RESOURCES_BY_STANDARD_SET_ID_FILTERED =
  'GET_FILTERS_FOR_RESOURCES_BY_STANDARD_SET_ID_FILTERED';
export const GET_RESOURCES_BY_PRODUCT_CATEGORY =
  'GET_RESOURCES_BY_PRODUCT_CATEGORY';
export const GET_FILTERS_FOR_RESOURCES_BY_PRODUCT_CATEGORY =
  'GET_FILTERS_FOR_RESOURCES_BY_PRODUCT_CATEGORY';
export const GET_RESOURCES_BY_PRODUCT_CATEGORY_FILTERED =
  'GET_RESOURCES_BY_PRODUCT_CATEGORY_FILTERED';
export const GET_FILTERS_FOR_RESOURCES_BY_PRODUCT_CATEGORY_FILTERED =
  'GET_FILTERS_FOR_RESOURCES_BY_PRODUCT_CATEGORY_FILTERED';
export const GET_ALL_STANDARDS_FOR_PROGRAM = 'GET_ALL_STANDARDS_FOR_PROGRAM';
export const GET_MANIFEST_FOR_STANDARD_SET = 'GET_MANIFEST_FOR_STANDARD_SET';
export const GET_STANDARD_SETS_FOR_PROGRAM = 'GET_STANDARD_SETS_FOR_PROGRAM';
export const GET_STANDARD_SETS_FOR_LESSON = 'GET_STANDARD_SETS_FOR_LESSON';
export const GET_STANDARD_SETS_FOR_ORGANISATION =
  'GET_STANDARD_SETS_FOR_ORGANISATION';
export const GET_ABGUID_RESOURCES = 'GET_ABGUID_RESOURCES';
export const GET_ABGUID_FILTERS_FOR_RESOURCES =
  'GET_ABGUID_FILTERS_FOR_RESOURCES';
export const GET_ABGUID_RESOURCES_FILTERED = 'GET_ABGUID_RESOURCES_FILTERED';
export const GET_ABGUID_FILTERS_FOR_RESOURCES_FILTERED =
  'GET_ABGUID_FILTERS_FOR_RESOURCES_FILTERED';
export const HCP_SUPPORT_TOOL = 'HCP_SUPPORT_TOOL';
