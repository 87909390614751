import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'CountryInput' })(() => ({
  countryContainer: {
    paddingBottom: 'var(--ebl-s3)',
  },
  input: {
    background: 'initial',
    backgroundColor: 'white',
  },
}));
