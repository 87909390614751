const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const devCertSupportToolConfig = {
  app: 'HCP Support App',
  name: 'devCert',
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/signed-in/`,
  edsLogoutUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: `https://local.hmhone.hmhco.com:${currentPort}/`,
  edsLoginClientId: '991FD7EC-C2ED-4FE7-B19D-F249EC88FC63',
  edsLoginUrl:
    'https://app01.cert.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: 'hmh-cert-f19b6fd0-1372-416b-974a-55302f8b0665',
  edsServiceUrl: 'https://app01.cert.tsg.hmhco.com/edcore/eds-core-app',
  appEndPointUrl: 'https://app01.cert.tsg.hmhco.com/hcp-support-tool/',
  emailTokenURL:
    'https://hmh-identity-provider.dev.br.hmheng.io/user/signupToken',
  commonIdsUrl: 'https://api.cert.br.internal/ids-grid-api/ids/',
  edsGraphUrl:
    'https://app01.cert.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  idmUserApiUrl: 'https://api.cert.br.internal/idm-user-api/',
  idmConfigUrl: 'https://config-idm.cert.br.hmheng.io',
  hmhIdentityProvider: 'https://hmh-identity-provider.int.br.hmheng.io',
  edcoreGatewayUrl:
    'https://hapi.cert.hmhco.com/edcore/hmheng-eds-api-gateway/',
};

module.exports = devCertSupportToolConfig;
