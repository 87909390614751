import React from 'react';
import PropTypes from 'prop-types';

const attachSvg = (element, svg, useShadowDom) => {
  if (useShadowDom && element.attachShadow) {
    const shadow = element.attachShadow({ mode: 'open' });
    shadow.innerHTML = svg;
  } else {
    element.insertAdjacentHTML('afterbegin', svg);
  }
};

const Svg = ({
  useShadowDom,
  className,
  svg,
  Component,
  ariaHidden,
  ariaLabel,
}) => {
  return (
    <Component
      ref={element => {
        if (
          element &&
          !element.innerHTML && // when mocked there won't be children but a text
          element.children.length === 0 &&
          !element.shadowRoot
        ) {
          attachSvg(element, svg, useShadowDom);
        }
      }}
      className={className}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
    />
  );
};

Svg.defaultProps = {
  className: '',
  useShadowDom: false,
  Component: 'div',
  ariaHidden: false,
  ariaLabel: '',
};
Svg.propTypes = {
  // Import the svg you want to use from @ed/baseline and use this prop
  svg: PropTypes.string.isRequired,
  useShadowDom: PropTypes.bool,
  className: PropTypes.string,
  Component: PropTypes.node,
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default Svg;
