import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'LoginAppFormLayout' })(theme => ({
  form: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'var(--ebl-s2)',
    maxWidth: '447px', // Temporary for now.
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'var(--ebl-spacing-vert-small)',
      paddingRight: 'var(--ebl-spacing-vert-small)',
    },
  },
}));
