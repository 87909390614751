import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setConnectionPid } from '../storageHelpers/localStorageAccessors';
import {
  setResourceUrl,
  setStateParam,
} from '../storageHelpers/sessionStorageAccessors';

export function getResourceUrlFromUrl(search) {
  return search.get('resource_url');
}

export function getStateParamFromUrl(search) {
  return search.get('state');
}

export function getConnectionParamFromUrl(search) {
  return search.get('connection');
}

function useSetUrlParamsInStorage() {
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const resourceUrl = getResourceUrlFromUrl(search);
    if (resourceUrl) {
      setResourceUrl(resourceUrl);
    }

    const stateParam = getStateParamFromUrl(search);
    if (stateParam) {
      setStateParam(stateParam);
    }

    const connectionParam = getConnectionParamFromUrl(search);
    if (connectionParam) {
      setConnectionPid(connectionParam);
    }
  }, [location.search]);
}

export default useSetUrlParamsInStorage;
