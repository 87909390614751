/*
 *
 * This Config is for developing with feature flags
 * Add your district to the value array for use
 *
 * Permanent config goes on s3
 * Please don't commit this file in your PR's
 *
 */

const localCohorts = [
  {
    key: 'new-licensing',
    flag: 'licensingApp',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'devCertTestKey',
    flag: 'devCertTestFlag',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: { devCert: true },
    },
  },
  {
    key: 'new-licensing',
    flag: 'licensingApp',
    filter: {
      type: 'cohort',
      target: 'selectDistrict',
      value: { local: ['92033418'], int: ['92033418'] },
    },
  },
  {
    key: 'demo-entitlements-by-district',
    flag: 'demoEntitlements',
    filter: {
      type: 'cohort',
      target: 'selectDistrict',
      value: { local: ['91003620', '92004741'] },
    },
  },
  {
    key: 'demo-entitlements-by-usertype',
    flag: 'demoFlag',
    filter: {
      type: 'cohort',
      target: 'selectUserType',
      value: { local: ['Learner'] },
    },
  },
  {
    key: 'reporting-suite-new-vision',
    flag: 'reportingSuiteNewVision',
    filter: {
      type: 'cohort',
      target: 'selectDistrict',
      value: {
        local: [
          '92028086',
          '92027663',
          '92027931',
          '92030621',
          '92028295',
          '92028251',
          '92030621',
          '92028110',
          '92028109',
          '92089200',
          '92083700',
          '92092340',
          '92083718',
          '92082961',
          '92082960',
          '92103100',
          '92025560',
          '92024130',
          '92025806',
          '92023966',
          '00039178',
        ],
      },
    },
  },
  {
    key: 'par-report',
    flag: 'parReport',
    filter: {
      type: 'cohort',
      target: 'selectDistrict',
      value: { local: ['92031910', '92103100', '92092340', '92028100'] },
    },
  },
  {
    key: 'capabilities-manager',
    flag: 'capabilities-manager',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
      },
    },
  },
];

export default localCohorts;
