/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

const useButtonStyles = makeStyles({ name: 'LoadingButton' })({
  root: {},
  disabled: {
    color: 'var(--ebl-text-black) !important',
  },
});

const useProgressStyles = makeStyles({ name: 'LoadingButtonCircularProgress' })(
  {
    root: {
      marginRight: 'var(--ebl-button-icon-text-padding)',
    },
  },
);

const LoadingButton = React.forwardRef((props, ref) => {
  const { loading, loadingText, buttonProps, disabled } = props;
  const { classes: buttonClasses } = useButtonStyles(buttonProps, {
    props: buttonProps,
  });
  const { classes: progressClasses } = useProgressStyles();

  return (
    <Button
      ref={ref}
      {...buttonProps}
      disabled={loading || disabled}
      classes={buttonClasses}
    >
      {loading ? (
        <>
          <CircularProgress
            size={20}
            classes={progressClasses}
            role="none presentation"
          />
          {loadingText}
        </>
      ) : (
        buttonProps.children
      )}
    </Button>
  );
});

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.node.isRequired,
  buttonProps: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default LoadingButton;
