import React from 'react';
import { createRoot } from 'react-dom/client';
import initRUM from '@hmhco/client-monitoring-init/src/datadog/initRum';
import initBrowserLogs from '@hmhco/client-monitoring-init/src/datadog/initBrowserLogs';
import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import tabHandler from '@hmhco/tab-handler/src/tabHandler';
import initGoogleTagManager from '@hmhco/google-tag-manager/src/initGoogleTagManagerNonAuth';
import parseLanguageFromUrl from './lang/parseLanguageFromUrl';
import App from './App';

initGoogleTagManager();

const loginService = 'amp-login-form';
const browserLogsToken = 'pub6215bb5978f75631c332ae0ca6639655';

initBrowserLogs(loginService, browserLogsToken);

const env = getEnvironment();

const sessionSampleRate = env === 'cert' ? 100 : 1;
const sessionReplaySampleRate = env === 'cert' ? 100 : 10;

const edUIApplicationId = 'f8fa8137-0752-4416-b7d1-d693b654ac6c';
const rumToken = 'pub180dbd0eb7c9190919db56946a7227c5';
const allowedTracingUrls = [
  { match: 'https://api.cert.hmhco.com', propagatorTypes: ['datadog'] },
  { match: 'https://cert.hmhco.com', propagatorTypes: ['datadog'] },
  { match: 'https://papi.hmhco.com', propagatorTypes: ['datadog'] },
  { match: 'https://www.hmhco.com', propagatorTypes: ['datadog'] },
];

initRUM(
  edUIApplicationId,
  loginService,
  rumToken,
  allowedTracingUrls,
  sessionSampleRate,
  sessionReplaySampleRate,
);

tabHandler();

const props = {
  languageKey: parseLanguageFromUrl(),
};

if (env === 'local' || env === 'int') {
  console.log('the Console.log is enabled in local and INT environments');
} else {
  console.log('Console.log is disabled');
  window.console.log = function() {
    return '';
  };
}

const root = createRoot(document.getElementById('log-in-form-app'));
root.render(<App {...props} />);
