import TagManager from 'react-gtm-module';
import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import {
  GA_CONTAINER_ID,
  localArgs,
  intArgs,
  certArgs,
  devCertArgs,
  prodArgs,
} from './constants';

const initGoogleTagManagerNonAuth = async () => {
  const env = getEnvironment();
  const tagManagerProfile = {
    gtmId: GA_CONTAINER_ID,
    dataLayer: {},
  };

  const tagManagerArgs = {
    local: {
      ...tagManagerProfile,
      ...localArgs,
    },
    int: {
      ...tagManagerProfile,
      ...intArgs,
    },
    cert: {
      ...tagManagerProfile,
      ...certArgs,
    },
    devCert: {
      ...tagManagerProfile,
      ...devCertArgs,
    },
    prod: {
      ...tagManagerProfile,
      ...prodArgs,
    },
  };

  TagManager.initialize(tagManagerArgs[env]);
};

export default initGoogleTagManagerNonAuth;
