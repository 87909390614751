const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const localSupportToolConfig = {
  app: 'HCP Support App',
  name: 'local',
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/landing-page`,
  edsLogoutUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: `https://local.hmhone.hmhco.com:${currentPort}/`,
  edsLoginClientId: '4A611590-2CA8-4F30-AEC1-28CC043FFBFE',
  edsLoginUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: ' https://app01.dev.tsg.hmhco.com/hcp-support-login/',
  edsServiceUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-core-app',

  appEndPointUrl: 'https://app01.dev.tsg.hmhco.com/hcp-support-tool/',
  emailTokenURL:
    'https://hmh-identity-provider.dev.br.hmheng.io/user/signupToken',
  commonIdsUrl: 'https://api.dev.br.internal/ids-grid-api/ids/',
  edsGraphUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  hmhIdentityProvider: 'https://hmh-identity-provider.int.br.hmheng.io',
  edcoreGatewayUrl:
    'https://hapi.local.hmhco.com/edcore/hmheng-eds-api-gateway/',
};

module.exports = localSupportToolConfig;
