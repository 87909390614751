import React from 'react';
import Icon from '@hmhco/icon';
import { FormattedMessage, useIntl } from 'react-intl';
import infoSvgSm from '@ed/baseline/icons/cts/info-sm.svg';
import addTitleToSvg from '@hmhco/svg/src/utils/svgModifier';
import { SimpleNotification } from '@hmhco/simple-notification/src/SimpleNotification';
import AddTranslations from '@hmhco/i18n-react/src/AddTranslations/AddTranslations';
import { makeStyles } from 'tss-react/mui';
import { getLocaleFile } from './lang';

const useStyles = makeStyles({ name: 'InfoRequiredFields' })(theme => ({
  container: {
    marginBottom: 'var(--ebl-s3)',
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-start',
        paddingTop: '6px',
      },
    },
  },
}));

/* Instance of SimpleNotification adding text about required fields and bottom margin.
 * Margin can be overridden using `classes` prop. */
const InfoRequiredFields = props => {
  const { classes } = useStyles(props, {
    props,
  });
  const { formatMessage } = useIntl();
  const svgInfo = addTitleToSvg(
    infoSvgSm,
    formatMessage({ id: 'form.svg.information' }),
  );

  return (
    <SimpleNotification
      justifyContent="flex-start"
      icon={<Icon svg={svgInfo} size="16" colour="var(--ebl-signal-info)" />}
      classes={{ root: classes.container }}
    >
      <FormattedMessage id="form.info.requiredFields" />
    </SimpleNotification>
  );
};

export default props => (
  <AddTranslations getLocaleFile={getLocaleFile}>
    <InfoRequiredFields {...props} />
  </AddTranslations>
);
