import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Marketing' })({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: 'var(--ebl-s4)',
  },
  heinemann: {
    backgroundColor: '#EAF4FF',
    position: 'relative',
    overflow: 'hidden',
  },
  hmh: {
    backgroundColor: 'var(--ebl-pagelayout-login-brand-color)',
    height: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& svg': {
      maxHeight: 'calc(100vh - 3rem)',
    },
  },
  heinemannImage: {
    width: 'calc(100% - 3rem)',
    translate: '0 4rem',
    scale: '1.2',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center',
  },
  hmhImage: {},
});
