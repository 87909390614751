import { createAndDisplayOktaWidget } from './createAndDisplayOktaWidget';
import { createObserversToUpdateWidget } from './createObserversToUpdateWidget';
import { isOktaAvailable } from './utils';

export const initializeOktaForm = (
  isVisible,
  oktaInfo,
  widgetRef,
  formatMessage,
) => {
  if (!isOktaAvailable(isVisible, oktaInfo, widgetRef)) {
    return () => {};
  }
  const { location } = window;
  const search = new URLSearchParams(location.search);
  const interactionCode = search.get('interaction_code');
  const state = search.get('state');

  if (interactionCode || state) {
    return () => {};
  }

  const oktaForm = {
    oktaWidget: null,
    observers: null,
  };

  (async () => {
    oktaForm.oktaWidget = await createAndDisplayOktaWidget(oktaInfo, widgetRef);

    if (!oktaForm.oktaWidget) {
      return;
    }

    oktaForm.observers = createObserversToUpdateWidget(
      widgetRef,
      formatMessage,
    );
  })();

  return () => {
    if (oktaForm.oktaWidget) {
      oktaForm.oktaWidget.remove();
    }
    if (oktaForm.observers) {
      oktaForm.observers.containerObserver?.disconnect();
      oktaForm.observers.fieldObservers.forEach(fieldObserver =>
        fieldObserver.disconnect(),
      );
      oktaForm.observers.alertObservers.forEach(alertObserver =>
        alertObserver.disconnect(),
      );
    }
  };
};

export default initializeOktaForm;
