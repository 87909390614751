import React from 'react';
import MarketingImage from '@ed/baseline/VAL/hmh/login-sm.svg';
import PropTypes from 'prop-types';
import { Svg } from '@hmhco/svg';
import HeinemannImage from './integrationImages/hnm-background.png';
import useStyles from './MarketingSection.styles';

const MarketingSection = ({ currentTheme }) => {
  const { classes } = useStyles();
  return currentTheme === 'HNM' ? (
    <div
      data-testid="marketing-image"
      role="presentation"
      className={classes.heinemann}
      style={{
        backgroundImage: `url(${HeinemannImage})`,
      }}
    />
  ) : (
    <div
      data-testid="marketing-image"
      role="presentation"
      className={classes.ed}
    >
      <Svg svg={MarketingImage} />
    </div>
  );
};

MarketingSection.propTypes = {
  currentTheme: PropTypes.string,
};

export default MarketingSection;
