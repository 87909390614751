import React from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

/**
 * Add translations to the existing react-intl context
 * This is for child Apps or components that expect an already existing 'react-intl' context
 * (which mean there is an IntlProvider in the upper level).
 *
 * This helper will merge the provided translations with the one available in the current Intl context.
 *
 * @param children node the child receiving the extended intl context
 * @param getLocaleFile function receiving the current language key and returning the translation messages
 */
const AddTranslations = ({ children, getLocaleFile }) => {
  const { locale, messages } = useIntl();
  const appMessages = getLocaleFile(locale) || {};

  // only remerge those if the locale change
  const mergedTranslations = React.useMemo(
    () => ({ ...messages, ...appMessages }),
    [locale],
  );

  return (
    <IntlProvider
      locale={locale}
      defaultlocale="en-US"
      messages={mergedTranslations}
    >
      {children}
    </IntlProvider>
  );
};

AddTranslations.propTypes = {
  children: PropTypes.node,
  getLocaleFile: PropTypes.func,
};

export default AddTranslations;
