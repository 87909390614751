import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { ONE_SECOND_SESSION } from '@hmhco/cache-expiration/src/cacheExpirationValues';

export const SYSTEM_ALERT_URL = '/content/ui/messages/login_message.json';

export const systemAlert = (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });

  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    pactConfig,
  });

  return {
    fetchSystemAlert: () =>
      request.get(SYSTEM_ALERT_URL, { maxAge: ONE_SECOND_SESSION }),
    cancel,
  };
};
