import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import useStyles from './ReadOnlyField.styles';

const ReadOnlyField = props => {
  const { label, value, ...contentProps } = props;
  const { classes: styleClasses } = useStyles(props, { props });

  return (
    <>
      <FormLabel component="p" className={styleClasses.label}>
        {label}
      </FormLabel>
      <Typography
        className={styleClasses.value}
        variant="body2"
        {...contentProps}
      >
        {value}
      </Typography>
    </>
  );
};

ReadOnlyField.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default ReadOnlyField;
