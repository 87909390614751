import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from './ForgotPasswordInformationHeader.styles';
import { getIsForgotPasswordDisabled } from '../../store/selectors/ForgotPasswordOrg';

const ForgotPasswordInformationHeader = () => {
  const { classes } = useStyles();
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  return (
    <>
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.forgotPassword.pageTitle" />
      </Typography>
      {!forgotPasswordDisabled && (
        <Typography
          component="p"
          variant="body2"
          className={classes.informationPanel}
        >
          <FormattedMessage id="login.forgotPassword.helpText" />
        </Typography>
      )}
    </>
  );
};

export default ForgotPasswordInformationHeader;
