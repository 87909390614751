import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Svg from '@hmhco/svg/src/Svg';
import addTitleToSvg from '@hmhco/svg/src/utils/svgModifier';
import hmhLogo from '@ed/baseline/images/rebrand/HMHEd_c_158x32.svg';
import { usePlatform, PLATFORMS } from '@hmhco/platform-helper';
import heinemennLogo from '../../Layout/integrationImages/hnm-logo.svg';
import useStyles from './Logo.styles';

const Logo = () => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();
  const { formatMessage } = useIntl();

  const svg = useMemo(() => {
    switch (platform) {
      case PLATFORMS.FLIGHT:
        return addTitleToSvg(
          heinemennLogo,
          formatMessage({ id: 'login.form.svg.heinemannFlight.logo' }),
        );
      case PLATFORMS.ED:
      default:
        return addTitleToSvg(
          hmhLogo,
          formatMessage({ id: 'login.form.svg.hmhEd.logo' }),
        );
    }
  }, [platform, formatMessage]);

  return (
    <div className={classes.root}>
      <Svg
        className={cx(classes.logo, {
          [classes.heinemennLogo]: platform === PLATFORMS.FLIGHT,
        })}
        svg={svg}
      />
    </div>
  );
};

export default Logo;
