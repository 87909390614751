import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';

function RenderSanitizedHtml({ children }) {
  const [sanitizedChildren, setSanitizedChildren] = useState(null);
  useEffect(() => {
    const sanitized = DOMPurify.sanitize(children);
    setSanitizedChildren(sanitized);
  }, [children]);
  return ReactHtmlParser(sanitizedChildren);
}
export default RenderSanitizedHtml;
