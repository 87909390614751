import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './RequestSignInDetailsEmail.styles';
import {
  setRequestSigninEmail,
  setRequestSigninIsValid,
} from '../../store/actions/RequestSigninActions';
import {
  getIsRequestSigninValid,
  getRequestSigninEmail,
} from '../../store/selectors/RequestSigninPageState';
import { AUTO_COMPLETE } from '../../constants/constants';

export const VALID_EMAIL_PATTERN = [/^[^@]+@[^@]+\.[^@]+$/];

const RequestSignInDetailsEmail = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const emailValid = useSelector(getIsRequestSigninValid);
  const currentEmail = useSelector(getRequestSigninEmail);
  const showError = currentEmail === null > 0 && !emailValid;
  const onEmailChange = useCallback(event => {
    const newEmail = event?.target?.value;
    const validPattern = VALID_EMAIL_PATTERN.find(pattern =>
      newEmail.match(pattern),
    );
    dispatch(setRequestSigninEmail(newEmail));
    dispatch(setRequestSigninIsValid(Boolean(validPattern)));
  }, []);

  return (
    <FormattedMessage id="login.requestSiginDetails.emailLabel">
      {emailLabel => (
        <TextField
          id="email"
          name="email"
          onChange={onEmailChange}
          label={emailLabel}
          inputProps={{
            'data-testid': 'email',
            className: classes.input,
          }}
          error={showError}
          required
          autoComplete={AUTO_COMPLETE.EMAIL}
          helperText={
            <FormattedMessage id="login.requestSiginDetails.emailHelpText" />
          }
        />
      )}
    </FormattedMessage>
  );
};

export default RequestSignInDetailsEmail;
