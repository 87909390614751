const localFlightCohorts = [
  {
    key: 'user-management.delete-user',
    flag: 'user-management.delete-user',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    /* 
      Dev, Int, Cert, and Prod should never be true for institutional login.
      Set "local" to true ONLY when testing rostered login for flight locally.
      See packages/ext/hnm/apps/local-rostered-login/README.md for more information on why this is.
    */
    key: 'flight-institutional-login',
    flag: 'flight-institutional-login',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'user-management.add-admin',
    flag: 'user-management.add-admin',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'user-management.edit-user',
    flag: 'user-management.edit-user',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-main-nav-app',
    flag: 'flight-main-nav-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-dashboard-app',
    flag: 'flight-dashboard-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-login-app',
    flag: 'flight-login-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-logout-app',
    flag: 'flight-logout-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-footer-app',
    flag: 'flight-footer-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-license-management-app',
    flag: 'flight-license-management-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-hcp-licensing',
    flag: 'flight-hcp-licensing',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-user-management-app',
    flag: 'flight-user-management-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-my-schools-app',
    flag: 'flight-my-schools-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-admin-roster-app',
    flag: 'flight-admin-roster-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-admin-preferences-app',
    flag: 'flight-admin-preferences-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'lucy-calkins-app',
    flag: 'lucy-calkins-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'marilyn-burns-app',
    flag: 'marilyn-burns-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-product-app',
    flag: 'flight-product-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'fountas-pinell-lattice-navigation-app',
    flag: 'fountas-pinell-lattice-navigation-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'fountas-pinell-app',
    flag: 'fountas-pinell-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-enable-container-v2',
    flag: 'flight-enable-container-v2',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-capabilities',
    flag: 'flight-capabilities',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: true,
        int: true,
        cert: true,
        prod: true,
      },
    },
  },
  {
    key: 'flight-teacher-display-tools-app',
    flag: 'flight-teacher-display-tools-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-assignments-app',
    flag: 'flight-assignments-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-my-classes-app',
    flag: 'flight-my-classes-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-reporting-app',
    flag: 'flight-reporting-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-scores-app',
    flag: 'flight-scores-app',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: true,
        devCert: true,
        dev: true,
        int: true,
        cert: true,
        prod: false,
      },
    },
  },
  {
    key: 'flight-isExpirationEnabled',
    flag: 'flight-isExpirationEnabled',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        devCert: false,
        dev: true,
        int: true,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-product-selector',
    flag: 'flight-product-selector',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-hcp-teacher-dashboard',
    flag: 'flight-hcp-teacher-dashboard',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-student-feature',
    flag: 'flight-student-feature',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-my-school-redirect-feature',
    flag: 'flight-my-school-redirect-feature',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
  {
    key: 'flight-student-linkTree-writingLessons',
    flag: 'flight-student-linkTree-writingLessons',
    filter: {
      type: 'cohort',
      target: 'selectUserId',
      value: {
        local: ['22524225'],
        dev: [],
        int: ['22524225'],
        cert: ['86855722'],
        prod: [],
      },
    },
  },
  {
    key: 'flight-expiration-warning-for-products',
    flag: 'flight-expiration-warning-for-products',
    filter: {
      type: 'cohort',
      target: 'toggleApp',
      value: {
        local: false,
        dev: false,
        int: false,
        cert: false,
        prod: false,
      },
    },
  },
];

export default localFlightCohorts;
