const formDataValidator = (
  newPassword,
  verifyPassword,
  userName,
  userNameIsValid,
  isPasswordValid,
) => {
  const passwordsMatch = newPassword === verifyPassword;
  const usernameDoesNotMatchPassword = userName !== newPassword;
  const passwordMatchesUsernameErrorMessageId =
    usernameDoesNotMatchPassword || newPassword === undefined
      ? undefined
      : 'login.newPassword.errorMessage.passwordMatchesUsername';
  const passwordsDoNotMatchErrorMessageId =
    passwordsMatch || verifyPassword === undefined
      ? undefined
      : 'login.newPassword.errorMessage.passwordsDoNotMatch';
  const isFormValid =
    userNameIsValid &&
    isPasswordValid &&
    passwordsMatch &&
    usernameDoesNotMatchPassword;

  return {
    isFormValid,
    passwordMatchesUsernameErrorMessageId,
    passwordsDoNotMatchErrorMessageId,
  };
};

export default formDataValidator;
