import baseTheme from './baseTheme';
import createBaseThemeSettings from './createBaseThemeSettings';
import getHexFromVariable from './getHexFromVariable';
import createPalette from './createPalette';
import defaultTypography, {
  TYPOGRAPHY_VARIANTS,
  TYPOGRAPHY_COMPONENTS,
} from './styles/defaultTypography';

export {
  baseTheme,
  createBaseThemeSettings,
  getHexFromVariable,
  createPalette,
  defaultTypography,
  TYPOGRAPHY_VARIANTS,
  TYPOGRAPHY_COMPONENTS,
};
export default baseTheme;
