import React from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './RequestSignInDetailsButtons.styles';
import requestSignInDetails from '../../../../api/requestSiginDetails';
import useGetConnectionParam from '../../../../hooks/getConnectionParam.hook';
import {
  setRequestSigninDetailsError,
  setRequestSigninDetailsLoading,
  setRequestSigninDetailsSuccess,
} from '../../../../store/actions/RequestSigninActions';
import {
  getRequestSigninEmail,
  getIsRequestSigninValid,
} from '../../../../store/selectors/RequestSigninPageState';

const RequestSignInDetailsButtons = ({ currentTheme, canSubmit }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentEmail = useSelector(getRequestSigninEmail);
  const connection = useGetConnectionParam();
  const requestSigninEmailIsValid = useSelector(getIsRequestSigninValid);

  const onSubmit = async event => {
    event.preventDefault();
    const {
      requestSignInDetailsEd,
      requestSignInDetailsHnm,
    } = requestSignInDetails();
    try {
      dispatch(setRequestSigninDetailsLoading());
      if (currentTheme === 'HNM') {
        await requestSignInDetailsHnm({
          email: currentEmail,
          districtId: connection,
        });
      } else {
        await requestSignInDetailsEd({
          email: currentEmail,
          districtId: connection,
        });
      }
      dispatch(setRequestSigninDetailsSuccess());
    } catch (error) {
      dispatch(setRequestSigninDetailsError());
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.buttons}>
      <Button
        variant="outlined"
        size="small"
        onClick={goBack}
        role="link"
        className={classes.button}
      >
        <FormattedMessage id="login.forgotPassword.backButton" />
      </Button>
      {canSubmit && (
        <Button
          disabled={!requestSigninEmailIsValid}
          data-testid="reqSiginSubmitButton"
          variant="contained"
          color="primary"
          size="small"
          type="submit"
          className={classes.button}
          onClick={onSubmit}
        >
          <FormattedMessage id="login.forgotPassword.submitButton" />
        </Button>
      )}
    </div>
  );
};

RequestSignInDetailsButtons.propTypes = {
  currentTheme: PropTypes.string,
  canSubmit: PropTypes.bool,
};
RequestSignInDetailsButtons.defaultProps = {
  currentTheme: null,
  canSubmit: true,
};

export default RequestSignInDetailsButtons;
