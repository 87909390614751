import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';
import CssBaseline from '@mui/material/CssBaseline';

// forcing where emotion injects leads to a more predictable behaviour
const documentHead = document.querySelector('head');
const insertionPoint = document.createElement('meta');
insertionPoint.setAttribute('name', 'inject-style');
documentHead.appendChild(insertionPoint);

const muiCache = createCache({
  key: 'mui',
  insertionPoint,
});

const tssCache = createCache({
  key: 'tss',
});

const TopLevelThemeProvider = ({ theme, children }) => (
  <CacheProvider value={muiCache}>
    <TssCacheProvider value={tssCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </TssCacheProvider>
  </CacheProvider>
);

TopLevelThemeProvider.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default TopLevelThemeProvider;
