import React from 'react';
import { useSelector } from 'react-redux';
import Username from './Username';
import Password from './Password';
import { getRenderUsernameAndPassword } from '../../store/selectors/CountryStateOrg';
import useGetUserHasNavigatedFromDistrictSelect from '../../hooks/getUserHasNavigatedFromDistrictSelect.hook';

const UsernameAndPasswordForm = () => {
  const shouldUsernameFocus = useGetUserHasNavigatedFromDistrictSelect();
  const renderUsernameAndPassword = useSelector(getRenderUsernameAndPassword);
  if (!renderUsernameAndPassword) {
    return null;
  }
  return (
    <>
      <Username shouldUsernameFocus={shouldUsernameFocus} />
      <Password />
    </>
  );
};
export default UsernameAndPasswordForm;
