import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Alert, AlertTitle, Typography } from '@mui/material';
import AddTranslations from '@hmhco/i18n-react/src/AddTranslations/AddTranslations';
import { SEVERITY, ALIGNMENT, ALERT_SIZE, ALERT_TEST_ID } from './constants';
import { getIconMappingForAlert } from './iconMapping';
import DefaultAction from './DefaultAction';
import { getLocaleFile } from './lang';

/**
 * Represents an HcpAlert component.
 * Also to make sure Alert component works properly, make sure that default Palette will be over-riden
 * neutral color has beed added into palette for example
 * const flightPaletteOverrides = {
 * this extra color needed for Alert component neutal variant
 * neutral: {
 *  main: '#ffffff',
 *  light: '#ffffff',
 *  },
 * };
 * export default createTheme(
 * createBaseThemeSettings({
 *  palette: merge(createPalette(colours), flightPaletteOverrides),
 *  }),
 * );
 * @param {Object} props - Component props.
 *
 * @param {string} props.severity - The severity of the alert ('success', 'error', 'info', 'warning', or 'neutral').
 * @param {string} props.alignment - The alignment of title and subtitle/description in the alert ('stacked' or 'inline').
 * @param {string|ReactNode} props.title - The title of the alert.
 * @param {string|ReactNode} props.subtitle - The subtitle or description of the alert.
 * @param {string} props.size - The size of the alert ('sm' or 'lg').
 * @param {boolean} props.showIcon - Whether to display the alert icon.
 * @param {function} props.onClose - Callback function to handle close action if.onClose will be ignored if null or custom action component is passed to action prop.
 * @param {null|undefined|ReactNode} props.action - Custom action component.If undefined default close button will be shown and null will hide the action button.
 * @param {ReactNode} props.children - Additional content within the alert.If children passed title and subtitle will be ignored
 * @param {string} props.role - Aria attribute to be provided fror Alert component default will be 'alert'.
 * @returns {JSX.Element} - The HcpAlert component.
 */

function HcpAlert({
  severity = SEVERITY.SUCCESS,
  alignment = ALIGNMENT.STACKED,
  title = null,
  subtitle = null,
  size = ALERT_SIZE.LARGE,
  showIcon = true,
  onClose = null,
  action = undefined,
  children = undefined,
  role = 'alert',
  ...domProps
}) {
  const isInline = () => alignment === ALIGNMENT.INLINE;
  const isStackedOrNone = () => alignment !== ALIGNMENT.INLINE;
  // eslint-disable-next-line prettier/prettier
  const isStackedAndNotNone = () =>
    alignment !== ALIGNMENT.NONE && alignment === ALIGNMENT.STACKED;
  // eslint-disable-next-line prettier/prettier
  const getCustomAction = () =>
    action === null ? (
      undefined
    ) : (
      <DefaultAction action={action} onClose={onClose} />
    );
  // eslint-disable-next-line prettier/prettier
  const getSeverityColor = () =>
    severity === SEVERITY.NEUTRAL ? SEVERITY.INFO : severity;
  const stakDirectionBreaks = {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  };
  const stackGapBreaks = {
    xs: '0rem',
    sm: '0rem',
    md: 'var(--ebl-s2)',
    lg: 'var(--ebl-s2)',
    xl: 'var(--ebl-s2)',
  };

  return (
    <AddTranslations getLocaleFile={getLocaleFile}>
      <Alert
        severity={getSeverityColor()}
        color={severity === SEVERITY.NEUTRAL ? SEVERITY.NEUTRAL : undefined}
        icon={showIcon ? undefined : false}
        action={getCustomAction()}
        size={size}
        iconMapping={getIconMappingForAlert(size === ALERT_SIZE.SMALL)}
        role={role}
        data-testid={ALERT_TEST_ID.ALERT}
        alignment={alignment}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...domProps}
      >
        {isStackedAndNotNone() && !children && (
          <AlertTitle noWrap data-testid={ALERT_TEST_ID.TITLE}>
            <Typography
              variant={size === ALERT_SIZE.SMALL ? 'body2Bold' : 'body1Bold'}
              component="p"
            >
              {title}
            </Typography>
          </AlertTitle>
        )}

        {isInline() && !children && (
          <Stack direction={stakDirectionBreaks} gap={stackGapBreaks}>
            <Box>
              <AlertTitle data-testid={ALERT_TEST_ID.TITLE}>
                <Typography
                  variant={
                    size === ALERT_SIZE.SMALL ? 'body2Bold' : 'body1Bold'
                  }
                  component="p"
                >
                  {title}
                </Typography>
              </AlertTitle>
            </Box>
            <Box>
              <Typography
                data-testid={ALERT_TEST_ID.SUBTITLE}
                variant={size === ALERT_SIZE.SMALL ? 'body2' : 'body1'}
                component="p"
              >
                {subtitle}
              </Typography>
            </Box>
          </Stack>
        )}

        {isStackedOrNone() && !children && (
          <Typography
            data-testid={ALERT_TEST_ID.SUBTITLE}
            variant={size === ALERT_SIZE.SMALL ? 'body2' : 'body1'}
            component="p"
          >
            {subtitle}
          </Typography>
        )}
        {children}
      </Alert>
    </AddTranslations>
  );
}

HcpAlert.propTypes = {
  severity: PropTypes.oneOf(Object.values(SEVERITY)),
  alignment: PropTypes.oneOf(['stacked', 'inline', 'none']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(['sm', 'lg']),
  showIcon: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([null, undefined]),
  ]),
  children: PropTypes.node,
  role: PropTypes.string,
};

HcpAlert.defaultProps = {
  severity: SEVERITY.SUCCESS,
  alignment: ALIGNMENT.STACKED,
  title: null,
  size: ALERT_SIZE.LARGE,
  showIcon: true,
  onClose: null,
  action: undefined,
  children: undefined,
  role: 'alert',
  subtitle: null,
};

export default HcpAlert;
