import enApp from './en-US.json';
import esApp from './es-US.json';

function getLocaleFile(locale) {
  switch (locale) {
    case 'en-US': {
      return enApp;
    }
    case 'es-US': {
      return esApp;
    }
    default: {
      return {};
    }
  }
}
export async function getFileForTranslations() {
  return enApp;
}
export default getLocaleFile;
