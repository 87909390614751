import { merge, camelCase } from 'lodash';
import TEST_PALETTE from './testPalette';

/* Using this CSS variable to find where the colour variable CSS rule is */
const BASE_COLOURS_MARKER = '--white';

const computedStyle = getComputedStyle(document.documentElement);

const getSubstringStartingAfter = (string, startAfter = 'ebl-') => {
  const substringFrom = string.indexOf(startAfter) + startAfter.length;
  return string.substring(substringFrom);
};

const parseKeyName = keyName => {
  return camelCase(getSubstringStartingAfter(keyName));
};

/* this function loops through all the 'styleSheets' present in the 'document',
inside the 'styleSheets' it loops only through 'CSSStyleRule' type 'cssRules'.

Looping through the definitions ('style') of the 'cssRules' of the 'styleSheets' of the 'document',
it returns any definition that contains '--' (is a css variable)
but does not return any definition that is a reference of a css variable 
(such as 'var(--some-var)') 

It also looks through all the definitions trying to find the BASE_COLOURS_MARKER.
When the BASE_COLOURS_MARKER is found, it returns the rule it was found in as 'baseRule' */

const grabAllCssVarsAndBaseRule = () => {
  // just loop through local stylesheets
  const localStyleSheets = Array.from(document.styleSheets).filter(sheet => {
    return !sheet.href || sheet.href.startsWith(window.location.origin);
  });
  return localStyleSheets.reduce(
    (sheetAcc, currSheet) => {
      const rulesReducer = Object.values(currSheet.cssRules).reduce(
        (rulesAcc, currRule) => {
          // only looking for CSSStyleRule types
          if (currRule.constructor.name === 'CSSStyleRule') {
            const definitionValues = Object.values(currRule.style).filter(
              rule => {
                return rule.indexOf('--') > -1 && rule.indexOf('var') === -1;
              },
            );
            /* when the BASE_COLOURS_MARKER is found
            return the base rule as rulesReducer.baseRule 
            otherwise return the value of baseRule in the accumulator */
            const rulesBaseRule =
              definitionValues.indexOf(BASE_COLOURS_MARKER) > -1
                ? currRule
                : rulesAcc.baseRule;

            const declarations = [
              ...rulesAcc.declarations,
              ...definitionValues,
            ];

            return { declarations, baseRule: rulesBaseRule };
          }
          return rulesAcc;
        },
        {
          declarations: [],
          baseRule: {},
        },
      );
      const declarations = [
        ...sheetAcc.declarations,
        ...rulesReducer.declarations,
      ];

      /* if rulesReducer has a 'style' property, it means a 'baseRule' 
      has been returned, otherwise, just return the 'baseRule' 
      of the accumulator (which starts off as an empty object) */

      const sheetBaseRule = rulesReducer.baseRule.style
        ? rulesReducer.baseRule
        : sheetAcc.baseRule;

      return { declarations, baseRule: sheetBaseRule };
    },
    { declarations: [], baseRule: {} },
  );
};

const getAllBaselineColourVars = () => {
  const { declarations, baseRule } = grabAllCssVarsAndBaseRule();

  /* we don't load any css when rendering apps in tests, 
  this makes sure there's a TEST_PALETTE available for createPalette
  this is necessary because createPalette() expects an ebl palette */

  if (!baseRule.style || declarations.length === 0) {
    // only warn in the browser
    if (!navigator.userAgent.includes('jsdom')) {
      console.error(
        'A test colour palette has been loaded due to the lack of ebl css variables in the document. Theming will not work properly!',
      );
    }
    // still return the test palette
    return TEST_PALETTE;
  }

  /* a CSSStyleRule contains much more than the variables as declarations 
  this will get only the variable names, we don't really care about the values */
  const baseVars = Object.values(baseRule.style).filter(value => {
    return value.indexOf('--') > -1;
  });

  const variablesMap = {}; // you can use this to log the variable mapping to the console
  const defaultPalette = {}; // you can use this to generate a new test palette for `testPalette.js`
  const allColourVariables = declarations.reduce((acc, curr) => {
    // if the declaration is not one of the base colours (that's why we only wanted the name(s))
    if (baseVars.indexOf(curr) === -1) {
      // we get it's computed style
      const computedValue = computedStyle.getPropertyValue(curr).trim();
      // to check if it's a HEX colour value, we only really need those
      if (computedValue.charAt(0) === '#' || computedValue === 'transparent') {
        variablesMap[curr] = parseKeyName(curr);
        defaultPalette[parseKeyName(curr)] = computedValue;
        // then we normalize the variable name and append it to the object
        return { ...acc, [parseKeyName(curr)]: computedValue };
      }
      return acc;
    }
    return acc;
  }, {});
  // console.log(JSON.stringify(variablesMap)); // uncomment this to log the variable name mapping
  // console.log(JSON.stringify(defaultPalette)); // uncomment this to post the default palette to console

  return allColourVariables;
};

const createPaletteObject = colours => {
  return Object.keys(colours).reduce((prev, curr) => {
    return {
      ...prev,
      [parseKeyName(curr)]: colours[curr],
    };
  }, {});
};

export default (colours = {}) => {
  // parse the theme specific variables into a palette object shape
  const themeColours = createPaletteObject(colours);

  // parse the baseline colour variables into a palette object shape
  const baseColours = getAllBaselineColourVars();

  /* merge the pallete objects, the order is important 
  as we actually want the theme colours to override the base colours */
  return merge({}, baseColours, themeColours);
};
