import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Footer' })(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  paragraph: {
    padding: '0.125rem var(--ebl-s2)',
  },
  link: {
    whiteSpace: 'nowrap',
    '& span': {
      marginBottom: '0.125rem',
      marginLeft: 6,
    },
    [`&:visited`]: {
      color: 'var(--ebl-link-visited-color) !important',
    },
    [`&:visited > *`]: {
      fill: 'var(--ebl-link-visited-color) !important',
    },
  },
  linkHeinemann: {
    '& svg': {
      fill: 'var(--ebl-dark-action-color)',
    },
  },
}));
