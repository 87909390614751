import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Alerts' })({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--ebl-s2)',
  },
  hasAlert: { paddingBottom: 'var(--ebl-s4)' },
});
