import { useLocation } from 'react-router-dom';
import { getConfig } from '../api/buildAuthenticationUrl';

const useCallbackParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { ampLogin } = getConfig();

  const {
    client_id: clientId,
    client_secret: clientSecret,
    iss: issuer,
    state,
    login_hint: loginHint,
  } = Object.fromEntries(params.entries());

  const allKeysHaveValue =
    clientId !== undefined &&
    clientSecret !== undefined &&
    issuer !== undefined &&
    state !== undefined;

  return {
    allKeysHaveValue,
    params: {
      clientId,
      clientSecret,
      issuer,
      redirectUri: `${ampLogin}tenant-picker`,
      state,
      loginHint,
    },
  };
};

export default useCallbackParams;
