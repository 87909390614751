import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './Password.styles';
import {
  getHasLoginFailed,
  getIsPasswordValid,
} from '../../store/selectors/UsernameAndPassword';
import { setPassword } from '../../store/actions/UsernameAndPasswordActions';
import { AUTO_COMPLETE } from '../../constants/constants';

const Password = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hasLoginFailed = useSelector(getHasLoginFailed);
  const passwordIsValid = useSelector(getIsPasswordValid);
  const changeCallback = useCallback(
    event => {
      const password = event?.target?.value;
      dispatch(setPassword(password));
    },
    [dispatch],
  );

  const error = Boolean(hasLoginFailed || !passwordIsValid);
  let helperText = null;
  if (error) {
    helperText = hasLoginFailed ? null : 'login.form.failed.password.length';
  }
  return (
    <FormattedMessage id="login.form.passwordLabel">
      {passwordLabel => (
        <TextField
          id="password"
          name="password"
          onChange={changeCallback}
          label={passwordLabel}
          inputProps={{
            'data-testid': 'password',
            className: classes.input,
          }}
          FormHelperTextProps={{
            'aria-live': 'polite',
          }}
          required
          type="password"
          helperText={helperText && <FormattedMessage id={helperText} />}
          error={error}
          autoComplete={AUTO_COMPLETE.PASSWORD}
        />
      )}
    </FormattedMessage>
  );
};

export default Password;
