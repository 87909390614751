/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Typography, Grid, Alert } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import error from '@ed/baseline/icons/cts/error-md.svg';
import smallError from '@ed/baseline/icons/cts/error-sm.svg';
import warning from '@ed/baseline/icons/cts/warning-md.svg';
import smallWarning from '@ed/baseline/icons/cts/warning-sm.svg';
import info from '@ed/baseline/icons/cts/info-md.svg';
import smallInfo from '@ed/baseline/icons/cts/info-sm.svg';
import success from '@ed/baseline/icons/cts/success-md.svg';
import smallSuccess from '@ed/baseline/icons/cts/success-sm.svg';
import keyMirror from '@hmhco/react-utils/src/keyMirror';
import AddTranslations from '@hmhco/i18n-react/src/AddTranslations/AddTranslations';
import Icon, { DISPLAY_TYPES } from '@hmhco/icon/src/Icon';
import getLocaleFile from './lang';
import useStyles from './AlertNotifications.styles';
import CloseButton from './CloseButton';

export const ALERT_SEVERITIES = keyMirror({
  error: null,
  info: null,
  success: null,
  warning: null,
});
export const TEXT_MODES = keyMirror({
  inline: null,
  wrap: null,
});

export const getIconMappingForAlert = (
  theme,
  small = false,
  setIconSize = '24',
) => {
  return {
    info: (
      <Icon
        svg={small ? smallInfo : info}
        size={setIconSize}
        colour={theme.palette.ebl.signalInfo}
        display={DISPLAY_TYPES.noDisplayValue}
      />
    ),
    warning: (
      <Icon
        svg={small ? smallWarning : warning}
        size={setIconSize}
        colour={theme.palette.ebl.signalWarning}
        display={DISPLAY_TYPES.noDisplayValue}
      />
    ),
    success: (
      <Icon
        svg={small ? smallSuccess : success}
        size={setIconSize}
        colour={theme.palette.ebl.signalSuccess}
        display={DISPLAY_TYPES.noDisplayValue}
      />
    ),
    error: (
      <Icon
        svg={small ? smallError : error}
        size={setIconSize}
        colour={theme.palette.ebl.signalError}
        display={DISPLAY_TYPES.noDisplayValue}
      />
    ),
  };
};

const getAlertClasses = (small, classes) => ({
  action: classes.action,
  ...(small ? { icon: classes.iconSmall, message: classes.messageSmall } : {}),
});

const AlertNotification = ({
  severity,
  children,
  label,
  small,
  count,
  textMode,
  autoFocus,
  forceFocus,
  onClose,
  ...domProps
}) => {
  const { classes, theme } = useStyles();
  const [setIconSize, setBodySize] = small ? ['16', 'body2'] : ['24', 'body1'];
  const contentRef = React.useRef();

  React.useEffect(() => {
    // focus on message if autoFocus passed and no close button or if force focus is provided
    if (autoFocus && (!onClose || forceFocus) && contentRef.current) {
      contentRef.current.focus();
    }
  }, [autoFocus, forceFocus]);

  return (
    <AddTranslations getLocaleFile={getLocaleFile}>
      <Alert
        classes={getAlertClasses(small, classes)}
        size={small ? 'sm' : 'lg'}
        severity={severity}
        tabIndex={autoFocus && '-1'}
        alignment="inline"
        action={
          onClose && (
            <CloseButton
              data-testid="alertCloseButton"
              autoFocus={autoFocus}
              onClose={onClose}
            />
          )
        }
        {...domProps}
        iconMapping={getIconMappingForAlert(theme, small, setIconSize)}
      >
        <Grid
          container
          tabIndex={forceFocus ? '0' : '-1'}
          ref={contentRef}
          className={classes.alertGrid}
        >
          <Grid item>
            <strong>
              <Typography
                variant={setBodySize}
                data-testid="alertHeader"
                className={classes.alertLabel}
              >
                {label || (
                  <FormattedMessage
                    id={`alertNotification.header.${severity}`}
                  />
                )}
              </Typography>
            </strong>
          </Grid>
          {children && (
            <Grid item>
              <Typography
                variant={setBodySize}
                component="div"
                data-testid="alertMessage"
              >
                {children}
              </Typography>
            </Grid>
          )}
          {count !== undefined && (
            <Grid item className={classes.alertCountContainer}>
              {count && (
                <Typography
                  variant={setBodySize}
                  data-testid="alertHeaderCount"
                  className={classes.alertCount}
                >
                  {count}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Alert>
    </AddTranslations>
  );
};

AlertNotification.defaultProps = {
  label: undefined,
  children: undefined,
  small: false,
  count: undefined,
  textMode: TEXT_MODES.wrap,
  autoFocus: undefined,
  forceFocus: false,
};

AlertNotification.propTypes = {
  severity: PropTypes.oneOf(Object.keys(ALERT_SEVERITIES)).isRequired,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  small: PropTypes.bool,
  count: PropTypes.number,
  action: PropTypes.node, // original MUI Alert prop to define a custom button
  textMode: PropTypes.oneOf(Object.keys(TEXT_MODES)),
  autoFocus: PropTypes.bool,
  onClose: PropTypes.func,
  forceFocus: PropTypes.bool,
};

export default AlertNotification;
