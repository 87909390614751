import { CountryStateOrgTypes } from './types';

export const setCountry = country => ({
  type: CountryStateOrgTypes.SET_COUNTRY,
  country,
});

export const setState = state => ({
  type: CountryStateOrgTypes.SET_STATE,
  state,
});

export const setOrg = org => ({
  type: CountryStateOrgTypes.SET_ORG,
  org,
});

export const setCachedOrgInfo = orgData => ({
  type: CountryStateOrgTypes.SET_CACHED_ORG,
  data: orgData.data,
});
