import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RegisterPage' })({
  passwordRequirements: {
    marginBottom: 'var(--ebl-s3)',
  },
  verifyPassword: {
    marginBottom: 'var(--ebl-s6)',
  },
  helpLink: {
    marginBottom: 'var(--ebl-s3)',
  },
  title: {
    marginBottom: 'var(--ebl-s2)',
  },
  input: {
    background: 'initial',
    backgroundColor: 'var(--ebl-white)',
  },
});
