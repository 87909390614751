import React from 'react';
import infoSvgSm from '@ed/baseline/icons/cts/info-sm.svg';
import { FormattedMessage } from 'react-intl';
import Icon from '@hmhco/icon';
import { string } from 'prop-types';
import useStyles from './InformationMessage.style';

const InformationMessage = ({ messageId }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <Icon svg={infoSvgSm} size="16" colour="var(--ebl-signal-info)" />
      </div>
      <div className={classes.informationMessage}>
        <FormattedMessage id={messageId} />
      </div>
    </div>
  );
};

InformationMessage.propTypes = {
  messageId: string,
};

export default InformationMessage;
