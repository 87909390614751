/* eslint-disable jsx-a11y/no-static-element-interactions */ // The role required for this component needs to be supplied by the parent component as per the BackButtonReadme.md
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */ // This error appears if a role is not supplied
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import returnIcon from '@ed/baseline/icons/cts/return-sm.svg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Icon from '@hmhco/icon/src/Icon';
import backButtonStyles from './BackButton.styles';

const BackButton = props => {
  const { title, backButtonClick, className, ...domProps } = props;
  const { classes: styleClasses } = backButtonStyles(props, { props });

  const [rootClass, setRootClass] = useState(
    `${styleClasses.root} ${styleClasses.clicked} ${className}`,
  );

  const handleBackButtonKeyDown = event => {
    if (event.keyCode === 13 || event.key.toLowerCase() === 'enter') {
      backButtonClick();
    }
  };
  const handleBackButtonClick = () => {
    backButtonClick();
  };
  const handleFocus = event => {
    if (event.keyCode === 9 || event.key.toLowerCase() === 'tab') {
      setRootClass(`${styleClasses.root} ${className}`);
    }
  };
  const handleBlur = () => {
    setRootClass(`${styleClasses.root} ${styleClasses.clicked} ${className}`);
  };

  return (
    <Grid container>
      <div
        {...domProps}
        className={rootClass}
        tabIndex={0}
        onKeyDown={handleBackButtonKeyDown}
        onKeyUp={handleFocus}
        onBlur={handleBlur}
        onClick={handleBackButtonClick}
        data-testid="back-button"
      >
        <Icon
          className={styleClasses.icon}
          svg={returnIcon}
          size="16"
          data-testid="back-button-return-icon"
        />
        <Typography
          variant="subtitle2"
          component="span"
          className={styleClasses.title}
          data-testid="back-button-title"
        >
          {title}
        </Typography>
      </div>
    </Grid>
  );
};

export default BackButton;

BackButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  backButtonClick: PropTypes.func,
  className: PropTypes.string,
};

BackButton.defaultProps = {
  backButtonClick: () => {},
  className: undefined,
};
