import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import loginClient from '../../api/login';
import loginHnmClient from '../../api/loginHnm';
import useStyles from './LoginPageButtons.styles';
import {
  getCountryStateOrg,
  getCurrentPid,
  getRenderSignInButton,
  getRenderNextButton,
} from '../../store/selectors/CountryStateOrg';
import { setConnectionPid } from '../../storageHelpers/localStorageAccessors';
import {
  setCachedOrgInfo,
  setNextButtonPressedFlag,
} from '../../storageHelpers/sessionStorageAccessors';
import {
  getPassword,
  getUserIsAllowedToSignIn,
  getUsername,
} from '../../store/selectors/UsernameAndPassword';

const LoginPageButtons = ({ currentTheme }) => {
  const { login, checkSsoOrg } = loginClient();
  const { loginHnm, checkSsoOrgHnm } = loginHnmClient();
  const { classes } = useStyles();
  const orgPid = useSelector(getCurrentPid);
  const renderSignInButton = useSelector(getRenderSignInButton);
  const renderNextButton = useSelector(getRenderNextButton);
  const orgData = useSelector(getCountryStateOrg);
  const username = useSelector(getUsername);
  const password = useSelector(getPassword);
  const userIsAllowedToSignIn = useSelector(getUserIsAllowedToSignIn);

  if (!renderSignInButton && !renderNextButton) {
    return null;
  }
  const buttonId = renderSignInButton
    ? 'login.form.button.signIn'
    : 'login.form.button.next';

  const clickHandler = event => {
    event.preventDefault();
    if (renderSignInButton) {
      if (currentTheme === 'HNM') {
        loginHnm({ pid: orgPid, username, password });
      } else {
        login({ pid: orgPid, username, password });
      }
    } else {
      setConnectionPid(orgPid);
      setCachedOrgInfo(orgPid, orgData);
      if (currentTheme === 'HNM') {
        checkSsoOrgHnm({ pid: orgPid });
      } else {
        checkSsoOrg({ pid: orgPid });
      }
      setNextButtonPressedFlag();
    }
  };

  return (
    <Grid item className={classes.submit}>
      <Button
        disabled={renderSignInButton && !userIsAllowedToSignIn}
        variant="contained"
        color="primary"
        type="submit"
        onClick={clickHandler}
      >
        <FormattedMessage id={buttonId} />
      </Button>
    </Grid>
  );
};

LoginPageButtons.propTypes = {
  currentTheme: PropTypes.string,
};

LoginPageButtons.defaultProps = {
  currentTheme: 'HMH',
};

export default LoginPageButtons;
