import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Icon from '@hmhco/icon';
import infoSvg from '@ed/baseline/icons/cts/info-sm.svg';
import { useSelector } from 'react-redux';
import useStyles from './Heading.styles';
import {
  getRenderInternationalForm,
  getRenderUnitedStatesForm,
} from '../../../store/selectors/CountryStateOrg';
import { getOktaVisible } from '../../../store/selectors/TenantPickerState';

const getTitleId = (oktaVisible, hasInternational, hasUnitedStates) => {
  if (oktaVisible) {
    return 'tenantPicker.signIn';
  }
  if (hasInternational) {
    return 'tenantPicker.selectOrganization';
  }
  if (hasUnitedStates) {
    return 'tenantPicker.selectDistrictSchool';
  }

  return 'tenantPicker.signIn';
};

const Heading = () => {
  const { classes } = useStyles();
  const hasInternational = useSelector(getRenderInternationalForm);
  const hasUnitedStates = useSelector(getRenderUnitedStatesForm);
  const oktaVisible = useSelector(getOktaVisible);
  const titleId = getTitleId(oktaVisible, hasInternational, hasUnitedStates);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3">
        <FormattedMessage id={titleId} />
      </Typography>

      <Typography className={classes.requiredFields} component="p">
        <Icon
          svg={infoSvg}
          className={classes.icon}
          size="16"
          ariaHidden
          colour="var(--ebl-signal-info)"
        />
        <FormattedMessage id="tenantPicker.requiredFields" />
      </Typography>
    </div>
  );
};

export default Heading;
