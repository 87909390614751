import React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { func, string } from 'prop-types';
import useStyles from './Input.styles';
import InformationMessage from '../InformationMessage/InformationMessage';
import { AUTO_COMPLETE } from '../../constants/constants';

const VerifyPassword = ({
  password,
  errorMessageId,
  handleChange,
  handleBlur,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const verifyPasswordLabel = formatMessage({
    id: 'registerPage.label.verifyPassword',
  });
  const errorMessage = errorMessageId
    ? formatMessage({ id: errorMessageId })
    : '';

  return (
    <>
      <TextField
        value={password}
        helperText={errorMessage}
        error={Boolean(errorMessageId)}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          className: classes.input,
          'data-testid': 'register-app-reenter-password',
        }}
        label={verifyPasswordLabel}
        id="verifyPassword"
        name="verifyPassword"
        required
        type="password"
        autoComplete={AUTO_COMPLETE.NEW_PASSWORD}
      />
      <div className={classes.verifyPassword}>
        <InformationMessage messageId="newPassword.info.requiredFields" />
      </div>
    </>
  );
};

VerifyPassword.propTypes = {
  password: string.isRequired,
  errorMessageId: string,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
};

export default VerifyPassword;
