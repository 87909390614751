import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { SimpleNotification } from '@hmhco/simple-notification/src/SimpleNotification';
import { useSelector } from 'react-redux';
import RenderSanitizedHtml from '@hmhco/render-sanitized-html/src/RenderSanitizedHtml';
import useStyles from './StudentMessage.styles';
import { getIsForgotPasswordDisabled } from '../../store/selectors/ForgotPasswordOrg';

const StudentMessage = () => {
  const { classes } = useStyles();
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  if (forgotPasswordDisabled) {
    return null;
  }
  const renderStrong = (...chunks) => (
    <strong>
      <RenderSanitizedHtml>{chunks}</RenderSanitizedHtml>
    </strong>
  );
  return (
    <div className={classes.studentMessage}>
      <SimpleNotification justifyContent="flex-start">
        <Typography variant="body2" component="span">
          <FormattedMessage
            id="login.forgotPassword.studentHelpText"
            values={{
              strong: renderStrong,
            }}
          />
        </Typography>
      </SimpleNotification>
    </div>
  );
};

export default StudentMessage;
