import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import LinkToNewTab from '@hmhco/link-to-new-tab/src/LinkToNewTab';
import { getConfigForCurrentEnv, CONFIG_TYPES } from '@hmhco/amp-core-env';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import useStyles from './Footer.styles';

const Footer = () => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();
  const [loginHelp, setLoginHelp] = useState('');
  const [loginPrivacyPolicy, setLoginPrivacyPolicy] = useState('');
  const [loginTermsAndConditions, setLoginTermsAndConditions] = useState('');
  const {
    ampLoginHelp,
    ampLoginPrivacyPolicy,
    ampLoginTermsAndConditions,
    hnmLoginHelp,
    hnmLoginPrivacyPolicy,
    hnmLoginTermsAndConditions,
  } = getConfigForCurrentEnv(CONFIG_TYPES.ed);

  useEffect(() => {
    switch (platform) {
      case PLATFORMS.FLIGHT: {
        setLoginHelp(hnmLoginHelp);
        setLoginPrivacyPolicy(hnmLoginPrivacyPolicy);
        setLoginTermsAndConditions(hnmLoginTermsAndConditions);
        return;
      }
      case PLATFORMS.ED:
      default: {
        setLoginHelp(ampLoginHelp);
        setLoginPrivacyPolicy(ampLoginPrivacyPolicy);
        setLoginTermsAndConditions(ampLoginTermsAndConditions);
      }
    }
  }, [platform]);

  return (
    <footer className={classes.root}>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="tenantPicker.helpLink.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={loginHelp}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="tenantPicker.helpLink" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="tenantPicker.termsAndConditions.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={loginTermsAndConditions}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="tenantPicker.termsAndConditions" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="tenantPicker.privacyPolicy.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={loginPrivacyPolicy}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="tenantPicker.privacyPolicy" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
    </footer>
  );
};

export default Footer;
