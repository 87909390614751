import { getRandomGuid, getRandomId } from './guidUtil';

const getInitialCorrelationId = () => {
  const localStorageCorrelationId = window.localStorage.getItem(
    'com.hmhco.logging.initialCorrelationId',
  );
  if (localStorageCorrelationId) {
    return localStorageCorrelationId;
  }
  return getRandomGuid();
};

const getMainCorrelationId = () => {
  return `${getInitialCorrelationId()}-${getRandomId(3)}`;
};

export default getMainCorrelationId;
