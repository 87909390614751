function addTitleToSvg(svg, title) {
  const newSVGArray = [];
  let modifiedSVG = '';

  if (svg !== undefined && svg.includes('svg')) {
    const svgArray = svg.split('<svg');

    svgArray[0] = '<svg role="img"';

    newSVGArray.push(svgArray[0]);

    const bodySvg = svgArray[1].split('">');

    bodySvg.forEach((body, index) => {
      if (index === 0) newSVGArray.push(`${body}"><title>${title}/></title>`);
      else if (index < bodySvg.length - 1) newSVGArray.push(`${body}">`);
      else newSVGArray.push(body);
    });

    newSVGArray.forEach(newSvg => {
      modifiedSVG += newSvg;
    });

    return modifiedSVG;
  }

  return svg;
}

export default addTitleToSvg;
