import {
  SESSION_STORAGE_CACHED_ORG_KEY,
  SESSION_STORAGE_NEXT_BUTTON_PRESSED_KEY,
  SESSION_STORAGE_OKTA_VISIBLE,
  SESSION_STORAGE_RESOURCE_URL_KEY,
  SESSION_STORAGE_STATE_KEY,
} from '../constants/storageConstants';

function setSessionStorageValue(key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch {
    // We ignore as this is likely that login is being accessed from iframe which is unsupported
  }
}

function getSessionStorageValue(key) {
  try {
    return sessionStorage.getItem(key);
  } catch {
    return null;
  }
}

function removeSessionStorageValue(key) {
  try {
    sessionStorage.removeItem(key);
  } catch {
    // We ignore as this is likely that login is being accessed from iframe which is unsupported
  }
}

export function setCachedOrgInfo(orgPid, reduxState) {
  const cachedDate = JSON.stringify({
    pid: orgPid,
    data: reduxState,
  });
  setSessionStorageValue(SESSION_STORAGE_CACHED_ORG_KEY, cachedDate);
}

export function getCachedOrgInfo(orgPid) {
  const cachedOrg = getSessionStorageValue(SESSION_STORAGE_CACHED_ORG_KEY);
  if (!cachedOrg) {
    return null;
  }
  try {
    const orgData = JSON.parse(cachedOrg);
    if (orgData.pid !== orgPid) {
      return null;
    }
    return orgData;
  } catch {
    return null;
  }
}

export function setResourceUrl(resourceUrl) {
  setSessionStorageValue(SESSION_STORAGE_RESOURCE_URL_KEY, resourceUrl);
}

export function getResourceUrl() {
  return getSessionStorageValue(SESSION_STORAGE_RESOURCE_URL_KEY);
}

export function setNextButtonPressedFlag() {
  setSessionStorageValue(SESSION_STORAGE_NEXT_BUTTON_PRESSED_KEY, true);
}

export function getNextButtonPressedFlag() {
  return getSessionStorageValue(SESSION_STORAGE_NEXT_BUTTON_PRESSED_KEY);
}

export function deleteNextButtonPressedFlag() {
  removeSessionStorageValue(SESSION_STORAGE_NEXT_BUTTON_PRESSED_KEY);
}

export function setStateParam(state) {
  setSessionStorageValue(SESSION_STORAGE_STATE_KEY, state);
}

export function getStateParam() {
  return getSessionStorageValue(SESSION_STORAGE_STATE_KEY);
}

export function setOktaVisible(oktaVisible) {
  setSessionStorageValue(SESSION_STORAGE_OKTA_VISIBLE, oktaVisible);
}

export function getOktaVisible() {
  return getSessionStorageValue(SESSION_STORAGE_OKTA_VISIBLE);
}
