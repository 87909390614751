/* eslint-disable import/prefer-default-export */
import usStates from './usStates.json';

const usStatesMap = usStates.reduce((acc, usState) => {
  acc[usState.code] = usState;
  return acc;
}, {});

export const getStateFromStateCode = stateCode =>
  usStatesMap[stateCode] || { code: stateCode, name: '' };
