import { ForgotPasswordStateTypes } from '../actions/types';

const initialState = {
  username: null,
  loading: false,
  error: false,
  success: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ForgotPasswordStateTypes.FORGOT_PASSWORD_UPDATE_USERNAME: {
      const { username } = action;
      return {
        ...state,
        username,
      };
    }
    case ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_IS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_HAS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_IS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    default: {
      return state;
    }
  }
};
