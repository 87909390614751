import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Svg from '@hmhco/svg/src/Svg';
import OpenInNew from '@ed/baseline/icons/cts/open-sm.svg';
import useStyles from './LinkToNewTab.styles';

const LinkToNewTab = ({ href, isIconFirst, children, ...domProps }) => {
  const { classes } = useStyles({ isIconFirst });

  return (
    <Link
      href={href}
      target="_blank"
      rel="noreferrer"
      className={classes.link}
      {...domProps}
    >
      {!isIconFirst && children}
      <Svg svg={OpenInNew} className={classes.linkIcon} Component="span" />
      {isIconFirst && children}
    </Link>
  );
};

LinkToNewTab.defaultProps = {
  isIconFirst: false,
};

LinkToNewTab.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isIconFirst: PropTypes.bool,
};

export default LinkToNewTab;
