import { CapabilitiesKeyType } from './definitions/CapabilitiesKeyType';
import { Capabilities } from './definitions/GroupMembership';

export const TEACHER_CAPABILITIES: Capabilities = [
  {
    key: CapabilitiesKeyType.worksForSchool,
    value: 'allow',
  },
  {
    key: CapabilitiesKeyType.manageClassroom,
    value: 'allow',
  },
];

export const ADMINISTRATOR_CAPABILITIES: Capabilities = [
  {
    key: CapabilitiesKeyType.manageUser,
    value: 'allow',
  },
  {
    key: CapabilitiesKeyType.manageLicense,
    value: 'allow',
  },
  {
    key: CapabilitiesKeyType.manageSchool,
    value: 'allow',
  },
  {
    key: CapabilitiesKeyType.manageCapabilities,
    value: 'allow',
  },
  {
    key: CapabilitiesKeyType.worksForSchool,
    value: 'allow',
  },
];

export const PRODUCT_QUALIFIERS = [
  'author',
  'product_category_filters',
  'product_release_details',
  'productLine',
];
