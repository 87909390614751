import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';
import { getConfigForCurrentEnv, CONFIG_TYPES } from '@hmhco/amp-core-env';
import { useSelector } from 'react-redux';
import useStyles from './HelpArea.styles';
import { getRenderSignInButton } from '../../store/selectors/CountryStateOrg';

const HelpArea = () => {
  const { classes } = useStyles();
  const { forgotPassword, requestSigninDetails } = getConfigForCurrentEnv(
    CONFIG_TYPES.ed,
  );
  const renderForgotPassword = useSelector(getRenderSignInButton);
  return (
    <>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        className={classes.helpAreaContainer}
      >
        {renderForgotPassword && (
          <Grid item md={6} className={classes.helpLink}>
            <Typography variant="body1">
              <Link href={forgotPassword} target="_self">
                <FormattedMessage id="login.form.forgotPassword.link" />
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        className={classes.helpAreaContainer}
      >
        {renderForgotPassword && (
          <Grid item md={6} className={classes.helpLink}>
            <Typography variant="body1">
              <Link href={requestSigninDetails} target="_self">
                <FormattedMessage id="login.form.forgotPassword.requestSiginDetails" />
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HelpArea;
