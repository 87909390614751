import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'OrgInput' })(() => ({
  orgContainer: {
    paddingBottom: 'var(--ebl-s3)',
  },
  input: {
    background: 'initial',
    backgroundColor: 'white',
  },
}));
