import en from './en-US.json'; // We import this as it's the default and the fallback

export const defaultTranslations = en;

export function getLocaleFile(locale) {
  switch (locale) {
    case 'en-US': {
      return Promise.resolve(en);
    }
    case 'es-US': {
      // Defer loading of spanish until it is needed
      return import('./es-US.json').then(json => json.default);
    }
    default: {
      return Promise.resolve({});
    }
  }
}

export function getFileForTranslations() {
  return en;
}

export default getLocaleFile;
