/* eslint-disable import/prefer-default-export */
import React from 'react';
import Icon, { DISPLAY_TYPES } from '@hmhco/icon/src/Icon';
import error from '@ed/baseline/icons/cts/error-md.svg';
import smallError from '@ed/baseline/icons/cts/error-sm.svg';
import warning from '@ed/baseline/icons/cts/warning-md.svg';
import smallWarning from '@ed/baseline/icons/cts/warning-sm.svg';
import info from '@ed/baseline/icons/cts/info-md.svg';
import smallInfo from '@ed/baseline/icons/cts/info-sm.svg';
import success from '@ed/baseline/icons/cts/success-md.svg';
import smallSuccess from '@ed/baseline/icons/cts/success-sm.svg';
import { SEVERITY, ALERT_SIZE } from './constants';
/**
 * Creates an Icon component with specified SVG and size.
 *
 * @param {boolean} small - Whether to use small icons.
 * @param {string} lgSvg - The large SVG for the icon.
 * @param {string} smallSvg - The small SVG for the icon.
 * @returns {JSX.Element} - An Icon component.
 */
const createIcon = (small, lgSvg, smallSvg, testId) => {
  const size = small ? '16' : '24';
  const svg = small ? smallSvg : lgSvg;
  return (
    <Icon
      svg={svg}
      size={size}
      aria-hidden="true"
      display={DISPLAY_TYPES.noDisplayValue}
      data-testid={`alert-icon-${
        small ? ALERT_SIZE.SMALL : ALERT_SIZE.LARGE
      }-${testId}`}
    />
  );
};

/**
 * Returns an object mapping alert types to corresponding icons.
 *
 * @param {boolean} small - Whether to use small icons (default is false).
 * @returns {Object} - A dictionary with alert types as keys and corresponding icons as values.
 */
export const getIconMappingForAlert = (small = false) => {
  const iconMapping = {
    info: createIcon(small, info, smallInfo, SEVERITY.INFO),
    warning: createIcon(small, warning, smallWarning, SEVERITY.WARNING),
    success: createIcon(small, success, smallSuccess, SEVERITY.SUCCESS),
    error: createIcon(small, error, smallError, SEVERITY.ERROR),
  };
  return iconMapping;
};
