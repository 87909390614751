import React from 'react';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import logoIcon from '@ed/baseline/images/rebrand/HMHEd_c_158x32.svg';
import Svg from '@hmhco/svg/src/Svg';
import addTitleToSvg from '@hmhco/svg/src/utils/svgModifier';
import PropTypes from 'prop-types';
import useStyles from './LoginLogo.styles';
import hnmIcon from './integrationImages/hnm-logo.svg';

const LoginLogo = ({ currentTheme }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  const setImage = () => {
    switch (currentTheme) {
      case 'HNM':
        return hnmIcon;
      case 'HMH':
      default:
        return logoIcon;
    }
  };

  return (
    <Grid
      item
      container
      direction="row"
      wrap="nowrap"
      justifyContent="center"
      className={classes.logoContainer}
    >
      <Svg
        svg={addTitleToSvg(
          setImage(),
          formatMessage({ id: 'login.form.svg.logo' }),
        )}
        className={classes.svg}
      />
    </Grid>
  );
};

LoginLogo.propTypes = {
  currentTheme: PropTypes.string,
};

export default LoginLogo;
