import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { SHORT_SESSION } from '@hmhco/cache-expiration/src/cacheExpirationValues';
import { FETCH_FEATURE_FLAG } from '@hmhco/cache-api-helper/src/actionsAndCacheKeyValues';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';

const featureFlagService = configType => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });

  const { featureFlagURL } = getConfigForCurrentEnv(configType);

  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
  });

  return {
    getCohorts: () =>
      request.get(featureFlagURL, {
        maxAge: SHORT_SESSION,
        keyPrefix: FETCH_FEATURE_FLAG,
      }),
    cancel,
  };
};

export default featureFlagService;
