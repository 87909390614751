import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import { CONFIG_TYPES } from '@hmhco/platform-helper';
import { isFeatureActive } from './isFeatureActive';

export const applyFeatureFlagFilter = config => {
  const isBehindFeatureFlag = app => Boolean(app.featureFlagName);

  const applyFeatureFlagCondition = key => {
    if (config[key].replacementFeatureFlagName) {
      if (isFeatureActive(config[key].replacementFeatureFlagName, true)) {
        return {};
      }

      return { [`${config[key].replacementKey}`]: config[key] };
    }

    if (!isBehindFeatureFlag(config[key])) {
      return { [`${key}`]: config[key] };
    }

    if (isFeatureActive(config[key].featureFlagName, true)) {
      return { [`${key}`]: config[key] };
    }

    return {};
  };

  return Object.keys(config).reduce((aggregate, key) => {
    return { ...aggregate, ...applyFeatureFlagCondition(key) };
  }, {});
};

const ALL_ENVS = ['local', 'dev', 'int', 'cert', 'prod'];
const arrToObjectKeysWithSameValue = (arr, value) => {
  return arr.reduce((prev, curr) => {
    return { ...prev, [curr]: value };
  }, {});
};

/* 
  this is just a one-off function to convert the legacy feature file config
  to the new schema. 
  In this func, all the district ids present will be available in all the environments
  also, any "selectDistrict" feature flag with an "*" as a district will be converted to a "toggleApp" feature flag
  Instead of this function, we will have to modify the feature file config manually to include the correct environment info
  this function can be used to convert it
*/
export const updateCohortsSchema = cohorts => {
  const result = cohorts.reduce((prev, curr) => {
    const cohort = curr;
    const isLegacyFF = cohort.filter.value.local === undefined;
    const isLegacyToggle = isLegacyFF && cohort.filter.value[0] === '*';
    const newFilterValue = isLegacyToggle ? true : cohort.filter.value;
    cohort.filter.value = !isLegacyFF
      ? cohort.filter.value
      : arrToObjectKeysWithSameValue(ALL_ENVS, newFilterValue);
    cohort.filter.target = isLegacyToggle ? 'toggleApp' : cohort.filter.target;
    return [...prev, cohort];
  }, []);
  return result;
};

export const parseFlightUserContext = userContext => {
  return {
    role: userContext.role,
    districtPid: '', // not enough data
    userId: userContext.id ? userContext.id.toString() : '',
    env: getEnvironment(),
    configType: CONFIG_TYPES.hybrid,
    // hcp user contexts always have an id
    validUserContext: !!userContext.id,
  };
};

export const parseEdUserContext = userContext => {
  return {
    role: userContext.role,
    districtPid: userContext.districtPid
      ? userContext.districtPid.toString()
      : '',
    userId: userContext.userId ? userContext.userId.toString() : '',
    env: getEnvironment(),
    configType: CONFIG_TYPES.ed,
    // ed user contexts always have a role
    validUserContext: !!userContext.role,
  };
};

export default {};
