import { updateAlerts } from './utils';

export const createAlertObservers = widgetRef => {
  const oktaErrorContainer = widgetRef.current.querySelector(
    '[data-se="o-form-error-container"]',
  );
  const oktaInfoContainer = widgetRef.current.querySelector(
    '[data-se="o-form-info-container"]',
  );

  const alertObservers = [];

  [oktaErrorContainer, oktaInfoContainer].forEach(alertContainer => {
    if (!alertContainer) {
      return;
    }

    const alertObserver = new MutationObserver(() => updateAlerts(widgetRef));

    alertObserver.observe(alertContainer, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    alertObservers.push(alertObserver);
  });

  return alertObservers;
};

export default createAlertObservers;
