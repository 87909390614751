import { PLATFORMS } from '@hmhco/platform-helper';
import { useEffect } from 'react';
import { Authorize } from '@hmh/eds-login-js';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import {
  getEdsLoginUrl,
  getFlightLoginUrl,
  getSignedInUrl,
} from '../api/buildAuthenticationUrlHnm';
import { HNM_SSO_CLIENT_ID } from '../constants/constants';

const handleSSOError = error => {
  const flightLoginUrl = getFlightLoginUrl();
  logErrorWithContext(
    'Flight Rostered Login app - Error Retrieving Third Party Login URI',
    [{ key: 'errorMessage', value: error }],
  );
  window.location.assign(flightLoginUrl);
};

const useHnmSSORedirect = platform => {
  useEffect(() => {
    if (platform === PLATFORMS.FLIGHT) {
      const search = new URLSearchParams(window.location.search);
      if (search.has('iss')) {
        const authorize = new Authorize(
          HNM_SSO_CLIENT_ID,
          search.get('iss'),
          getEdsLoginUrl(),
          { targetLinkUri: getSignedInUrl() },
        );

        authorize
          .getThirdPartyLoginUri()
          .then(loginInitUri => {
            window.location.assign(loginInitUri);
          })
          .catch(handleSSOError);
      }
    }
  }, [platform]);
};

export default useHnmSSORedirect;
