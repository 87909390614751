import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes, { node } from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import MarketingSection from './MarketingSection';
import { defaultStyle, hnmStyle } from './LoginAppLayout.styles';

const styles = {
  HMH: defaultStyle,
  HNM: hnmStyle,
};

const LoginAppLayout = ({ children, currentTheme }) => {
  const [currentStyle, setCurrentStyle] = useState(null);
  const showOnDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    setCurrentStyle(currentTheme ? styles[currentTheme] : styles.HMH);
  }, []);

  useEffect(() => {
    setCurrentStyle(currentTheme ? styles[currentTheme] : styles.HMH);
  }, [currentTheme]);

  if (!currentStyle) {
    return <></>;
  }

  return (
    <Grid container style={currentStyle.page}>
      {showOnDesktop && (
        <Grid style={currentStyle.marketingArea} container item lg={7} md={6}>
          <MarketingSection currentTheme={currentTheme || null} />
        </Grid>
      )}
      <Grid
        style={currentStyle.pageContent}
        container
        item
        lg={5}
        md={6}
        sm={12}
      >
        {children}
      </Grid>
    </Grid>
  );
};

LoginAppLayout.propTypes = {
  children: node,
  currentTheme: PropTypes.string,
};

export default LoginAppLayout;
