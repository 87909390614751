import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { initializeOktaForm } from './initializeOktaForm';

const useInitializeOktaForm = (isVisible, oktaInfo, widgetRef) => {
  const { formatMessage } = useIntl();

  useEffect(
    () => initializeOktaForm(isVisible, oktaInfo, widgetRef, formatMessage),
    [isVisible, oktaInfo, widgetRef, formatMessage],
  );
};

export default useInitializeOktaForm;
