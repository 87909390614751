import { uniqBy, uniqWith } from 'lodash';
import {
  CapabilitiesGroupType,
  CapabilitiesKeyType,
  LicenseTypes,
  ManagedUser,
  RoleType,
  UserMembership,
  RoleOption,
  TEACHER_CAPABILITIES,
  ADMINISTRATOR_CAPABILITIES,
  Capabilities,
  Role,
} from '@hnm/types';
import { isFeatureActive } from '@hmhco/feature-flags';
import { getAuth } from '@hnm/session-context';

export const isOrganizationIndividual = (membership: UserMembership): boolean =>
  membership.accountType === LicenseTypes.Individual;

export const isOrganizationInstitution = (
  membership: UserMembership,
): boolean => membership.accountType === LicenseTypes.Institutional;

export const getOrganizationLabel = (
  membership: UserMembership,
  // ideally, this would not need to be an input parameter if we had a way of storing some translations inside core
  individualLabel: string,
): string =>
  isOrganizationIndividual(membership)
    ? individualLabel
    : membership.hnmInstitutionName || '';

// this function should be removed, it actually complicates logic everywhere it is used, getOrganizationLabel should be used instead
export const getUserOrganizations = (
  memberships: Array<UserMembership>,
  // ideally, this would not need to be an input parameter if we had a way of storing some translations inside core
  individualLabel: string,
): Array<{ groupId: number; name: string }> =>
  memberships
    .map(membership => ({
      groupId: membership.groupId,
      name: getOrganizationLabel(membership, individualLabel),
    }))
    .filter(m => m.name.length);

export const getUniqueUsers = (users: ManagedUser[]): ManagedUser[] => {
  return uniqWith(users, (left: ManagedUser, right: ManagedUser) => {
    return left.id === right.id;
  });
};

export const getManagedOrganizations = (
  memberships: Array<UserMembership>,
): Array<UserMembership> => {
  const uniqOrganization: Array<UserMembership> = [];
  memberships
    .filter(membership => membership.membershipRole === RoleType.Owner)
    .filter(membership =>
      ([LicenseTypes.Institutional, LicenseTypes.Individual] as (
        | LicenseTypes
        | undefined
      )[]).includes(membership.accountType),
    )
    .forEach(membership => {
      if (membership.accountType === LicenseTypes.Institutional) {
        uniqOrganization.push(membership);
      }
    });
  const indvMembership = memberships.find(
    membership =>
      membership.accountType === LicenseTypes.Individual &&
      membership.membershipRole === RoleType.Owner,
  );
  if (indvMembership) {
    uniqOrganization.push(indvMembership);
  }
  return uniqOrganization;
};

export const getDistinctOrganizations = (
  memberships: Array<UserMembership>,
): Array<UserMembership> => {
  return uniqBy(memberships, membership => membership.groupId);
};

export const isAbleToManageUser = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return (
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.manageUser &&
          capability.value === 'allow',
      ) &&
      (groupMemberships.capabilitiesGroup?.type ===
        CapabilitiesGroupType.individual ||
        groupMemberships.capabilitiesGroup?.type ===
          CapabilitiesGroupType.institution)
    );
  });
};

export const isAbleToLicence = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return groupMemberships.capabilities?.some(
      capability =>
        capability.key === CapabilitiesKeyType.manageLicense &&
        capability.value === 'allow',
    );
  });
};

export const isAbleToRoster = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return groupMemberships.capabilities?.some(
      capability =>
        (capability.key === CapabilitiesKeyType.performBulkRostering ||
          capability.key === CapabilitiesKeyType.performManualRostering) &&
        capability.value === 'allow',
    );
  });
};

export const isAbleToDiscover = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return (
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.viewProduct &&
          capability.value === 'allow',
      ) &&
      groupMemberships.capabilitiesGroup?.type === CapabilitiesGroupType.private
    );
  });
};

export const isAbleToTakeAssessment = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return groupMemberships.capabilities?.some(
      capability =>
        capability.key === CapabilitiesKeyType.attendSchool &&
        capability.value === 'allow',
    );
  });
};

export const getDistinctOrganizationsPreferredAdmin = (
  memberships: Array<UserMembership>,
): Array<UserMembership> => {
  const sortedMemberships = [...memberships].sort(
    (membershipA, membershipB) => {
      if (membershipA.membershipRole === membershipB.membershipRole) {
        return 0;
      }
      if (membershipA.membershipRole === RoleType.Owner) {
        return -1;
      }
      return 1;
    },
  );
  return getDistinctOrganizations(sortedMemberships);
};

export const roleOptionToCapabilities = (
  roleOption: RoleOption | undefined,
): Capabilities => {
  return roleOption === RoleOption.Teacher || roleOption === undefined
    ? TEACHER_CAPABILITIES
    : ADMINISTRATOR_CAPABILITIES;
};

export const roleOptionToRole = (
  roleOption: RoleOption | undefined,
): RoleType => {
  return roleOption === RoleOption.Teacher || roleOption === undefined
    ? RoleType.Member
    : RoleType.Owner;
};

export const isDealer2User = (user): boolean => {
  return user.groupMemberships.some(groupMemberships => {
    return groupMemberships?.capabilitiesGroup?.linkedAccounts?.some(
      accounts => accounts.account.dealerId === 2,
    );
  });
};

export const isRoleDistrictAdmin = (user): boolean => {
  const auth = getAuth();
  if (Number(auth?.authContext?.idToken?.claims?.tenant_id) !== 2) {
    return (
      user.groupMemberships.some(groupMemberships => {
        return (
          groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.manageDistrict &&
              capability.value === 'allow',
          ) &&
          groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.worksForDistrict &&
              capability.value === 'allow',
          )
        );
      }) && isDealer2User(user)
    );
  }
  return user.groupMemberships.some(groupMemberships => {
    return (
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.manageDistrict &&
          capability.value === 'allow',
      ) &&
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.worksForDistrict &&
          capability.value === 'allow',
      ) &&
      groupMemberships.capabilitiesGroup?.type ===
        CapabilitiesGroupType.district &&
      groupMemberships.capabilitiesGroup.linkedAccounts?.some(
        accounts => accounts.account.dealerId === 2,
      )
    );
  });
};

export const isRoleSchoolAdmin = (user): boolean => {
  const auth = getAuth();
  if (Number(auth?.authContext?.idToken?.claims?.tenant_id) !== 2) {
    return (
      user.groupMemberships.some(groupMemberships => {
        return (
          groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.manageSchool &&
              capability.value === 'allow',
          ) &&
          groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.worksForSchool &&
              capability.value === 'allow',
          )
        );
      }) && isDealer2User(user)
    );
  }
  return user.groupMemberships.some(groupMemberships => {
    return (
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.manageSchool &&
          capability.value === 'allow',
      ) &&
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.worksForSchool &&
          capability.value === 'allow',
      ) &&
      groupMemberships.capabilitiesGroup?.type ===
        CapabilitiesGroupType.school &&
      groupMemberships.capabilitiesGroup.linkedAccounts?.some(
        accounts => accounts.account.dealerId === 2,
      )
    );
  });
};

export const isRoleTeacher = (user): boolean => {
  const auth = getAuth();
  if (Number(auth?.authContext?.idToken?.claims?.tenant_id) !== 2) {
    return (
      user.groupMemberships.some(groupMemberships => {
        return (
          (groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.manageClassroom &&
              capability.value === 'allow',
          ) &&
            groupMemberships.capabilities?.some(
              capability =>
                capability.key === CapabilitiesKeyType.worksForSchool &&
                capability.value === 'allow',
            )) ||
          groupMemberships.capabilities?.some(
            capability =>
              capability.key === CapabilitiesKeyType.teachClass &&
              capability.value === 'allow',
          )
        );
      }) && isDealer2User(user)
    );
  }
  return user.groupMemberships.some(groupMemberships => {
    return (
      (groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.manageClassroom &&
          capability.value === 'allow',
      ) &&
        groupMemberships.capabilities?.some(
          capability =>
            capability.key === CapabilitiesKeyType.worksForSchool &&
            capability.value === 'allow',
        ) &&
        groupMemberships.capabilitiesGroup?.type ===
          CapabilitiesGroupType.school &&
        groupMemberships.capabilitiesGroup.linkedAccounts?.some(
          accounts => accounts.account.dealerId === 2,
        )) ||
      groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.teachClass &&
          capability.value === 'allow' &&
          groupMemberships.capabilitiesGroup?.type ===
            CapabilitiesGroupType.class &&
          groupMemberships.capabilitiesGroup.linkedAccounts?.some(
            accounts => accounts.account.dealerId === 2,
          ),
      )
    );
  });
};

export const isRoleStudent = (user): boolean => {
  const auth = getAuth();
  if (Number(auth?.authContext?.idToken?.claims?.tenant_id) !== 2) {
    return (
      user.groupMemberships.some(groupMemberships => {
        return groupMemberships.capabilities?.some(
          capability =>
            (capability.key === CapabilitiesKeyType.attendSchool &&
              capability.value === 'allow') ||
            (capability.key === CapabilitiesKeyType.attendClass &&
              capability.value === 'allow'),
        );
      }) && isDealer2User(user)
    );
  }
  return user.groupMemberships.some(groupMemberships => {
    return groupMemberships.capabilities?.some(
      capability =>
        (capability.key === CapabilitiesKeyType.attendSchool &&
          capability.value === 'allow' &&
          groupMemberships.capabilitiesGroup?.type ===
            CapabilitiesGroupType.school &&
          groupMemberships.capabilitiesGroup.linkedAccounts?.some(
            accounts => accounts.account.dealerId === 2,
          )) ||
        (capability.key === CapabilitiesKeyType.attendClass &&
          capability.value === 'allow' &&
          groupMemberships.capabilitiesGroup?.type ===
            CapabilitiesGroupType.class &&
          groupMemberships?.capabilitiesGroup.linkedAccounts?.some(
            accounts => accounts.account.dealerId === 2,
          )),
    );
  });
};

export const isAbleToReport = (user): boolean => {
  const isAdminReportsActive = isFeatureActive('flight-admin-reports', true);
  return (
    user.groupMemberships.some(groupMemberships => {
      return groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.reporting &&
          capability.value === 'allow',
      );
    }) &&
    (isRoleTeacher(user) ||
      (isAdminReportsActive &&
        (isRoleSchoolAdmin(user) || isRoleDistrictAdmin(user))))
  );
};

export const isAbleToManageClass = (user): boolean => {
  return (
    user.groupMemberships.some(groupMemberships => {
      return groupMemberships.capabilities?.some(
        capability =>
          capability.key === CapabilitiesKeyType.manageClassroom &&
          capability.value === 'allow',
      );
    }) && isRoleTeacher(user)
  );
};

export const discoverRole = (user): string => {
  if (isRoleDistrictAdmin(user)) {
    return Role.DistrictAdmin;
  }
  if (isRoleSchoolAdmin(user)) {
    return Role.SchoolAdmin;
  }
  if (isRoleTeacher(user)) {
    return Role.Teacher;
  }
  if (isRoleStudent(user)) {
    return Role.Student;
  }
  return '';
};
