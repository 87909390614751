import React from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import RenderSanitizedHtml from '@hmhco/render-sanitized-html/src/RenderSanitizedHtml';
import useStyles from './RequestSignInDetailsInformationHeader.styles';
import { getIsRequestSigninDisabled } from '../../store/selectors/RequestSigninPageState';

const RequestSignInDetailsInformationHeader = () => {
  const { classes } = useStyles();
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);
  const renderStrong = (...chunks) => (
    <strong>
      <RenderSanitizedHtml>{chunks}</RenderSanitizedHtml>
    </strong>
  );

  return (
    <>
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.requestSiginDetails.pageTitle" />
      </Typography>
      {!requestSigninDisabled && (
        <>
          <Typography
            component="p"
            variant="body2"
            className={classes.informationPanel}
          >
            <FormattedMessage id="login.requestSiginDetails.helpText" />
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className={classes.informationPanel}
          >
            <FormattedMessage
              id="login.requestSiginDetails.studentHelpText"
              values={{
                strong: renderStrong,
              }}
            />
          </Typography>
        </>
      )}
    </>
  );
};

export default RequestSignInDetailsInformationHeader;
