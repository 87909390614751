import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import useStyles from './ForgotPasswordErrorState.styles';
import ForgotPasswordButtons from './ForgotPasswordButtons';

const ForgotPasswordErrorState = () => {
  const { classes } = useStyles();
  return (
    <>
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.forgotPassword.pageTitle" />
      </Typography>
      <Typography
        component="p"
        variant="body2"
        className={classes.informationPanel}
      >
        <FormattedMessage id="login.forgotPassword.errorMessage" />
      </Typography>
      <ForgotPasswordButtons />
    </>
  );
};

export default ForgotPasswordErrorState;
