const intHnmConfig = {
  name: 'int',
  edsLoginLandingPage:
    'https://platform.int.heinemann.com/ui/login/landing-page',
  contentShareAppUrl: 'https://platform-content-share.int.heinemann.com/',
  edsLogoutUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: 'https://platform.int.heinemann.com/',
  flightRosteredLoginUrl: 'https://platform.int.heinemann.com/ui/login/',
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: 'https://platform-int.authorization.heinemann.com',
  edsGraphUrl:
    'https://app01.int.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  edsLtiUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-lti/lti',
  edLtiUrl: 'https://api.int.br.internal/lti-platform',
  rceProtocolHost: 'http://int.hmhone.app.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: true,
  edsContentShareClientId: '686d53aa-db86-4754-b265-bf2f36a527fb',
  edsContentShareInitializeUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-lti/lti/authorize',
  edsContentShareTokenUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-lti/lti/token',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  restUdsApi: 'https://api.int.eng.hmhco.com/uds/',
};

module.exports = intHnmConfig;
