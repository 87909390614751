import {
  getConfigForCurrentEnv,
  getConfigTypeForPlatform,
} from '@hmhco/amp-core-env';
import { getPlatform } from '@hmhco/platform-helper';
import { handleError } from '../axiosHelpers';

/**
 * create an Axios config with default values
 *
 * @param {object} options object containing the following values:
 * @param {string} options.cancelToken
 * @param {string} options.baseURL
 * @param {object} options.options expected content: {headers}
 * @param {object} options.data for DELETE method
 * @param {object} options.pactConfig for PACT tests only
 *
 * @returns {object} configuration for Axios client
 */
export const getConfig = ({
  cancelToken,
  baseURL,
  options = {},
  data = null,
  pactConfig = {},
}) => {
  const platform = getPlatform();
  const configType = getConfigTypeForPlatform(platform);
  const { apiBaseURL } = getConfigForCurrentEnv(configType);
  const { headers, ...requestOptions } = options;
  const finalBaseURL = baseURL === '' ? apiBaseURL : baseURL;

  return {
    ...requestOptions,
    baseURL: finalBaseURL,
    ...(headers ? { headers } : {}),
    ...(data ? { data } : {}),
    ...pactConfig,
    ...(cancelToken ? { cancelToken } : {}),
  };
};

/**
 * Helper for creating API
 * wrap Axios call with commonly use pattern (promise based, error handling)
 *
 * @param {object} options object containing the following values:
 * @param {object} client Axios client
 * @param {string} options.baseURL
 * @param {string} options.cancelToken
 * @param {boolean} options.isCancel expected content: {headers}
 * @param {object} options.pactConfig for PACT tests only
 *
 * @returns {promise} promise based response of Axios
 */
const requestHelper = ({
  client,
  baseURL = '',
  cancelToken = null,
  isCancel = null,
  pactConfig = {},
  fullResponse = false,
}) => {
  const run = (method, ...args) => {
    return method(...args)
      .then(response => {
        if (fullResponse || !response?.data) {
          return response;
        }
        return response?.data;
      })
      .catch(error => {
        return handleError(error, isCancel);
      });
  };

  return {
    post: (url, data, options = {}) => {
      const { maxAge = null, keyPrefix = null, ...moreOptions } = options;
      const cache = {
        keyPrefix, // If this value is null, axiosHelpers will fall back to the default key of the req.url itself
      };
      // Only include maxAge if it has been specifically included in this request, otherwise, use the default in axiosHelpers
      if (maxAge) {
        cache.maxAge = maxAge;
      }
      return run(client.post, url, data, {
        ...getConfig({
          cancelToken,
          baseURL,
          options: moreOptions,
          pactConfig,
        }),
        cache,
      });
    },

    patch: (url, data, options = {}) =>
      run(
        client.patch,
        url,
        data,
        getConfig({ cancelToken, baseURL, options, pactConfig }),
      ),

    put: (url, data, options = {}) =>
      run(
        client.put,
        url,
        data,
        getConfig({ cancelToken, baseURL, options, pactConfig }),
      ),

    delete: (url, data, options = {}) =>
      run(
        client.delete,
        url,
        getConfig({ cancelToken, baseURL, options, data, pactConfig }),
      ),

    get: (url, options = {}) => {
      const { maxAge = null, keyPrefix = null, ...moreOptions } = options;
      const cache = {
        keyPrefix, // If this value is null, axiosHelpers will fall back to the default key of the req.url itself
      };
      // Only include maxAge if it has been specifically included in this request, otherwise, use the default in axiosHelpers
      if (maxAge) {
        cache.maxAge = maxAge;
      }
      return run(client.get, url, {
        ...getConfig({
          cancelToken,
          baseURL,
          options: moreOptions,
          pactConfig,
        }),
        cache,
      });
    },
  };
};

export default requestHelper;
