import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { getConfig } from './buildAuthenticationUrl';

const CLIENT_ID_HNM = '80B4EFE0-B723-46AC-A46A-C75037B44F86';
const SCOPE = 'openid profile';

/**
Generates a random nonce string.
The nonce is a randomly generated string that is creating unique value for a digital signature.
It is generated using random number generation to ensure
unpredictability and resistance against manipulation or prediction.
@returns {string} A randomly generated nonce.
*/
export function randomNonce() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4() + s4() + s4();
}

export function getFlightLoginUrl() {
  return getConfig().flightLogin;
}

export function getAmpLoginUrl() {
  return getConfig().ampLogin;
}

export function getSignedInUrl() {
  return getConfig().ampSignedInURL;
}

export function getEdsLoginUrl() {
  return getConfig().edsInitUrl;
}

export function tryDecodeUrlParam(variable, key) {
  try {
    return decodeURIComponent(variable);
  } catch (error) {
    logErrorWithContext('Failed login decoding', [
      { key: 'decodeKey', value: key },
      { key: 'attemptedDecodeValue', value: variable },
    ]);
    return '';
  }
}

function buildAuthenticationUrlHnm({ pid }) {
  if (!pid) {
    throw new Error('buildAuthenticationUrlHnm requires a pid');
  }
  const signedInUrl = getSignedInUrl();
  const encodedSignedInUrl = encodeURIComponent(signedInUrl);
  const baseUrlHnm = getFlightLoginUrl();
  const searchParams = new URLSearchParams(
    `?connection=${pid}&client_id=${CLIENT_ID_HNM}&scope=${SCOPE}&redirect_uri=${encodedSignedInUrl}`,
  );
  return `${baseUrlHnm}?${searchParams.toString()}`;
}

export default buildAuthenticationUrlHnm;
