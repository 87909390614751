export const Criteria = [
  {
    id: 'allowDistrictIDs',
    check(userObject) {
      const { userData } = userObject;
      return userObject.value[userData.env]?.includes(userData.districtPid);
    },
  },
  {
    id: 'allowedUserType',
    check(userObject) {
      const { userData } = userObject;
      return userObject.value[userData.env]?.includes(userData.role);
    },
  },
  {
    id: 'allowedUserId',
    check(userObject) {
      const { userData } = userObject;
      return userObject.value[userData.env]?.includes(userData.userId);
    },
  },
  {
    id: 'globalAppToggle',
    check(userObject) {
      const { userData } = userObject;
      return userObject.value[userData.env];
    },
  },
  {
    id: 'disallowDistrictIDs',
    check(userObject) {
      const { userData } = userObject;
      return !userObject.value[userData.env]?.includes(userData.districtPid);
    },
  },
];

export const Features = [
  {
    id: 'selectDistrict',
    criteria: { allowDistrictIDs: true },
  },
  { id: 'exceptDistricts', criteria: { disallowDistrictIDs: false } },
  {
    id: 'selectUserType',
    criteria: { allowedUserType: true },
  },
  { id: 'selectUserId', criteria: { allowedUserId: true } },
  { id: 'toggleApp', criteria: { globalAppToggle: true } },
];
