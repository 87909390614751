import { createSelector } from 'reselect';

export const getForgotPasswordOrgInfo = state => state.forgotPasswordOrgInfo;

export const getForgotPasswordOrgName = createSelector(
  [getForgotPasswordOrgInfo],
  forgotPasswordOrgInfo => forgotPasswordOrgInfo?.orgName || '',
);

export const getIsForgotPasswordDisabled = createSelector(
  [getForgotPasswordOrgInfo],
  forgotPasswordOrgInfo => forgotPasswordOrgInfo?.forgotPasswordDisabled,
);
