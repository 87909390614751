import createEblPalette from './createEblPalette';

export default styles => {
  const ebl = createEblPalette(styles);

  return {
    primary: {
      main: ebl.actionColor,
      light: ebl.lightActionColor,
      dark: ebl.darkActionColor,
    },
    secondary: {
      main: ebl.brand,
      light: ebl.lightBrand,
    },
    neutral: {
      main: '#ffffff',
      light: '#ffffff',
    },
    action: {
      disabled: ebl.disabledColor, // Set for disabled state of components - class .Mui-disabled
      disabledBackground: ebl.disabledColor, // Set for disabled state of components - class .Mui-disabled
    },
    text: {
      disabled: ebl.disabledColor, // Set for disabled text state of components - class .Mui-disabled
    },
    error: { main: ebl.signalError, background: ebl.alertNotifErrorBg },
    success: { main: ebl.signalSuccess, background: ebl.alertNotifSuccessBg },
    info: { main: ebl.signalInfo, background: ebl.alertNotifInfoBg },
    warning: { main: ebl.signalWarning, background: ebl.alertNotifWarningBg },
    background: { default: ebl.pagelayoutBackgroundcolor },
    ebl,
  };
};
