export function createForm() {
  return document.createElement('form');
}

export function setAttributes(form, { method, url, encoding }) {
  form.setAttribute('method', method);
  form.setAttribute('action', url);
  form.setAttribute('style', 'display:none');
  form.setAttribute('encType', encoding);
}

export function setUsername(form, username) {
  const usernameField = document.createElement('input');
  usernameField.setAttribute('name', 'username');
  usernameField.setAttribute('value', username);
  form.appendChild(usernameField);
}

export function setPassword(form, password) {
  const passwordField = document.createElement('input');
  passwordField.setAttribute('name', 'password');
  passwordField.setAttribute('value', password);
  form.appendChild(passwordField);
}

export async function submitForm(form) {
  document.body.appendChild(form);
  await form.submit();
  await form.remove();
}

export function navigateToSsoService(url) {
  window.location.assign(url);
}
