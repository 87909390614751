import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import {
  setLoginFlow,
  setLoginHint,
  setLoginStep,
} from '../store/actions/TenantPickerActions';
import { setConnectionPid } from '../storageHelpers/localStorageAccessors';
import { setNextButtonPressedFlag } from '../storageHelpers/sessionStorageAccessors';
import { LOGIN_FLOWS } from '../constants/constants';
import { setShowUsernameAndPassword } from '../store/actions/UsernameAndPasswordActions';

export const handleLoginHint = (dispatch, orgPid, cachedOrgInfo) => {
  dispatch(setShowUsernameAndPassword(true));
  dispatch(setLoginStep(2));
  setConnectionPid(orgPid);
  setCachedOrgInfo(orgPid, cachedOrgInfo);
  setNextButtonPressedFlag();
  dispatch(setCachedOrgInfo(cachedOrgInfo));
  dispatch(setLoginFlow(LOGIN_FLOWS.INTEGRATED));
  dispatch(setLoginHint(true));
};
export default {};
