import React from 'react';
import Icon from '@hmhco/icon';
import { SimpleNotification } from '@hmhco/simple-notification/src/SimpleNotification';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import info from '@ed/baseline/icons/cts/info-md.svg';
import RenderSanitizedHtml from '@hmhco/render-sanitized-html/src/RenderSanitizedHtml';
import AlertNotification from '@hmhco/alert-notification/src/AlertNotification';
import addTitleToSvg from '@hmhco/svg/src/utils/svgModifier';
import { oneOf } from 'prop-types';
import useGetLoginMessage from '../../hooks/getLoginMessage.hook';
import useStyles from './LoginNotificationBanner.styles';
import { QUERY_PARAMS } from '../../constants/constants';

const successMessageMap = {
  [QUERY_PARAMS.PASSWORD_RESET]: 'login.form.passwordReset',
  [QUERY_PARAMS.REGISTER_USER]: 'login.form.message.newUserCreated',
};

const MAXIMUM_NUMBER_OF_LOGIN_MESSAGE_REQUESTS = 10;

const LoginNotificationBanner = props => {
  const loginMessage = useGetLoginMessage(
    MAXIMUM_NUMBER_OF_LOGIN_MESSAGE_REQUESTS,
  );
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { redirectedFrom } = props;
  const successMessage = redirectedFrom && successMessageMap[redirectedFrom];
  const svgInfo = addTitleToSvg(
    info,
    formatMessage({ id: 'login.form.svg.information' }),
  );

  return (
    <>
      <div className={classes.notificationContainer}>
        {loginMessage?.activate && (
          <SimpleNotification
            justifyContent="flex-start"
            icon={
              <Icon svg={svgInfo} size="16" colour="var(--ebl-signal-info)" />
            }
          >
            <Typography variant="body2" component="span">
              <FormattedMessage
                id="login.form.message"
                values={{
                  loginMessageTitle: loginMessage?.title,
                  loginMessage: loginMessage?.content,
                  strong: (...chunks) => (
                    <strong>
                      <RenderSanitizedHtml>{chunks}</RenderSanitizedHtml>
                    </strong>
                  ),
                  span: (...chunks) => (
                    <span>
                      <RenderSanitizedHtml>{chunks}</RenderSanitizedHtml>
                    </span>
                  ),
                }}
              />
            </Typography>
          </SimpleNotification>
        )}
      </div>
      {successMessage && (
        <AlertNotification severity="success">
          <FormattedMessage id={successMessage} />
        </AlertNotification>
      )}
    </>
  );
};

LoginNotificationBanner.propTypes = {
  redirectedFrom: oneOf([
    QUERY_PARAMS.PASSWORD_RESET,
    QUERY_PARAMS.REGISTER_USER,
  ]),
};

export default LoginNotificationBanner;
