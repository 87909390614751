import hasValidRedirect from '@hmhco/redirect-validator/src/hasValidRedirect';
import { getConfigForCurrentEnv, CONFIG_TYPES } from '@hmhco/amp-core-env';
import { STATE_QUERY_PARAM } from '@hmhco/url-const-loginstate/src/loginState';

export default ({ encodeStateInUrl = false } = {}) => {
  const { ampLogin } = getConfigForCurrentEnv(CONFIG_TYPES.ed);
  const currentUrl = window.location.href;
  const urlSearch = new URLSearchParams();
  if (encodeStateInUrl && hasValidRedirect(currentUrl)) {
    urlSearch.append(STATE_QUERY_PARAM, currentUrl);
  }
  window.location.assign(`${ampLogin}?${urlSearch.toString()}`);
};
