import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import LinkToNewTab from '@hmhco/link-to-new-tab/src/LinkToNewTab';
import { getConfigForCurrentEnv, CONFIG_TYPES } from '@hmhco/amp-core-env';
import PropTypes from 'prop-types';
import useStyles from './LoginFooter.styles';

const LoginFooter = ({ currentTheme }) => {
  const { classes } = useStyles();
  const [loginHelp, setLoginHelp] = useState('');
  const [loginPrivacyPolicy, setLoginPrivacyPolicy] = useState('');
  const [loginTermsAndConditions, setLoginTermsAndConditions] = useState('');
  const {
    ampLoginHelp,
    ampLoginPrivacyPolicy,
    ampLoginTermsAndConditions,
    hnmLoginHelp,
    hnmLoginPrivacyPolicy,
    hnmLoginTermsAndConditions,
  } = getConfigForCurrentEnv(CONFIG_TYPES.ed);

  useEffect(() => {
    if (currentTheme && currentTheme === 'HNM') {
      setLoginHelp(hnmLoginHelp);
      setLoginPrivacyPolicy(hnmLoginPrivacyPolicy);
      setLoginTermsAndConditions(hnmLoginTermsAndConditions);
    } else {
      setLoginHelp(ampLoginHelp);
      setLoginPrivacyPolicy(ampLoginPrivacyPolicy);
      setLoginTermsAndConditions(ampLoginTermsAndConditions);
    }
  }, [currentTheme]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.loginFooterContainer}
    >
      <Grid item xs={12} sm="auto">
        <Typography variant="body1">
          <FormattedMessage id="login.form.help.helpLink.aria">
            {ariaLabel => (
              <LinkToNewTab href={loginHelp} aria-label={ariaLabel}>
                <FormattedMessage id="login.form.help.helpLink" />
              </LinkToNewTab>
            )}
          </FormattedMessage>
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" className={classes.termsAndConditions}>
        <Typography variant="body1">
          <FormattedMessage id="login.form.footer.termsAndConditions.aria">
            {ariaLabel => (
              <LinkToNewTab
                href={loginTermsAndConditions}
                aria-label={ariaLabel}
              >
                <FormattedMessage id="login.form.footer.termsAndConditions" />
              </LinkToNewTab>
            )}
          </FormattedMessage>
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" className={classes.privacyPolicy}>
        <Typography variant="body1">
          <FormattedMessage id="login.form.footer.privacyPolicy.aria">
            {ariaLabel => (
              <LinkToNewTab href={loginPrivacyPolicy} aria-label={ariaLabel}>
                <FormattedMessage id="login.form.footer.privacyPolicy" />
              </LinkToNewTab>
            )}
          </FormattedMessage>
        </Typography>
      </Grid>
    </Grid>
  );
};

LoginFooter.propTypes = {
  currentTheme: PropTypes.string,
};

export default LoginFooter;
