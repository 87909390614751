import {
  VANILLA_ROUTES,
  VANILLA_ROUTE_EXCLUSIONS,
  EXCLUDE_PENDO_ROUTE,
} from './constants';

export const isVanillaRoute = () => {
  /* eslint-disable-next-line no-restricted-globals */
  return VANILLA_ROUTES.some(route => location.href.includes(route));
};
export const isExcludePendoRoute = () => {
  /* eslint-disable-next-line no-restricted-globals */
  return EXCLUDE_PENDO_ROUTE.some(route => location.href.includes(route));
};

export { VANILLA_ROUTE_EXCLUSIONS };
