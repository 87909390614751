import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ReadOnlyField from '@hmhco/form-components/src/ReadOnlyField';
import { getForgotPasswordOrgName } from '../../store/selectors/ForgotPasswordOrg';
import useStyles from './OrgInfo.styles';

const OrgInfo = () => {
  const { classes } = useStyles();
  const orgName = useSelector(getForgotPasswordOrgName);
  return (
    <div className={classes.orgField}>
      <ReadOnlyField
        label={
          <FormattedMessage id="login.requestSiginDetails.selectedOrgLabel" />
        }
        value={orgName}
        data-testid="request-signin-selected-org"
      />
    </div>
  );
};

export default OrgInfo;
