import React from 'react';
import { Grid } from '@mui/material';
import PropTypes, { node } from 'prop-types';
import useStyles from './LoginAppFormLayout.styles';
import LoginLogo from './LoginLogo';
import LoginFooter from './LoginFooter';

const LoginAppFormLayout = ({ children, currentTheme }) => {
  const { classes } = useStyles();
  return (
    <Grid
      container
      direction="column"
      component="form"
      className={classes.form}
    >
      <LoginLogo currentTheme={currentTheme || null} />
      {children}
      <LoginFooter currentTheme={currentTheme || null} />
    </Grid>
  );
};

LoginAppFormLayout.propTypes = {
  children: node,
  currentTheme: PropTypes.string,
};

export default LoginAppFormLayout;
