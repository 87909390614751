import {
  ENVIRONMENTS,
  CONFIG_TYPES,
} from '../../../../environments/globalConstants';

import localHnmConfig from '../../../../config/appConfigs/hnm/local';
import localSupportToolConfig from '../../../../config/appConfigs/supportTool/local';

import devEdConfig from '../../../../config/appConfigs/ed/dev';
import devHnmConfig from '../../../../config/appConfigs/hnm/dev';
import devHybridConfig from '../../../../config/appConfigs/hybrid/dev';
import devSupportToolConfig from '../../../../config/appConfigs/supportTool/dev';

import intEdConfig from '../../../../config/appConfigs/ed/int';
import intHnmConfig from '../../../../config/appConfigs/hnm/int';
import intHybridConfig from '../../../../config/appConfigs/hybrid/int';
import intSupportToolConfig from '../../../../config/appConfigs/supportTool/int';

import certEdConfig from '../../../../config/appConfigs/ed/cert';
import certHnmConfig from '../../../../config/appConfigs/hnm/cert';
import certHybridConfig from '../../../../config/appConfigs/hybrid/cert';
import certSupportToolConfig from '../../../../config/appConfigs/supportTool/cert';

import devCertSupportToolConfig from '../../../../config/appConfigs/supportTool/devCert';
import devCertHnmConfig from '../../../../config/appConfigs/hnm/devCert';

import prodEdConfig from '../../../../config/appConfigs/ed/prod';
import prodHnmConfig from '../../../../config/appConfigs/hnm/prod';
import prodHybridConfig from '../../../../config/appConfigs/hybrid/prod';
import prodSupportToolConfig from '../../../../config/appConfigs/supportTool/prod';

export default {
  [ENVIRONMENTS.local]: {
    [CONFIG_TYPES.ed]: devEdConfig, // ed local === ed dev | this will have to change when the legacy backend gets a proper dev environment
    [CONFIG_TYPES.hnm]: localHnmConfig,
    [CONFIG_TYPES.supportTool]: localSupportToolConfig,
    [CONFIG_TYPES.hybrid]: devHybridConfig, // hybrid local === hybrid dev | this will have to change when the legacy backend gets a proper dev environment
  },
  [ENVIRONMENTS.dev]: {
    [CONFIG_TYPES.ed]: devEdConfig,
    [CONFIG_TYPES.hnm]: devHnmConfig,
    [CONFIG_TYPES.hybrid]: devHybridConfig,
    [CONFIG_TYPES.supportTool]: devSupportToolConfig,
  },
  [ENVIRONMENTS.int]: {
    [CONFIG_TYPES.ed]: intEdConfig,
    [CONFIG_TYPES.hnm]: intHnmConfig,
    [CONFIG_TYPES.hybrid]: intHybridConfig,
    [CONFIG_TYPES.supportTool]: intSupportToolConfig,
  },
  [ENVIRONMENTS.devCert]: {
    [CONFIG_TYPES.ed]: {
      ...certEdConfig,
      name: 'devCert',
      ampContent: devEdConfig.ampContent,
      liftRoot: devEdConfig.liftRoot,
      featureFlagURL: devEdConfig.featureFlagURL,
      ampSignedInURL: devEdConfig.ampSignedInURL,
    },
    [CONFIG_TYPES.hybrid]: {
      ...certHybridConfig,
      name: 'devCert',
      ampContent: devHybridConfig.ampContent,
      liftRoot: devHybridConfig.liftRoot,
      featureFlagURL: devHybridConfig.featureFlagURL,
      flightLogin: devHybridConfig.flightLogin,
      ampSignedInURL: devHybridConfig.ampSignedInURL,
    },
    [CONFIG_TYPES.supportTool]: devCertSupportToolConfig,
    [CONFIG_TYPES.hnm]: devCertHnmConfig,
  },
  [ENVIRONMENTS.cert]: {
    [CONFIG_TYPES.ed]: certEdConfig,
    [CONFIG_TYPES.hnm]: certHnmConfig,
    [CONFIG_TYPES.hybrid]: certHybridConfig,
    [CONFIG_TYPES.supportTool]: certSupportToolConfig,
  },
  [ENVIRONMENTS.prod]: {
    [CONFIG_TYPES.ed]: prodEdConfig,
    [CONFIG_TYPES.hnm]: prodHnmConfig,
    [CONFIG_TYPES.hybrid]: prodHybridConfig,
    [CONFIG_TYPES.supportTool]: prodSupportToolConfig,
  },
};
