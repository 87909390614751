import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import useStyles from './RequestSignInDetailsSuccessState.styles';
import RequestSignInDetailsButtons from './RequestSignInDetailsButtons';

const RequestSignInDetailsSuccessState = () => {
  const { classes } = useStyles();
  return (
    <>
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.requestSiginDetails.pageTitle" />
      </Typography>
      <Typography
        component="p"
        variant="body2"
        className={classes.informationPanel}
      >
        <FormattedMessage id="login.RequestSignInDetails.successMessage" />
      </Typography>
      <RequestSignInDetailsButtons canSubmit={false} />
    </>
  );
};

export default RequestSignInDetailsSuccessState;
