import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import useStyles from './Password.styles';
import {
  getHasLoginFailed,
  getIsUsernameValid,
} from '../../store/selectors/UsernameAndPassword';
import { setUsername } from '../../store/actions/UsernameAndPasswordActions';
import { AUTO_COMPLETE } from '../../constants/constants';

const Username = ({ shouldUsernameFocus }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hasLoginFailed = useSelector(getHasLoginFailed);
  const usernameIsValid = useSelector(getIsUsernameValid);
  const changeCallback = useCallback(
    event => {
      const username = event?.target?.value;
      dispatch(setUsername(username));
    },
    [dispatch],
  );

  const error = Boolean(hasLoginFailed || !usernameIsValid);
  let helperText = null;
  if (error) {
    helperText = hasLoginFailed
      ? 'login.form.failed.username.label'
      : 'login.form.failed.username.length';
  }

  return (
    <FormattedMessage id="login.form.usernameLabel">
      {usernameLabel => (
        <TextField
          id="username"
          name="username"
          onChange={changeCallback}
          label={usernameLabel}
          inputProps={{
            'data-testid': 'username',
            className: classes.input,
          }}
          FormHelperTextProps={{
            'aria-live': 'polite',
          }}
          required
          helperText={helperText && <FormattedMessage id={helperText} />}
          error={error}
          autoComplete={AUTO_COMPLETE.USERNAME}
          autoFocus={shouldUsernameFocus || hasLoginFailed}
        />
      )}
    </FormattedMessage>
  );
};

Username.defaultProps = {
  shouldUsernameFocus: false,
};

Username.propTypes = {
  shouldUsernameFocus: bool,
};

export default Username;
