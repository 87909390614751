import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'HelpArea' })(() => ({
  helpLink: {
    textAlign: 'end',
  },
  helpAreaContainer: {
    paddingBottom: 'var(--ebl-s4)',
    justifyContent: 'end',
  },
}));
