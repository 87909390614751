import { useEffect, useState } from 'react';
import {
  deleteNextButtonPressedFlag,
  getNextButtonPressedFlag,
} from '../storageHelpers/sessionStorageAccessors';

function useGetUserHasNavigatedFromDistrictSelect() {
  const [userHasNavigated, setUserHasNavigated] = useState(false);

  useEffect(() => {
    const nextButtonPressedFlag = getNextButtonPressedFlag();
    if (nextButtonPressedFlag) {
      setUserHasNavigated(Boolean(nextButtonPressedFlag));
      deleteNextButtonPressedFlag();
    }
  }, []);
  return userHasNavigated;
}

export default useGetUserHasNavigatedFromDistrictSelect;
