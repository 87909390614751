import { datadogRum } from '@datadog/browser-rum';
import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import {
  isDeployedEnvironment,
  getVersion,
} from '@hmhco/client-monitoring/src/environmentFunctions';

export default (
  applicationId,
  applicationName,
  clientToken,
  allowedTracingUrls,
  sessionSampleRate = 1, // There is a cost associated with this value, get approval from Brendan Donegan prior to changing
  sessionReplaySampleRate = 0,
  trackViewsManually = false,
) => {
  const env = getEnvironment();
  const version = getVersion();
  if (!isDeployedEnvironment(env)) {
    return;
  }
  const sampleRate = env === 'prod' ? sessionSampleRate : 100;
  const replaySampleRate = env === 'prod' ? sessionReplaySampleRate : 100;
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: applicationName,
    env,
    version,
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: replaySampleRate,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackViewsManually,
    allowedTracingUrls,
    defaultPrivacyLevel: env === 'prod' ? 'mask-user-input' : 'allow',
  });
  window.DATADOG_RUM_ENABLED = true;
};
