import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const PlatformContext = createContext(null);

export const PlatformProvider = ({ platform, children }) => {
  return (
    <PlatformContext.Provider value={platform}>
      {children}
    </PlatformContext.Provider>
  );
};

PlatformProvider.propTypes = {
  platform: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const usePlatform = () => useContext(PlatformContext);

export default usePlatform;
