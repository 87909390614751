export const isFeatureActive = (flagName, isStrict = false) => {
  try {
    const flags = JSON.parse(localStorage.getItem('featureFlags'));
    const isFeatureFlagged = flags?.includes(flagName);
    const isDemoEntitled = flags?.includes('demoEntitlements');

    return isStrict ? isFeatureFlagged : isFeatureFlagged || isDemoEntitled;
  } catch (e) {
    return false;
  }
};

export default isFeatureActive;
