import React from 'react';
import PropTypes, { oneOf } from 'prop-types';
import LoginForm from './LoginForm';
import LoginAppLayout from '../Layout/LoginAppLayout';
import { QUERY_PARAMS } from '../../constants/constants';
import useHnmSSORedirect from '../../hooks/useHnmSSORedirect.hook';

const LoginPage = ({ redirectedFrom, currentTheme }) => {
  if (redirectedFrom) {
    document.title = `Success - ${document.title}`;
  }

  useHnmSSORedirect(currentTheme);

  return (
    <LoginAppLayout currentTheme={currentTheme}>
      <LoginForm redirectedFrom={redirectedFrom} currentTheme={currentTheme} />
    </LoginAppLayout>
  );
};

LoginPage.defaultProps = {
  redirectedFrom: '',
  currentTheme: 'HMH',
};

LoginPage.propTypes = {
  redirectedFrom: oneOf([
    QUERY_PARAMS.PASSWORD_RESET,
    QUERY_PARAMS.REGISTER_USER,
  ]),
  currentTheme: PropTypes.string,
};

export default LoginPage;
