const DAYS_UNTIL_EXPIRATION = 30;
export const formatToMonthDayYear = (date: string) => {
  const formattedDate = new Date(date);

  if (Number.isNaN(formattedDate.getTime())) {
    throw new Error('Invalid date input');
  }
  return new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(formattedDate);
};

export const isDateWithinRange = (
  referenceDate: string,
  days: number = DAYS_UNTIL_EXPIRATION,
): boolean => {
  const currentDate = new Date();
  const targetDate = new Date(referenceDate);

  if (Number.isNaN(targetDate.getTime())) {
    throw new Error('Invalid date input');
  }

  targetDate.setDate(targetDate.getDate() - days);
  return new Date(referenceDate) > currentDate && currentDate >= targetDate;
};

export const isExpired = (expirationDate: string) => {
  const date = new Date(expirationDate);

  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  return new Date(date) < new Date();
};

export const isDateWithinRangeForListOfEntitlements = entitlementList =>
  entitlementList.some(product => isDateWithinRange(product.expirationDate));

export default {
  formatToMonthDayYear,
  isDateWithinRange,
  isExpired,
  isDateWithinRangeForListOfEntitlements,
};
