/* eslint-disable react-hooks/rules-of-hooks, import/no-cycle */
import React from 'react';
import { string, node, bool } from 'prop-types';
import featureFlagService from '@hmhco/feature-flag-api/src/featureFlagApi';
import fflip from 'fflip';
import { isDemoUser } from '@hmhco/demo-entitlements-utils/src/utils/isDemoUser';
import { CONFIG_TYPES } from '@hmhco/platform-helper';
import setFeatureFlags, { FEATURE_FLAGS_KEY } from './utils/wrapperMethods';
import { Features, Criteria } from '../config/featureFlags.config';
import { updateCohortsSchema } from './utils/utils';
import { isFeatureActive } from './utils/isFeatureActive';

export * from './utils/utils';
export * from './utils/isFeatureActive';

export const compileFeatureFlagConfig = (userData, demoUser) => {
  const { getCohorts } = featureFlagService(userData.configType);
  fflip.config({
    criteria: Criteria,
    features: Features,
  });

  return getCohorts()
    .then(cohorts => {
      setFeatureFlags(userData, fflip, updateCohortsSchema(cohorts), demoUser);
    })
    .catch(() => {
      setFeatureFlags(userData, fflip, [], demoUser);
    });
};

export const instantiateFeatureFlagConfig = async userData => {
  const existingFeatureFlags = localStorage.getItem(FEATURE_FLAGS_KEY);
  const featureFlagsInitialized = existingFeatureFlags?.length > 0;
  const isValidUser = userData.validUserContext;

  const demoUser =
    userData.configType === CONFIG_TYPES.ed ? await isDemoUser() : false;

  // if no feature flags, or the user context is not empty, build feature flags
  if (!featureFlagsInitialized || isValidUser) {
    return compileFeatureFlagConfig(userData, demoUser);
  }
  // otherwise do nothing
  return Promise.resolve();
};

const FeatureFlag = props => {
  const { flagName, strictMode, children } = props;
  return <>{isFeatureActive(flagName, strictMode) && children}</>;
};

FeatureFlag.defaultProps = {
  strictMode: false,
};

FeatureFlag.propTypes = {
  flagName: string.isRequired,
  children: node.isRequired,
  strictMode: bool,
};

export default FeatureFlag;
