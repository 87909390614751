/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { Authorize, getPkceCodeVerifier } from '@hmh/eds-login-js';
import { LoadingButton } from '@hmhco/loading-button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { CONFIG_TYPES, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import Button from '@mui/material/Button';
import useStyles from './ActionButtons.styles';
import {
  getCountryStateOrg,
  getCurrentOrg,
  getCurrentPid,
  getRenderNextButton,
} from '../../../store/selectors/CountryStateOrg';
import {
  setCachedOrgInfo,
  setNextButtonPressedFlag,
} from '../../../storageHelpers/sessionStorageAccessors';
import { setConnectionPid } from '../../../storageHelpers/localStorageAccessors';
import {
  getPassword,
  getShowUsernameAndPassword,
  getUsername,
} from '../../../store/selectors/UsernameAndPassword';
import {
  getClientId,
  LOGIN_FLOWS,
  NON_SSO_ISSUER,
} from '../../../constants/constants';
import {
  getLoginFlow,
  getLoginStep,
} from '../../../store/selectors/TenantPickerState';
import { getConfig } from '../../../api/buildAuthenticationUrl';
import {
  currentRouteIntegration,
  environment,
} from '../../../../../../../environments/environment';
import loginHnmClient from '../../../api/loginHnm';
import loginClient from '../../../api/login';

const { loginHnm } = loginHnmClient();
const { login } = loginClient();

/**
 * @summary Checks feature flagging for okta embedded widget, or SSO redirection. Otherwise signs in via eds login.
 * @returns Button
 */
const ActionButtons = () => {
  const { classes } = useStyles();
  const orgPid = useSelector(getCurrentPid);
  const orgId = useSelector(getCurrentOrg);
  const renderSignInButton = useSelector(getShowUsernameAndPassword);
  const renderNextButton = useSelector(getRenderNextButton);
  const orgData = useSelector(getCountryStateOrg);
  const username = useSelector(getUsername);
  const password = useSelector(getPassword);
  const loginFlow = useSelector(getLoginFlow);
  const loginStep = useSelector(getLoginStep);
  const [isGenerating, setIsGenerating] = useState(false);
  const { edsInitUrl, ampSignedInURL } = getConfig();
  const configuration =
    environment.allConfigurations[currentRouteIntegration()].name;

  const { forgotPassword, requestSigninDetails } = getConfigForCurrentEnv(
    CONFIG_TYPES.ed,
  );

  const forgotPasswordLink = forgotPassword.replace(
    '/forgot-password',
    '/tenant-picker/forgot-password',
  );

  const requestSignInDetailsLink = requestSigninDetails.replace(
    '/request-signin-details',
    '/tenant-picker/request-signin-details',
  );

  const handleIntegratedLoginFlow = () => {
    setNextButtonPressedFlag();
    setConnectionPid(orgPid);
    setCachedOrgInfo(orgPid, orgData);
    if (configuration === 'HNM') {
      return loginHnm({ pid: orgPid, username, password });
    }
    return login({ pid: orgPid, username, password });
  };

  const handleContinueToSignIn = async () => {
    setConnectionPid(orgPid);
    setCachedOrgInfo(orgPid, orgData);

    await getPkceCodeVerifier('S256').then(({ challenge, verifier }) => {
      sessionStorage.setItem('verifier', verifier);
      sessionStorage.setItem('challenge', challenge);
    });

    const authorize = new Authorize(getClientId(), NON_SSO_ISSUER, edsInitUrl, {
      connection: orgPid,
      targetLinkUri: ampSignedInURL,
      login_hint: orgId.orgRefId,
    });

    authorize
      .getThirdPartyLoginUri({ loginHint: orgId.orgRefId })
      .then(loginInitUri => {
        window.location = loginInitUri;
      })
      .catch(() => {
        setIsGenerating(false);
      });
  };

  const clickHandler = async event => {
    setIsGenerating(true);
    event.preventDefault();
    if (loginStep === 1) {
      await handleContinueToSignIn();
    }
    if (loginStep === 2) {
      if (loginFlow === LOGIN_FLOWS.INTEGRATED) {
        handleIntegratedLoginFlow();
      }
    }
  };

  const getButtonText = () => {
    if (loginStep === 1) {
      return 'tenantPicker.continueToSignIn';
    }
    return 'tenantPicker.signIn';
  };

  if (loginFlow === LOGIN_FLOWS.OKTA && loginStep === 2) {
    return null;
  }

  return (
    <Grid container className={classes.root}>
      {loginStep === 2 && (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="flex-end"
          className={classes.actionLinkContainer}
        >
          <Grid item justifyContent="flex-end">
            <Typography variant="body1">
              <Link href={forgotPasswordLink} target="_self">
                <FormattedMessage id="login.form.forgotPassword.link" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent={loginStep === 2 ? 'space-between' : 'flex-end'}
        className={classes.actionLinkContainer}
      >
        {loginStep === 2 && (
          <Link
            href={requestSignInDetailsLink}
            target="_self"
            className={classes.signInDetailsLink}
          >
            <Button size="small" variant="outlined">
              <FormattedMessage id="tenantPicker.requestSiginDetails" />
            </Button>
          </Link>
        )}
        <FormattedMessage id={getButtonText()}>
          {([label]) => (
            <LoadingButton
              loading={isGenerating}
              disabled={!renderSignInButton && !renderNextButton}
              buttonProps={{
                children: label,
                color: 'primary',
                className: classes.button,
                variant: 'contained',
                size: 'small',
                onClick: clickHandler,
              }}
              loadingText={label}
            />
          )}
        </FormattedMessage>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
