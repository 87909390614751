import { datadogLogs } from '@datadog/browser-logs';
import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import {
  isDeployedEnvironment,
  getVersion,
} from '@hmhco/client-monitoring/src/environmentFunctions';

export default (applicationName, clientToken) => {
  const env = getEnvironment();
  const version = getVersion();
  if (!isDeployedEnvironment(env)) {
    return;
  }
  datadogLogs.init({
    clientToken,
    forwardErrorsToLogs: true,
    site: 'datadoghq.com',
    sampleRate: 100,
    env,
    version,
    service: applicationName,
  });
  // Needed otherwise it is not tracked with Datadog
  // It seems DD drops errors if the env is local. Something to be aware of when testing locally
  datadogLogs.setGlobalContextProperty('stage', env);
  datadogLogs.setGlobalContextProperty('service', applicationName);
  window.DATADOG_LOGS_ENABLED = true;
};
