import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Heading' })(() => ({
  root: {
    paddingBottom: 'var(--ebl-s3)',
  },
  requiredFields: {
    display: 'flex',
    gap: 'var(--ebl-s2)',
  },
  icon: {
    paddingTop: '0.0625rem',
  },
}));
