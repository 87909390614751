import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { arrayOf, shape, string } from 'prop-types';
import ComboBox from '@hmhco/combobox/src/ComboBox/ComboBox';
import useStyles from './CountryInput.styles';
import { getCurrentCountry } from '../../store/selectors/CountryStateOrg';
import { setCountry } from '../../store/actions/CountryStateOrgActions';

const CountryInput = ({ countries }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentCountry = useSelector(getCurrentCountry);
  const setCountryCallback = useCallback(country => {
    dispatch(setCountry(country));
  });
  return (
    <div className={classes.countryContainer}>
      <FormattedMessage id="login.form.country.label">
        {([label]) => (
          <ComboBox
            autoHighlight
            classes={{ input: classes.input }}
            required
            dataTestId="loginCountrySelect"
            label={label}
            options={countries}
            getOptionLabel={option => option.name || ''}
            value={currentCountry}
            handleChange={(event, value) => {
              setCountryCallback(value);
            }}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

CountryInput.propTypes = {
  countries: arrayOf(shape({ code: string, name: string })),
};

export default CountryInput;
