import { createSelector } from 'reselect';
import { getCurrentOrg } from './CountryStateOrg';

const MIN_USERNAME_LENGTH = 5;
const MIN_PASSWORD_LENGTH = 5;

export const getUsernameAndPassword = state => state.usernameAndPassword;

export const getShowUsernameAndPassword = createSelector(
  [getUsernameAndPassword, getCurrentOrg],
  (usernameAndPassword, currentOrg) =>
    usernameAndPassword?.showUsernameAndPassword ||
    Boolean(currentOrg?.pid && currentOrg?.isConnectionPid),
);

export const getHasLoginFailed = createSelector(
  [getUsernameAndPassword],
  usernameAndPassword => usernameAndPassword?.loginHasFailed,
);

export const getUsername = createSelector(
  [getUsernameAndPassword],
  usernameAndPassword => usernameAndPassword?.username || '',
);

export const getPassword = createSelector(
  [getUsernameAndPassword],
  usernameAndPassword => usernameAndPassword?.password || '',
);

export const getUsernameHasMinLength = createSelector(
  [getUsername],
  username => {
    return username.length >= MIN_USERNAME_LENGTH;
  },
);

export const getIsUsernameValid = createSelector(
  [getUsername, getUsernameHasMinLength],
  (username, usernameHasMinLength) => {
    const usernameHasNotChanged = username === '';
    return usernameHasNotChanged || usernameHasMinLength;
  },
);

export const getPasswordHasMinLength = createSelector(
  [getPassword],
  password => {
    return password.length >= MIN_PASSWORD_LENGTH;
  },
);

export const getIsPasswordValid = createSelector(
  [getPassword, getPasswordHasMinLength],
  (password, passwordHasMinLength) => {
    const passwordHasNotChanged = password === '';
    return passwordHasNotChanged || passwordHasMinLength;
  },
);

export const getUserIsAllowedToSignIn = createSelector(
  [getPasswordHasMinLength, getUsernameHasMinLength],
  (passwordHasMinLength, usernameHasMinLength) => {
    return passwordHasMinLength && usernameHasMinLength;
  },
);
