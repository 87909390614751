import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import AlertNotification from '@hmhco/alert-notification/src/AlertNotification';
import { getIsForgotPasswordDisabled } from '../../store/selectors/ForgotPasswordOrg';

const ForgotPasswordDisabledMessage = () => {
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  if (!forgotPasswordDisabled) {
    return null;
  }
  return (
    <AlertNotification severity="error" small textMode="inline">
      <FormattedMessage id="login.forgotPassword.forgotPasswordDisabledMessage" />
    </AlertNotification>
  );
};

export default ForgotPasswordDisabledMessage;
