import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const PlatformConfigContext = createContext(null);

const PlatformConfigProvider = ({ customConfig, children }) => {
  return (
    <PlatformConfigContext.Provider value={customConfig}>
      {children}
    </PlatformConfigContext.Provider>
  );
};

PlatformConfigProvider.propTypes = {
  customConfig: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default PlatformConfigProvider;

export const usePlatformConfigContext = () => {
  return useContext(PlatformConfigContext) || {};
};
