import { LicenseTypes, Role, RoleType, User, UserMembership } from '@hnm/types';
import { UserGroup } from '@hnm/types/src/definitions/UserMembership';
import {
  AccountQualifierDTO,
  GroupCapabilitiesDTO,
  GroupCapabilitiesKeyDTO,
} from '@hnm/types/src/definitions/UserCapabilities';
import {
  assembleAltIdKeyValue,
  assembleQualifierKeyValue,
} from './DataStructureUtils';
import {
  discoverRole,
  isAbleToDiscover,
  isAbleToLicence,
  isAbleToManageClass,
  isAbleToManageUser,
  isAbleToReport,
  isAbleToRoster,
  isAbleToTakeAssessment,
} from './userUtils';

const mapAccount = (
  account,
): Omit<
  ReturnType<typeof mapGroup>,
  'groupId' | 'groupName' | 'groupParentId'
> => {
  if (!account) {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    return {} as any;
  }

  return {
    accountId: account.id,
    accountType: account.accountType,
    dealerId: account.dealerId,
    domainId: account.domainId,
    sourceId: account.source,
    ...assembleQualifierKeyValue(account.accountQualifier ?? []),
    ...assembleAltIdKeyValue(account.accountAltId ?? []),
  };
};

export const mapGroup = (group): UserGroup => {
  if (!group) {
    return {} as UserGroup;
  }
  return {
    groupId: group.id,
    groupParentId: group.parentId,
    groupName: group.name,
    ...mapAccount(group.account),
  };
};

export const mapMembership = (membership): UserMembership => {
  if (!membership) {
    return {} as UserMembership;
  }

  return {
    membershipId: membership.id,
    membershipRole: membership.role,
    ...mapGroup(membership.group),
  };
};

export const mapUser = (user): User => {
  if (!user) {
    return {} as User;
  }

  const memberships: Array<UserMembership> = (user.memberships ?? []).map(
    mapMembership,
  );

  const role = user.groupMemberships ? discoverRole(user) : '';

  // This will be removed soon we will need to remove all it's usage
  const isAdmin =
    memberships.some(
      membership => membership.membershipRole === RoleType.Owner,
    ) ||
    role === Role.DistrictAdmin ||
    role === Role.SchoolAdmin;

  const abilities = {
    canManageUser: user.groupMemberships ? isAbleToManageUser(user) : isAdmin,
    canLicence: user.groupMemberships ? isAbleToLicence(user) : isAdmin,
    canDiscover: user.groupMemberships ? isAbleToDiscover(user) : true,
    canRoster: user.groupMemberships ? isAbleToRoster(user) : false,
    canReport: user.groupMemberships ? isAbleToReport(user) : false,
    canAssessment: user.groupMemberships ? isAbleToTakeAssessment(user) : false,
    canScores: user.groupMemberships ? isAbleToTakeAssessment(user) : false,
    canManageClass: user.groupMemberships ? isAbleToManageClass(user) : false,
  };

  return {
    id: user.id,
    givenName: user.givenName,
    familyName: user.familyName,
    email: user.email,
    name: `${user.givenName} ${user.familyName}`,
    isAdmin,
    memberships,
    abilities,
    role,
  };
};

interface MapCapabilitiesForMembershipParams {
  accountQualifier: AccountQualifierDTO[];
  capabilities: GroupCapabilitiesDTO[];
  dealerId: number;
  domainId: number;
  accountId: number;
  sourceId: number;
  accountType: LicenseTypes;
}

const mapCapabilitiesForMembership = ({
  accountQualifier,
  capabilities,
  dealerId,
  domainId,
  accountId,
  sourceId,
  accountType,
}: MapCapabilitiesForMembershipParams): UserMembership => {
  // For determining the user's role, we only need the key attribute from each capability
  const capabilitiesKeys = capabilities.map(capability => capability.key);

  // To determine if a user is an Owner (Administrator in UI role), capabilities must include both keys 'manage_licensing' and 'add_user_functions'
  const isOwner =
    capabilitiesKeys.includes(GroupCapabilitiesKeyDTO.licensing) &&
    capabilitiesKeys.includes(GroupCapabilitiesKeyDTO.manageCapabilities);

  // To determine if a user is a member (Teacher in UI role), capabilities must include the key 'member'
  const isMember = capabilitiesKeys.includes(GroupCapabilitiesKeyDTO.member);

  const institutionName = accountQualifier.find(
    qualifier => qualifier.key === 'hnm_institution_name',
  );

  let membershipRole;

  if (isOwner) {
    membershipRole = RoleType.Owner;
  } else if (isMember) {
    membershipRole = RoleType.Member;
  }

  return {
    dealerId,
    domainId,
    groupId: accountId, // The groupId needs to be mapped to the accountId when we want to construct a membership from a groupMembership.
    hnmInstitutionName: institutionName?.value,
    membershipRole,
    accountType,
    sourceId,
  };
};

export const mapUserAndTransformGroupMembership = (user): User => {
  const mappedUsers: UserMembership[] = [];
  user.groupMemberships.forEach(groupMembership => {
    if (groupMembership.capabilitiesGroup?.linkedAccounts) {
      groupMembership.capabilitiesGroup?.linkedAccounts.forEach(accounts => {
        const {
          id,
          dealerId,
          domainId,
          source: sourceId,
          accountQualifier,
          accountType,
        } = accounts.account;

        mappedUsers.push(
          mapCapabilitiesForMembership({
            accountQualifier,
            capabilities: groupMembership.capabilities,
            dealerId,
            domainId,
            accountId: id,
            sourceId,
            accountType,
          }),
        );
      });
    }
  });

  const role = discoverRole(user);

  const isAdmin =
    mappedUsers.some(
      membership => membership.membershipRole === RoleType.Owner,
    ) ||
    role === Role.DistrictAdmin ||
    role === Role.SchoolAdmin;

  const abilities = {
    canManageUser: isAbleToManageUser(user),
    canLicence: isAbleToLicence(user),
    canDiscover: isAbleToDiscover(user),
    canRoster: isAbleToRoster(user),
    canReport: isAbleToReport(user),
    canAssessment: isAbleToTakeAssessment(user),
    canScores: isAbleToTakeAssessment(user),
    canManageClass: isAbleToManageClass(user),
  };

  return {
    id: user.id,
    givenName: user.givenName,
    familyName: user.familyName,
    email: user.email,
    name: `${user.givenName} ${user.familyName}`,
    isAdmin,
    memberships: mappedUsers,
    abilities,
    role,
  };
};
