import React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import LinkToNewTab from '@hmhco/link-to-new-tab/src/LinkToNewTab';
import { bool, func, string } from 'prop-types';
import { PLATFORMS } from '@hmhco/platform-helper';
import useStyles from './Input.styles';
import { AUTO_COMPLETE } from '../../constants/constants';

const OrderNumber = ({
  orderNumber,
  error,
  isTouched,
  handleChange,
  handleBlur,
  registerAdminHelpLink,
  currentConfiguration,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const orderNumberLabel =
    currentConfiguration === PLATFORMS.FLIGHT
      ? formatMessage({
          id: 'registerPage.label.orderNumber.flight',
        })
      : formatMessage({
          id: 'registerPage.label.orderNumber',
        });
  const linkText =
    currentConfiguration === PLATFORMS.FLIGHT
      ? formatMessage({ id: 'registerPage.link.help.orderNumber.flight' })
      : formatMessage({ id: 'registerPage.link.help.orderNumber' });
  const isError = Boolean(isTouched && error);
  const errorMessage = isTouched && error ? formatMessage({ id: error }) : '';

  return (
    <>
      <TextField
        value={orderNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={errorMessage}
        error={isError}
        inputProps={{
          className: classes.input,
        }}
        label={orderNumberLabel}
        id="orderNumber"
        name="orderNumber"
        required
        autoComplete={AUTO_COMPLETE.ONE_TIME_CODE}
      />
      <div className={classes.helpLink}>
        <LinkToNewTab href={registerAdminHelpLink} aria-label={linkText}>
          {linkText}
        </LinkToNewTab>
      </div>
    </>
  );
};

OrderNumber.propTypes = {
  orderNumber: string,
  error: string,
  isTouched: bool,
  handleChange: func,
  handleBlur: func,
  registerAdminHelpLink: string.isRequired,
  currentConfiguration: string,
};

export default OrderNumber;
