import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfoRequiredFields from '@hmhco/form-components/src/InfoRequiredFields';
import LoginAppLayout from '../Layout/LoginAppLayout';
import useBootstrapOrgInfo from '../../hooks/bootstrapForgotPasswordOrgInfo.hook';
import LoginAppFormLayout from '../Layout/LoginAppFormLayout';
import RequestSignInDetailsInformationHeader from './RequestSignInDetailsInformationHeader';
import OrgInfo from './OrgInfo';
import RequestSignInDetailsEmail from './RequestSignInDetailsEmail';
import RequestSignInDetailsDisabledMessage from './RequestSignInDetailsDisabledMessage';
import RequestSignInDetailsButtons from './RequestSignInDetailsButtons';
import {
  getShouldRenderErrorScreen,
  getShouldRenderRequestSigninForm,
  getShouldRenderSuccessScreen,
  getIsRequestSigninDisabled,
} from '../../store/selectors/RequestSigninPageState';
import RequestSignInDetailsErrorState from './RequestSignInDetailsErrorState';
import RequestSignInDetailsSuccessState from './RequestSignInDetailsSuccessState';

const RequestSignInDetailsPage = ({ currentTheme }) => {
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const renderRequestSignInDetailsForm = useSelector(
    getShouldRenderRequestSigninForm,
  );
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);

  useBootstrapOrgInfo();
  return (
    <LoginAppLayout currentTheme={currentTheme}>
      <LoginAppFormLayout currentTheme={currentTheme}>
        {renderErrorState && <RequestSignInDetailsErrorState />}
        {renderSuccessState && <RequestSignInDetailsSuccessState />}
        {renderRequestSignInDetailsForm && (
          <>
            <RequestSignInDetailsInformationHeader />
            {!requestSigninDisabled && <InfoRequiredFields />}

            <OrgInfo />
            {!requestSigninDisabled && <RequestSignInDetailsEmail />}
            <RequestSignInDetailsDisabledMessage />
            <RequestSignInDetailsButtons
              currentTheme={currentTheme}
              canSubmit={!requestSigninDisabled}
            />
          </>
        )}
      </LoginAppFormLayout>
    </LoginAppLayout>
  );
};

RequestSignInDetailsPage.propTypes = {
  currentTheme: PropTypes.string,
};
RequestSignInDetailsPage.defaultProps = {
  currentTheme: null,
};

export default RequestSignInDetailsPage;
