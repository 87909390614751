import {
  getConfigForCurrentEnv,
  getConfigTypeForPlatform,
} from '@hmhco/amp-core-env';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { currentRouteIntegration } from '../../../../../environments/environment';

const CLIENT_ID = '152ced50-1369-4b19-8b26-8f3d5d9bfd6a.hmhco.com';
const SCOPE = 'openid profile';
const RESPONSE_TYPE = 'id_token token';

/**
Generates a random nonce string.
The nonce is a randomly generated string that is creating unique value for a digital signature.
It is generated using random number generation to ensure
unpredictability and resistance against manipulation or prediction.
@returns {string} A randomly generated nonce.
*/
export function randomNonce() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4() + s4() + s4();
}

export const getConfig = () => {
  const platform = currentRouteIntegration();
  const configType = getConfigTypeForPlatform(platform);
  return getConfigForCurrentEnv(configType);
};

export function getSusUrl() {
  const config = getConfig();
  return config.susAuth;
}

export function getLoggedInUrl() {
  const config = getConfig();
  return config.ampLoggedInURL;
}

export function tryDecodeUrlParam(variable, key) {
  try {
    return decodeURIComponent(variable);
  } catch (error) {
    logErrorWithContext('Failed login decoding', [
      { key: 'decodeKey', value: key },
      { key: 'attemptedDecodeValue', value: variable },
    ]);
    return '';
  }
}

function buildAuthenticationUrl({ pid, nonce, stateParam, resourceUrl }) {
  if (!pid || !nonce) {
    throw new Error('buildAuthenticationUrl requires a pid and a nonce');
  }
  const loggedInUrl = getLoggedInUrl();
  const encodedLoggedInUrl = encodeURIComponent(loggedInUrl);
  const susUrl = getSusUrl();
  const baseUrl = `${susUrl}/authorize`;
  const searchParams = new URLSearchParams(
    `?connection=${pid}&client_id=${CLIENT_ID}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}&nonce=${nonce}&redirect_uri=${encodedLoggedInUrl}&state={}`,
  );
  if (stateParam) {
    const decodedState = tryDecodeUrlParam(stateParam, 'state');
    if (decodedState) {
      searchParams.set('state', decodedState);
    }
  }
  if (resourceUrl) {
    const decodedResourceUrl = tryDecodeUrlParam(resourceUrl, 'resource_url');
    if (decodedResourceUrl) {
      searchParams.set('resource_url', decodedResourceUrl);
    }
  }
  return `${baseUrl}?${searchParams.toString()}`;
}

export default buildAuthenticationUrl;
