import { combineReducers } from 'redux';
import countryStateOrg from './CountryStateOrg';
import usernameAndPassword from './UsernameAndPassword';
import forgotPasswordOrgInfo from './ForgotPasswordOrgInfo';
import forgotPasswordState from './ForgotPasswordPageState';
import requestSigninState from './RequestSigninPageState';
import tenantPickerState from './TenantPickerState';

// rootReducer
// add any additional reducers inside combineReducers
const rootReducer = combineReducers({
  countryStateOrg,
  usernameAndPassword,
  forgotPasswordOrgInfo,
  forgotPasswordState,
  requestSigninState,
  tenantPickerState,
});

export default rootReducer;
