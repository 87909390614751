import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import OktaSignIn from '@okta/okta-signin-widget';

export const createAndDisplayOktaWidget = async (oktaInfo, widgetRef) => {
  try {
    const oktaWidget = new OktaSignIn(oktaInfo);

    oktaWidget
      .showSignInAndRedirect({
        el: widgetRef.current,
      })
      .catch(error => {
        logErrorWithContext(
          'OktaSignIn widget failed to showSignInAndRedirect',
          [{ key: 'error', value: error }],
        );
      });

    return oktaWidget;
  } catch (error) {
    logErrorWithContext('OktaSignIn widget failed to initialize', [
      { key: 'error', value: error },
    ]);
  }

  return null;
};

export default createAndDisplayOktaWidget;
