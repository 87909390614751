import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import PropTypes, { oneOf } from 'prop-types';
import InfoRequiredFields from '@hmhco/form-components/src/InfoRequiredFields';
import useStyles from './LoginForm.styles';
import UsernameAndPasswordForm from '../UsernameAndPassword/UsernameAndPasswordForm';
import CountryStateAndOrgForm from '../CountryStateAndOrg/CountryStateAndOrgForm';
import HelpArea from './HelpArea';
import LoginLogo from '../Layout/LoginLogo';
import LoginFooter from '../Layout/LoginFooter';
import LoginNotificationBanner from './LoginNotificationBanner';
import LoginPageButtons from './LoginPageButtons';
import useBootstrapOrgInfo from '../../hooks/bootstrapOrgInfo.hook';
import useCheckLoginCookies from '../../hooks/checkLoginCookies.hook';
import { QUERY_PARAMS } from '../../constants/constants';

const LoginForm = ({ redirectedFrom, currentTheme }) => {
  const { classes } = useStyles();
  useBootstrapOrgInfo();
  useCheckLoginCookies();
  return (
    <Grid
      container
      direction="column"
      component="form"
      className={classes.loginForm}
    >
      <LoginLogo currentTheme={currentTheme} />
      <LoginNotificationBanner redirectedFrom={redirectedFrom} />
      <Typography component="h1" variant="h3" className={classes.title}>
        <FormattedMessage id="login.form.pageTitle" />
      </Typography>
      <InfoRequiredFields fontSize="12" />
      <CountryStateAndOrgForm />
      <UsernameAndPasswordForm />
      <HelpArea />
      <LoginPageButtons currentTheme={currentTheme} />
      <LoginFooter currentTheme={currentTheme} />
    </Grid>
  );
};

LoginForm.defaultProps = {
  redirectedFrom: '',
  currentTheme: 'HMH',
};

LoginForm.propTypes = {
  redirectedFrom: oneOf([
    QUERY_PARAMS.PASSWORD_RESET,
    QUERY_PARAMS.REGISTER_USER,
  ]),
  currentTheme: PropTypes.string,
};

export default LoginForm;
