import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfoRequiredFields from '@hmhco/form-components/src/InfoRequiredFields';
import useBootstrapOrgInfo from '../../../../hooks/bootstrapForgotPasswordOrgInfo.hook';
import RequestSignInDetailsInformationHeader from '../../../RequestSignInDetailsPage/RequestSignInDetailsInformationHeader';
import OrgInfo from '../../../RequestSignInDetailsPage/OrgInfo';
import RequestSignInDetailsEmail from '../../../RequestSignInDetailsPage/RequestSignInDetailsEmail';
import RequestSignInDetailsDisabledMessage from '../../../RequestSignInDetailsPage/RequestSignInDetailsDisabledMessage';
import RequestSignInDetailsButtons from './RequestSignInDetailsButtons';
import {
  getShouldRenderErrorScreen,
  getShouldRenderRequestSigninForm,
  getShouldRenderSuccessScreen,
  getIsRequestSigninDisabled,
} from '../../../../store/selectors/RequestSigninPageState';
import RequestSignInDetailsErrorState from './RequestSignInDetailsErrorState';
import RequestSignInDetailsSuccessState from './RequestSignInDetailsSuccessState';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './RequestSignInDetailsPage.styles';

const RequestSignInDetailsPage = ({ currentTheme }) => {
  const { classes } = useStyles();
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const renderRequestSignInDetailsForm = useSelector(
    getShouldRenderRequestSigninForm,
  );
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);

  useBootstrapOrgInfo();
  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        {renderErrorState && <RequestSignInDetailsErrorState />}
        {renderSuccessState && <RequestSignInDetailsSuccessState />}
        {renderRequestSignInDetailsForm && (
          <>
            <RequestSignInDetailsInformationHeader />
            {!requestSigninDisabled && <InfoRequiredFields />}
          </>
        )}
      </header>
      <main className={classes.main}>
        {renderRequestSignInDetailsForm && (
          <>
            <OrgInfo />
            {!requestSigninDisabled && <RequestSignInDetailsEmail />}
            <RequestSignInDetailsDisabledMessage />
            <RequestSignInDetailsButtons
              currentTheme={currentTheme}
              canSubmit={!requestSigninDisabled}
            />
          </>
        )}
      </main>
    </TenantPickerLayout>
  );
};

RequestSignInDetailsPage.propTypes = {
  currentTheme: PropTypes.string,
};
RequestSignInDetailsPage.defaultProps = {
  currentTheme: null,
};

export default RequestSignInDetailsPage;
