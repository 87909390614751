/* this test palette will be used when no css variables are found in the document 
If any tests are failing, feel free to add the new ebl value to this object
If you're here to update the TEST_PALETTE for an error in the browser,
!! YOU'RE IN THE WRONG PLACE !!
Please refer to muiEblColourPalette.md for further info */

const TEST_PALETTE = {
  brand: '#fb0',
  lightBrand: '#ffe5ad',
  black: '#2d2d2d',
  darkGray: '#565757',
  midGray: '#898d8d',
  lightMidGray: '#ededed',
  lightGray: '#f4f4f4',
  white: '#fff',
  secondary1: '#853fba',
  secondary2: '#009dd1',
  secondary3: '#2eb8ac',
  secondary4: '#f05454',
  tertiary1: '#e9dcfc',
  tertiary2: '#cee6ff',
  tertiary3: '#ace3da',
  tertiary4: '#ffe8e8',
  actionColor: '#065ec2',
  midActionColor: '#afd6ff',
  lightActionColor: '#cee6ff',
  darkActionColor: '#004fa8',
  alertBrand: '#fb0',
  alertBlack: '#2d2d2d',
  alertGray: '#f7f7f8',
  signalError: '#d62424',
  signalWarning: '#ee6b00',
  signalSuccess: '#2f8717',
  signalInfo: '#007db3',
  signalErrorLight: '#ffe8e8',
  signalWarningLight: '#ffedd9',
  signalSuccessLight: '#ebfae1',
  signalInfoLight: '#e3f7fa',
  proficiencyBelow: '#f05454',
  proficiencyBasic: '#de7c14',
  proficiencyProficient: '#149690',
  proficiencyAdvanced: '#009dd1',
  textBlack: '#2d2d2d',
  textGray: '#6a6d6d',
  textWhite: '#fff',
  cardBorderColor: '#c0c6c8',
  background: '#f4f4f4',
  disabledColor: '#cacfd1',
  disciplineMath: '#853fba',
  disciplineRla: '#008a00',
  disciplineSh: '#e00097',
  disciplineSs: '#ba5b02',
  disciplineMathLight: '#e9dcfc',
  disciplineRlaLight: '#d8f7c6',
  disciplineShLight: '#ffdeef',
  disciplineSsLight: '#ffe0bd',
  widgetChartcolorRed: '#d62424',
  widgetChartcolorYellow: '#ee6b00',
  widgetChartcolorGreen: '#2f8717',
  heroBackground: '#f8f1e8',
  heroFloor: '#e8e1da',
  bannerHeroBackgroundColor: '#f7f0e7',
  bannerHeroFloorColor: '#e7e0d9',
  bannerFamilyBackgroundColor: '#f2f2f2',
  bannerFamilyFloorColor: '#ddd',
  bannerLeaderBackgroundColor: '#dcefe9',
  bannerLeaderFloorColor: '#c0e2db',
  gmIconBrand: '#fb0',
  gmIconWhite: '#fff',
  gmIconMath: '#9f4b9c',
  gmIconSpanish: '#0ba587',
  gmIconReading: '#00a2c2',
  miniBannerLiteracyBg: '#dfdcef',
  miniBannerMathBg: '#bdefb2',
  miniBannerHmhBg: '#f9e6c0',
  miniBannerBg: '#c0e2db',
  mktgBeige1: '#faf8f6',
  mktgBeige2: '#f7f3ef',
  mktgBeige3: '#efe9e1',
  mktgBeige4: '#e7ddd1',
  mktgBeige5: '#d6c5b1',
  mktgProficiencyGreen: '#05ad5e',
  mktgProficiencyGreenLight: '#b4e62e',
  mktgProficiencyYellow: '#fbdb08',
  mktgProficiencyOrange: '#fc7600',
  mktgProficiencyRed: '#fc3411',
  mktgProficiencyTurquoise: '#33ccbd',
  mktgProficiencyTurquoiseLight: '#e7f6f3',
  focusColor: '#ca0092',
  borderHairlineGray: '#cacfd1',
  h1Color: '#2d2d2d',
  h2Color: '#2d2d2d',
  h3Color: '#2d2d2d',
  h4Color: '#2d2d2d',
  h5Color: '#2d2d2d',
  h6Color: '#2d2d2d',
  sh1Color: '#2d2d2d',
  sh2Color: '#2d2d2d',
  sh3Color: '#2d2d2d',
  sh4Color: '#2d2d2d',
  p1Color: '#2d2d2d',
  p2Color: '#2d2d2d',
  h1ErrorColor: '#065ec2',
  linkNormalColor: '#065ec2',
  linkHoverColor: '#2d2d2d',
  linkActiveColor: '#2d2d2d',
  linkVisitedColor: '#853fba',
  linkDisabledColor: '#cacfd1',
  iconColor: '#2d2d2d',
  mAvatarBgColor: '#fb0',
  mAvatarTextColor: '#2d2d2d',
  buttonContainedTextColor: '#fff',
  buttonContainedNormalBg: '#065ec2',
  buttonContainedHoverBg: '#065ec2',
  buttonContainedActiveBg: '#004fa8',
  buttonContainedSelectedBg: '#fff',
  buttonContainedSelectedBorderColor: '#065ec2',
  buttonContainedSelectedTextColor: '#065ec2',
  buttonDisabledBg: '#cacfd1',
  buttonDisabledTextColor: '#fff',
  buttonOutlinedTextColor: '#065ec2',
  buttonOutlinedNormalBg: '#fff',
  buttonOutlinedHoverBg: '#fff',
  buttonOutlinedActiveBg: '#cee6ff',
  buttonOutlinedActiveBorder: '#065ec2',
  buttonOutlinedDisabledBorderColor: '#cacfd1',
  buttonOutlinedDisabledTextColor: '#cacfd1',
  buttonFramelessTextColor: '#065ec2',
  buttonFramelessNormalBg: '#fff',
  buttonFramelessHoverBg: '#fff',
  buttonFramelessActiveBg: '#cee6ff',
  buttonFramelessActiveBorderColor: '#065ec2',
  buttonFramelessActiveBorder: '#065ec2',
  buttonFramelessDisabledTextColor: '#cacfd1',
  buttonToggleContainerBorderColor: '#065ec2',
  buttonToggleContainerBorderDisabledColor: '#cacfd1',
  buttonToggleContainerBorderDisabled: '#cacfd1',
  buttonToggleTextColor: '#065ec2',
  buttonToggleBg: '#fff',
  buttonToggleActiveTextColor: '#fff',
  buttonToggleActiveBg: '#065ec2',
  buttonToggleHoverBg: '#f4f4f4',
  buttonToggleFilterBorderColor: '#c0c6c8',
  buttonToggleFilterBorder: '#c0c6c8',
  buttonChipBg: '#fff',
  buttonChipBorder: '#c0c6c8',
  buttonChipBgHoverColor: '#ededed',
  buttonChipBorderHoverColor: '#2d2d2d',
  buttonChipBgSelectedColor: '#cee6ff',
  buttonChipBorderSelectedColor: '#065ec2',
  cardBorderHover: '#2d2d2d',
  cardAccent: '#853fba',
  baseCardMediaBg: '#e3f7fa',
  baseCardIconColor: '#009dd1',
  sCardTitleColor: '#fff',
  sCardSubtitleColor: '#fff',
  sCardListHoverColor: '#f4f4f4',
  dbCardHeaderSeparatorColor: '#dea82a',
  dbCardTileBorderColor: '#c0c6c8',
  dbCardTileHoverBorderColor: '#2d2d2d',
  otNavColor: '#565757',
  otNavActiveBg: '#565757',
  checkboxColor: '#565757',
  checkboxPartialColor: '#898d8d',
  checkboxSelectedColor: '#065ec2',
  checkboxDisabledColor: '#cacfd1',
  notifySimpleColor: '#2d2d2d',
  liColor: '#2d2d2d',
  alertNotifErrorBg: '#ffe8e8',
  alertNotifErrorIconColor: '#d62424',
  alertNotifWarningBg: '#ffedd9',
  alertNotifWarningIconColor: '#ee6b00',
  alertNotifInfoBg: '#e3f7fa',
  alertNotifInfoIconColor: '#007db3',
  alertNotifSuccessBg: '#ebfae1',
  alertNotifSuccessIconColor: '#2f8717',
  emptyHeadlineBg: '#faf8f6',
  infoBannerErrorBg: '#ffe8e8',
  infoBannerErrorIconColor: '#d62424',
  infoBannerWarningBg: '#ffedd9',
  infoBannerWarningIconColor: '#ee6b00',
  infoBannerInfoBg: '#e3f7fa',
  infoBannerInfoIconColor: '#007db3',
  infoBannerSuccessBg: '#ebfae1',
  infoBannerSuccessIconColor: '#2f8717',
  dropdownBorderColor: '#898d8d',
  dropdownHoverBorderColor: '#2d2d2d',
  dropdownLiHoverColor: '#f4f4f4',
  dropdownLiSelectedColor: '#ededed',
  navBg: '#fff',
  logoColor: '#898d8d',
  logoColorHover: '#2d2d2d',
  menuColor: '#898d8d',
  menuColorHoverSelected: '#2d2d2d',
  navBtnFontColor: '#2d2d2d',
  navBtnBg: '#fff',
  navBtnBgHover: '#ffe5ad',
  navBtnBgSelected: '#fb0',
  settingsColor: '#898d8d',
  settingsHoverSelected: '#2d2d2d',
  subnavBorderTopColor: '#cacfd1',
  subnavIndicatorSelected: '#065ec2',
  subnavIndicatorHover: '#cee6ff',
  subnavLiBg: '#f4f4f4',
  tertNavBorderBottomColor: '#cacfd1',
  tertNavIndicatorSelected: '#065ec2',
  tertNavIndicatorHover: '#cee6ff',
  tertNavScrollHover: '#f4f4f4',
  tertNavBgSelected: '#f4f4f4',
  tertNavBgHover: '#ededed',
  dotNavColor: '#565757',
  dotNavActiveBg: '#565757',
  dotNavActiveBorderColor: '#565757',
  tocfilterListitemTitleColor: '#2d2d2d',
  tocfilterListitemSubtitleColor: '#2d2d2d',
  tocfilterListitemIconColor: '#565757',
  tocfilterListitemSelectedBackgroundcolor: '#f4f4f4',
  tocfilterListitemSelectedIconColor: '#565757',
  tocfilterListitemSelectedIndicatorColor: '#065ec2',
  inputBorderColor: '#898d8d',
  inputBorderHoverColor: '#2d2d2d',
  inputBordercolorError: '#d62424',
  linearProgressTextColor: '#2f8717',
  linearProgressRangeColor: '#cacfd1',
  linearProgressValueColor: '#2f8717',
  tableHeaderBorderColor: '#009dd1',
  tableBorderColor: '#c0c6c8',
  tableZebraBg: '#f4f4f4',
  tableHeaderTextColor: '#6a6d6d',
  tableHeaderTextActive: '#2d2d2d',
  tableRowHoverColor: '#cee6ff',
  tableRowSelectedColor: '#afd6ff',
  tableMAccordionOpenBg: '#f4f4f4',
  tableMAccordionSelectedOpenBg: '#cee6ff',
  pageHeaderEyebrowColor: '#6a6d6d',
  pageHeaderSubheadColor: '#2d2d2d',
  modalSdmItemBgColor: '#f4f4f4',
  modalSdmItemStatusCorrectColor: '#2f8717',
  chartCrBelowlevelColor: '#d62424',
  chartCrOnlevelColor: '#ee6b00',
  chartCrAbovelevelColor: '#2f8717',
  chartCrBelowlevelColorLight: '#ffe8e8',
  chartCrOnlevelColorLight: '#ffedd9',
  chartCrAbovelevelColorLight: '#ebfae1',
  chartCrHoverborderColor: '#2d2d2d',
  chartShbcClassAvgNodeBgcolor: '#2d2d2d',
  chartShbcAvgComboNodeBgColor: '#fff',
  chartShbcBandValueColor: '#6a6d6d',
  chartDonutLegendNodataColor: '#cacfd1',
  chartDonutLegendBelowColor: '#d62424',
  chartDonutLegendOnColor: '#ee6b00',
  chartDonutLegendAboveColor: '#2f8717',
  chartDonutLegendValueColor: '#6a6d6d',
  sliderThumbBorderColor: '#898d8d',
};
export default TEST_PALETTE;
