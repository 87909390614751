const nameFieldValidator = (name, field) => {
  let errorMessage;
  const nameRequired = `login.newPassword.${field}.empty`;

  if (name !== undefined && name.length === 0) {
    errorMessage = nameRequired;
  }

  return {
    isNameValid: errorMessage === undefined && name !== undefined,
    nameErrorMessageId: errorMessage,
  };
};

export default nameFieldValidator;
