const certSupportToolConfig = {
  app: 'HCP Support App',
  name: 'cert',
  edsLoginLandingPage: 'https://hcp-support-tool.cert.tsg.hmhco.com/',
  edsLogoutUrl: 'https://app01.cert.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl:
    'https://app01.cert.tsg.hmhco.com/hcp-support-tool-web/',
  edsLoginClientId: '991FD7EC-C2ED-4FE7-B19D-F249EC88FC63',
  edsLoginIssuer: 'hmh-cert-f19b6fd0-1372-416b-974a-55302f8b0665',
  edsLoginUrl:
    'https://hapi.cert.hmhco.com/edcore/eds-login/login/initializeUri',
  edsServiceUrl: 'https://app01.cert.tsg.hmhco.com/edcore/eds-core-app',
  appEndPointUrl:
    'https://hcp-support-tool-api.cert.tsg.hmhco.com/hcp-support-tool/',
  emailTokenURL:
    'https://hmh-identity-provider.cert.br.hmheng.io/user/signupToken',
  commonIdsUrl: 'https://api.cert.br.internal/ids-grid-api/ids/',
  edsGraphUrl: 'https://api.cert.heinemann.com/graphql',
  idmUserApiUrl: 'https://api.cert.br.internal/idm-user-api/',
  idmConfigUrl: 'https://config-idm.cert.br.hmheng.io',
  hmhIdentityProvider: 'https://hmh-identity-provider.cert.br.hmheng.io',
  edcoreGatewayUrl:
    'https://hapi.cert.hmhco.com/edcore/hmheng-eds-api-gateway/',
};

module.exports = certSupportToolConfig;
