/**
 * Creates a debounced function that delays invoking `func` until after wait milliseconds
 * have elapsed since the last time the debounced function was invoked.
 *
 * @param {function} func function to run after the wait time
 * @param {number} wait The number of milliseconds to delay.
 *
 * @returns {function} func wrapped in another function that delay it's execution.
 */
const debounce = (func, wait) => {
  let timeout;

  return function(...args) {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, wait);

    if (!timeout) func.apply(this, args);
  };
};
export default debounce;
